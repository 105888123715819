import { useForm } from "react-hook-form";
import React, { useEffect } from "react";
import Form from "./Form";

const useSimpleForm = (options ?: any) => {
  const isEditMode = !!options?.entityId;
  const action = options?.baseAction ? `${options.baseAction}${isEditMode ? "/" + options.entityId : ""}` : options?.action;

  const form = useForm({
    mode : "onSubmit",
    reValidateMode : "onSubmit",
    resolver : (values, context, options) => {
      return {
        values : values,
        errors : {}
      }
    }
  });

  const render = (children : any) => {
    return (
      <Form action={action} method={isEditMode ? "PUT" : "POST"} form={form}>{children}</Form>
    )
  }

  useEffect(() => {
    form && form.reset(options.data || {});
  }, [options.data]);

  return {
    instance : form,
    render : render,
    reset : form.reset
  };
}

export default useSimpleForm;