import { Input, TextField } from "@mui/material";
import React, { useState } from "react";

class SearchInput extends React.Component<any, any>
{
  constructor(props : any) {
    super(props);
    this.state = {
      value : ""
    };
  }

  reset() {
    this.setState({
      value : ""
    })

    this.props.onChange(undefined);
  }

  render() {
    return (
      <form onSubmit={(e) => {
        e.preventDefault();
        this.props.onChange(this.state.value.length > 0 ? this.state.value : undefined);
      }}>
        <TextField
            value={this.state.value}
            onChange={(e) => {
              this.setState({
                value : e.target.value
              });
            }}
            size="small"
            id="outlined-basic"
            label="Wyszukaj"
            variant="outlined"
            autoComplete="off"
            style={{ background : "#FFF" }}
        />
      </form>
    )
  }
}
//
// export const SearchInput = (props : any) => {
//   const [value, setValue] = useState("");
//
//   const reset = () => {
//
//   }
//
//   return (
//     <form onSubmit={(e) => {
//       e.preventDefault();
//       props.onChange(value.length > 0 ? value : undefined);
//     }}>
//       <TextField
//         value={value}
//         onChange={(e) => {
//           setValue(e.target.value);
//         }}
//         size="small"
//         id="outlined-basic"
//         label="Wyszukaj"
//         variant="outlined"
//         style={{ background : "#FFF" }}
//       />
//     </form>
//   )
// }

export default SearchInput;