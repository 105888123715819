import React, { memo, PropsWithChildren, useState } from "react";
import { useAuthContext } from "../Context/AuthContext";

export interface AuthorizeProps
{
  anyPerm ?: string[]
}

const isPermMatched = (pattern : string, testedValue : string) => {
  pattern = pattern.trim();
  const isWildcard = pattern.endsWith('*');
  pattern = isWildcard ? pattern.substr(0, pattern.length - 1) : pattern;


  if(isWildcard && testedValue.startsWith(pattern)) {
    return true;
  }

  return testedValue === pattern;
};

const Authorize = (props : PropsWithChildren<AuthorizeProps>) => {
  const context = useAuthContext();
  const perms = context?.state?.currentUser?.permissions || {};
  const permsArray = Object.keys(perms);
  const [granted, setGranted] = useState<boolean>(false);


  if(props.anyPerm) {
    props.anyPerm.forEach((permPattern) => {
      if(granted) {
        return;
      }

      if(permsArray.some(permName => isPermMatched(permPattern, permName))) {
        setGranted(true);
      }
    });
  }

  if(!granted) {
    return <></>;
  }

  return (
    <>
      {props.children}
    </>
  )
}

export default React.memo(Authorize);