import React, { useEffect, useState } from "react";
import api from "../Api";
import { navigate } from "@reach/router";
import { CircularProgress } from "@mui/material";

const AuthContext = React.createContext<AuthStateType | undefined>(undefined);
const AuthActionsContext = React.createContext<AuthActionsType | undefined>(undefined);

const LocalStorageJwtKey = "OmniDataWeb.Auth";

type AuthStateType = {
    isAuthenticated : boolean;
    isInitialized : boolean;
    currentUser ?: any;
    username ?: string | null;
};

type AuthActionsType = {
    login : Function;
    logout : Function;
};

const isJwtSet = () => {
    let jwt = localStorage.getItem(LocalStorageJwtKey);

    return jwt !== null && jwt !== undefined && jwt.trim().length > 0;
}

export const getAuthToken = () => {
    return localStorage.getItem(LocalStorageJwtKey);
}
const test = async () => {
    return false;
}

export const AuthContextProvider: React.FC = (props) => {
    const [state, setState] = useState<AuthStateType>({
        isAuthenticated : false,
        isInitialized : false,
        username : sessionStorage.getItem('username') || null
    });

    const changeState = (prop: any, value: any) => {
        setState({ ...state, [prop]: value });
    };

    const actions = {
        login : async (data : any) => {
            localStorage.setItem(LocalStorageJwtKey, data.authToken);

            if(data.authToken) {
                await actions.refreshData();
            }
        },

        logout : () => {
            localStorage.removeItem(LocalStorageJwtKey);
            changeState('isAuthenticated', false);
        },

        loadApplicationData : async () => {
            return await api.common.getInitialData();
        },

        refreshData : async () => {
            try {
                const { data } : any = await actions.loadApplicationData();

                const currentUser = data?.currentUser || {};
                currentUser.permissions = data?.auth?.grantedPolicies || {};

                setState({
                    ...state,
                    isInitialized : true,
                    isAuthenticated : data?.currentUser?.isAuthenticated || false,
                    currentUser : data?.currentUser || {}
                });
            } catch (e : any) {
                alert(e);
            }
        }
    };

    useEffect(() => {
        actions.refreshData();
    }, []);

    if(!state.isInitialized) {
        return <CircularProgress  />
    }

    return (
        <AuthContext.Provider value={state}>
            <AuthActionsContext.Provider value={actions}>
                {props.children}
            </AuthActionsContext.Provider>
        </AuthContext.Provider>
    );
};

export const useAuthContext = () => {
    return {
        state: React.useContext<AuthStateType>(AuthContext as React.Context<AuthStateType>),
        actions: React.useContext<AuthActionsType>(AuthActionsContext as React.Context<AuthActionsType>)
    };
};
