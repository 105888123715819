import { useParams } from "@reach/router";
import React, { useState } from "react";
import ControlCardView from "../../../OmniData/ControlCard/ControlCardView";
import { Checkbox, Divider, FormControlLabel, Grid, IconButton, Paper, Typography } from "@mui/material";
import Breadcrumbs from "../../../Components/Breadcrumbs";
import { useGetDataFromApi } from "../../../OmniData/DataAccess";
import { Controller } from "react-hook-form";
import { Check, CheckCircle, Trash as TrashIcon, X, XCircle } from "react-feather";
import Button from "../../../Components/Button";
import api from "../../../Api";
import { useSnackbar } from "notistack";


export default function Approve(props: any) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const params = useParams();
  const formData = useGetDataFromApi(`/api/app/control-card/form-data/${params.id}`);
  const [inProgress, setInProgress] = useState(false);

  if(formData.isLoading) {
    return <>Loading...</>
  }

  return <>
    <Breadcrumbs title={"Zatwierdzanie karty"}/>
    <Typography style={{marginTop: 16, marginBottom: 8}} >Dane karty</Typography>
    <ControlCardView id={params.id} mode="approve" />
    <Typography style={{marginTop: 16, marginBottom: 8}} >Zatwierdzanie</Typography>

    <Paper style={{ padding: 16 }} elevation={1}>
      <Grid container spacing={1}>
        {formData?.data?.template?.approvalOrderRequired && (
          <Grid container item spacing={3}>
            <Grid item xs={3}>
              <b>Kolejność zatwierdzania wymagana</b>
            </Grid>
          </Grid>
        )}

        <Grid container item spacing={3}>
          <Grid item xs={12}>

            {(formData?.data?.approvals || []).map(( approval : any) => (
                <div key={approval.id} style={{ display : "flex", alignItems : "center", marginBottom : 16, fontSize : 16 }}>
                  <div style={{ display : "flex" }}>
                    {approval.isApproved ? <CheckCircle size={20} style={{ marginRight : 8 }} /> : <XCircle size={20} style={{ marginRight : 8 }} />}
                  </div>
                  <div>
                    {approval.label}
                  </div>

                  {approval.isAvailableForCurrentUser && (
                      <div style={{ marginLeft : 16 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<CheckCircle size={20}/>}
                            type="button"
                            inProgress={inProgress}
                            size="small"
                            onClick={async (e : any) => {
                              try {
                                setInProgress(true);
                                await api.app.controlCards.filled.approve(params.id, approval.id);
                                await formData.revalidate();
                                enqueueSnackbar("Zatwierdzono")
                                setInProgress(false);
                              } catch (e : any) {
                                enqueueSnackbar(e.message, {
                                  variant : "error"
                                })
                                setInProgress(false);
                              }

                            }}
                        >
                          Zatwierdź
                        </Button>
                      </div>
                  )}
                </div>
            ))}
          </Grid>
        </Grid>
      </Grid>

    </Paper>

  </>
}