import { styled } from "@mui/material/styles";
import DatePickerField from "../../../../Core/Forms/Fields/DatePickerField";
import React from "react";
import { format } from "date-fns";

const ReadOnlyDiv = styled("div")({
  display : "flex",
  borderRadius : 4,
  fontSize : 16,
  border : "1px solid rgba(0, 0, 0, 0.1)",
  background : "rgba(0, 0, 0, 0.01)",
  padding : "10.5px 14px",
  minHeight : 40,
  alignItems : "center"
});

const ReadOnlyDateTimeFormControl = (props : any) => {
  let value = props?.value ?? undefined;
  const dateFormat = props?.format ?? undefined;

  if(dateFormat) {
    try {
      value = format(new Date(value), dateFormat);
    } catch (e) {

    }
  }

  return (<ReadOnlyDiv>{value ?? "-"}</ReadOnlyDiv>);
};

export default ReadOnlyDateTimeFormControl;