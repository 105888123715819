import React, {  } from "react";
import { Button } from "@mui/material";
import { RouteComponentProps } from "@reach/router";
import Breadcrumbs from "../../../Components/Breadcrumbs";
import { Plus as PlusIcon } from "react-feather";
import ListDataViewer from "../../../Core/ListDataViewer/ListDataViewer";
import NavigationLink from "../../../Core/NavigationLink";

export function List(props: RouteComponentProps) {

    return (
        <>
            <Breadcrumbs title="Karty kontrolne - szablony" />

            <Button
                component={NavigationLink}
                variant="contained"
                color="primary"
                startIcon={<PlusIcon>send</PlusIcon>}
                href="/control-cards/templates/create"
            >
                Dodaj
            </Button>


            <ListDataViewer
                searchIndexEnabled
                exportEnabled
                endpoints={{
                  base : `/api/app/control-cards-templates`
                }}
                paths={{
                  base : `/control-cards/templates`
                }}
                columns={[
                  {
                    Header: 'Nazwa',
                    accessor: 'name',
                  },
                  {
                    Header : 'Data modyfikacji',
                    accessor: 'lastModificationTime',
                    type : 'datetime'
                  },

                  {
                    Header : 'Data utworzenia',
                    accessor: 'creationTime',
                    type : 'datetime'
                  },
                ]}
            />
        </>
    );
}

export default React.memo(List);