import React from "react";
import { useForm, Controller, UseFormReturn, useFormContext } from "react-hook-form";
import { CheckboxProps as MuiCheckboxProps } from "@mui/material/Checkbox";
import _ from "lodash";
import { Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel } from "@mui/material";

export interface CheckboxFieldProps
{
  name : string;
  label ?: string;
  defaultValue ?: number | string;
  form ?: UseFormReturn;
  controlProps ?: MuiCheckboxProps;
}

const ensureValidValue = (value : any) => {
  if(value === undefined || value === null)
    return false;

  const normalizedStringValue = _.isString(value) ? (value as string)?.toLowerCase().trim() : "";

  if(normalizedStringValue === "true" || normalizedStringValue === "1" || value === true || value === 1) {
    return true;
  }

  if(normalizedStringValue === "false" || normalizedStringValue === "0" || value === false || value === 0) {
    return false;
  }

  return value;
}

const CheckboxField = (props : CheckboxFieldProps) => {
  const formContext = useFormContext();
  const form = props.form ? props.form : formContext;
  const name = props.name;

  const controlProps = _.merge<MuiCheckboxProps, MuiCheckboxProps>({
    color : "primary"
  }, props.controlProps || {});

  const error = _.get(form.formState.errors, name, undefined);
  const errorMessage = error?.message;

  return (
      <FormControl error={!!error}>
        <FormControlLabel
          control={(
            <Controller
                name={name}
                control={form.control}
                defaultValue={ensureValidValue(props.defaultValue)}
                render={({ field }) => {
                  return (
                    <Checkbox
                      {...field}
                      {...controlProps}
                      value={true}
                      checked={ensureValidValue(field.value)}
                    />
                  )
                }}
            />
          )}
          label={props.label || ""}
        />
        {error && (
          <FormHelperText>{errorMessage}</FormHelperText>
        )}
      </FormControl>
  )
}

export default CheckboxField;