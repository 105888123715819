import React from "react";
import DatePickerField from "../../../../Core/Forms/Fields/DatePickerField";
import DateTimePickerField from "../../../../Core/Forms/Fields/DateTimePickerField";
import ReadOnlyDateTimeFormControl from "./ReadOnlyDateTimeFormControl";

const DateTimeFormControl = (props : any) => {
  return <DateTimePickerField
      name={props?.name}
      isReadOnly={props?.isReadOnly || false}
      form={props?.form}
      readOnlyRender={(inputValue : string, format : string) => <ReadOnlyDateTimeFormControl value={inputValue} format={format} />}
  />;
};

export default DateTimeFormControl;