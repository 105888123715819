import React, { PropsWithChildren, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { UseFormReturn } from "react-hook-form/dist/types";
import _ from "lodash";
import { Save as SaveIcon } from "react-feather";
import Button from "../../Components/Button";
import api from "../../Api";
import { navigate } from "@reach/router";
import { useSnackbar } from "notistack";
import { Method } from "axios";

export type FormHttpMethod =
    | 'get' | 'GET'
    | 'delete' | 'DELETE'
    | 'head' | 'HEAD'
    | 'options' | 'OPTIONS'
    | 'post' | 'POST'
    | 'put' | 'PUT'
    | 'patch' | 'PATCH'
    | 'purge' | 'PURGE'
    | 'link' | 'LINK'
    | 'unlink' | 'UNLINK'

export interface FormProps {
  form : UseFormReturn;
  action : string;
  method : FormHttpMethod;
  onValidSubmit ?: (data : any) => void
  onAfterSubmitRequestConfig ?: (currentRequestConfig : any, formData : any, formInstance : UseFormReturn) => any
  useResponseData ?: any
}

const apiClient = api.getClient();

const Form = (props : PropsWithChildren<FormProps>) => {
  const form = props.form;
  const [operationInProgress, setOperationInProgress] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const concurrencyStampFieldName = "concurrencyStamp";

  const prepareSubmitRequestConfig = async (formData : any, form : UseFormReturn) => {
    let data = {
      requestData : formData
    }

    if(props.onAfterSubmitRequestConfig) {
      data = await props.onAfterSubmitRequestConfig(data, formData, form);
    }

    return data;
  };

  let isSubmitRendered = false;

  const formProps = {
    submitButton : () => {
      isSubmitRendered = true;

      return (
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon size={22} />}
          type="submit"
          inProgress={operationInProgress}
        >
          Zapisz
        </Button>
      )
    }
  }

  const onValidSubmit = async (formData : any) => {
    try {
      setOperationInProgress(true);

      const submitRequestConfig = await prepareSubmitRequestConfig(formData, form);

      const response = await apiClient.request({
        url : props.action,
        method : props.method,
        data : submitRequestConfig.requestData
      })

      if(response?.data?.[concurrencyStampFieldName]) {
        form.setValue("concurrencyStamp", response?.data?.[concurrencyStampFieldName])
      }

      if(props.useResponseData) {
        form.reset(response?.data);
      }

      enqueueSnackbar('Zmiany zapisane');

    } catch (e : any) {
      const error = _.get(e, 'response.data.error.message', e.message);
      enqueueSnackbar(error, {
        variant : "error"
      })

      // Validation errors
      const validationErrors = _.get(e, 'response.data.error.validationErrors', []);
      if(_.isArray(validationErrors)) {
        validationErrors.forEach((validationError) => {
          const errorKey = (validationError.members as []).join(".");
          form.setError(errorKey, {
            type : "manual",
            message : validationError.message
          })
        });
      }

      console.error(e);
    } finally {
      setOperationInProgress(false);
    }
  }

  return (
    <FormProvider {...form}>
      <form noValidate onSubmit={form.handleSubmit(onValidSubmit)}>
        {_.isFunction(props.children) ? props.children(formProps) : props.children}
        {!isSubmitRendered  && formProps.submitButton()}
      </form>
    </FormProvider>
  )
};

export default Form;