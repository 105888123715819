import { FormControl, Paper, TextField, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import React, { FormEvent, useEffect, useState } from "react";
import Button from "../../Components/Button";
import LogoImage from "../../Assets/images/logo.png";
import { navigate, RouteComponentProps } from "@reach/router";
import { useUIContext } from "../../Context/UIContext";
import {useAuthContext} from "../../Context/AuthContext";
import api from "../../Api";
import { Alert } from '@mui/material';

const PREFIX = 'Auth';

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    margin: "0px auto",
    maxWidth: "520px",
    display: "flex",
    minHeight: "calc(100vh - 50px)",
    marginTop: "50px",
    flexFlow: "column"
  },

  [`& .${classes.card}`]: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(5)
    },
  }
}));

export default function Login(props: RouteComponentProps) {

  const [inProgress, setInProgress] = useState<boolean>(false);
  const { actions : authActions } = useAuthContext();
  // @todo remove credentials
  const [username, setUsername] = useState<string>("admin");
  const [password, setPassword] = useState<string>("1q2w3E*");
  const [error, setError] = useState<string>();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setInProgress(true);

    try {
      var token = await api.auth.getToken(username, password);

      await authActions.login({
        authToken : token?.data?.access_token,
      });

      await navigate(`/`);
    } catch (e : any) {
      setInProgress(false);
      setError(e?.response?.data?.error_description);
    }

  };

  return (
    <Root className={classes.root}>
      <div style={{ display: "flex", alignContent: "center", justifyContent: "center", marginBottom: "24px" }}>
        <img src={LogoImage} width={92} height={92} alt="" />
      </div>

      <Paper className={classes.card}>

        <form action="#" onSubmit={onSubmit}>

          <Typography align="center" variant="h6" component="h1">
            Zaloguj się
          </Typography>

          <FormControl fullWidth margin="normal">
            <TextField value={username} onChange={(e) => setUsername(e.currentTarget.value)}  label="Login" variant="outlined" size="small" required />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <TextField value={password} onChange={(e) => setPassword(e.currentTarget.value)} type="password" label="Hasło" variant="outlined" size="small" required />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <Button inProgress={inProgress} type="submit" variant="contained" color="primary" disableElevation fullWidth>
              Zaloguj
            </Button>
          </FormControl>

          {error && error.length > 0 && (
            <Alert severity="error">{ error }</Alert>
          )}
        </form>

      </Paper>
    </Root>
  );
}

Login.Layout = React.Fragment;