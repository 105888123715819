import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  TextField
} from "@mui/material";
import  { Plus as EditIcon } from "react-feather";
import { useGetOU } from "../../../OmniData/DataAccess";
import api from "../../../Api";

const PREFIX = 'Manage';

const classes = {
  root: `${PREFIX}-root`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  }
}));

const Tree = (props : any) => {


  return (
    <List className={classes.root} component="div" style={{ paddingLeft : props.depth * 15 }} >
      {props.items.map((item : any) => {
        return (
          (<Root>
            <ListItem key={item.id} role={undefined} button dense ContainerComponent="div">
              <ListItemText primary={item.displayName} />
              <ListItemSecondaryAction>
                <IconButton size="small" edge="end" onClick={() => props.onAdd(item)}>
                  <EditIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            {item?.children?.length > 0 && (
              <Tree items={item.children} depth={props.depth+1} onAdd={props.onAdd} />
            )}
          </Root>)
        );
      })}
    </List>
  );
}

function listToTree(data : any, options : any) {
  options = options || {};
  var ID_KEY = options.idKey || 'id';
  var PARENT_KEY = options.parentKey || 'parentId';
  var CHILDREN_KEY = options.childrenKey || 'children';

  var tree : any = [],
      childrenOf : any = {};
  var item, id, parentId;

  for (var i = 0, length = data.length; i < length; i++) {
    item = data[i];
    id = item[ID_KEY];
    parentId = item[PARENT_KEY] || 0;
    // every item may have children
    childrenOf[id] = childrenOf[id] || [];
    // init its children
    item[CHILDREN_KEY] = childrenOf[id];
    if (parentId != 0) {
      // init its parent's children object
      childrenOf[parentId] = childrenOf[parentId] || [];
      // push it into its parent's children object
      childrenOf[parentId].push(item);
    } else {
      tree.push(item);
    }
  };

  return tree;
}

function FormDialog(props : any) {
  const [name, setName] = useState("");

  return (
      <div>
        <Dialog open={props.isOpen} onClose={props.onClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Nowa jednostka</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Nazwa"
              type="text"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onClose} color="primary">
              Anuluj
            </Button>
            <Button variant="contained" onClick={() => {
              props.onSave(name);
              setName("");
            }} color="primary">
              Zapisz
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}

export default function Manage(props: any) {

  const { data, revalidate } = useGetOU();
  const [editVisible, setEditVisible] = useState(false);
  const [parentId, setParentId] = useState<any>(null);

  const items = data ? listToTree(data, null) : [];


  return <>
    <Paper style={{padding: 16}} elevation={1}>
      <Tree items={items} depth={0} onAdd={(data : any) => {
        setParentId(data.id);
        setEditVisible(true);
      }}/>

      <IconButton
        onClick={() => {
          setParentId(null);
          setEditVisible(true);
        }}
        size="large"><EditIcon /></IconButton>
    </Paper>

    <FormDialog
      isOpen={editVisible}
      onSave={async (name : string) => {
        var x = await api.common.organizationUnits.create({
          displayName : name,
          parentId : parentId
        });
        await revalidate();
        setEditVisible(false);
      }}
      onClose={() => setEditVisible(false)}
    />
  </>;
}