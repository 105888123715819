import React, { PropsWithChildren, useState } from "react";
import { useForm, Controller, UseFormReturn, useFormContext } from "react-hook-form";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Select as MuiSelect, TextField
} from "@mui/material";
import { SelectProps as MuiSelectProps } from "@mui/material/Select";
import _ from "lodash";
import { Autocomplete } from '@mui/material';

export interface SearchableSelectFieldOption
{
  label : string;
  value : string;
  attributes ?: { type : string; value : string }[];
}

export interface SearchableSelectFieldProps
{
  name : string;
  multiple ?: boolean;
  options : SearchableSelectFieldOption[];
  label ?: string;
  defaultValue ?: number | string | SearchableSelectFieldOption[];
  form ?: UseFormReturn;
  controlProps ?: any;
  isReadOnly ?: boolean;
}

const ensureValidValue = (value : any, availableValues : string[], isMultiple : boolean = false) => {
  if(isMultiple) {
    if(value === undefined || value === null || !Array.isArray(value))
      return [];
  } else {
    if(value === undefined || value === null || !availableValues.includes(value))
      return null;
  }

  return value;
}

const SearchableSelectField = (props : PropsWithChildren<SearchableSelectFieldProps>) => {
  const formContext = useFormContext();
  const [isOpen, setIsOpen] = useState(false);
  const form = props.form ? props.form : formContext;
  const name = props.name;
  const isMultiple = props.multiple;

  const optionsByValue = _.keyBy(props.options, 'value');
  const optionValues = props.options.map(option => option.value);

  const controlProps = _.merge({
    label : props.label,
    fullWidth : true,
    variant : "outlined",
  }, props.controlProps || {});

  const error = _.get(form.formState.errors, name, undefined);
  const errorMessage = error?.message;

  return (
      <FormControl variant="outlined" size="small" fullWidth={true} error={!!error}>
        <Controller
            name={name}
            control={form.control}
            defaultValue={ensureValidValue(props.defaultValue, optionValues, isMultiple)}
            render={({ field }) => {
              return (
                <Autocomplete
                  {...field}
                  {...controlProps}
                  value={ensureValidValue(field.value, optionValues, isMultiple)}
                  multiple={isMultiple}
                  options={optionValues}
                  disableCloseOnSelect
                  blurOnSelect={!isMultiple}
                  getOptionLabel={(optionValue : string) => optionsByValue[optionValue]?.label || ""}
                  renderTags={(selected) => {
                    return (
                      <div onClick={() => setIsOpen(true)} style={{ display : "flex", alignItems : "center" }}>
                        {selected.toString()}
                      </div>
                    )
                  }}
                  onChange={(event, value) => {
                    field.onChange(value)
                  }}
                  open={isOpen}
                  onClose={() => setIsOpen(false)}
                  onOpen={() => setIsOpen(true)}
                  noOptionsText="Brak opcji"
                  renderOption={(props : any, optionValue : string, { selected }) => {
                    const option = optionsByValue[optionValue];
                    const imageUrl = option?.attributes?.find((attr : any) => attr.type === "image")?.value;

                    return (
                      <div {...props} style={{ display : "flex", alignItems : "center" }}>
                        {isMultiple && (
                            <Checkbox
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                        )}

                        {imageUrl && (<div style={{ width : 42, height : 42, display : "flex", alignItems : "center", justifyContent : "center", marginRight : 5 }}>
                          <img style={{ maxWidth : "100%", height : "auto" }} src={imageUrl} />
                        </div>)}

                        <div>{option?.label || option}</div>
                      </div>
                    )
                  }}
                  renderInput={(params) => {
                    if(!isMultiple) {
                      // params.InputProps.startAdornment = (<div onClick={() => setIsOpen(true)}>test</div>)
                    }

                    return (
                        <div>
                          <TextField {...params} size="small" variant="outlined" label={props.label}  placeholder=""  />
                        </div>
                    )}
                  }
                />
              )
            }}
        />
        {error && (
          <FormHelperText>{errorMessage}</FormHelperText>
        )}
      </FormControl>
  )
}

export default SearchableSelectField;