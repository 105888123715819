import React  from 'react';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { CheckSquare,  IconProps, Menu, Book } from 'react-feather';
import {Divider, IconButton, ListSubheader} from '@mui/material';
import { useUIContext } from '../Context/UIContext';
import { Link } from '@reach/router';
import { useAuthContext } from "../Context/AuthContext";
import Authorize from "../Core/Authorize";

const PREFIX = 'sidebar';

const classes = {
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  sidebarMainLink: `${PREFIX}-sidebarMainLink`,
  sidebarListHeader: `${PREFIX}-sidebarListHeader`,
  sidebarMainLinkText: `${PREFIX}-sidebarMainLinkText`,
  sidebarMainLinkIcon: `${PREFIX}-sidebarMainLinkIcon`,
  sidebarDivider: `${PREFIX}-sidebarDivider`,
  logoWithMenu: `${PREFIX}-logoWithMenu`,
  logoWithMenu__Link: `${PREFIX}-logoWithMenu__Link`
};

const drawerWidth = 260;

const Root = styled('nav')(({ theme }) => ({
  [`&.${classes.drawer}`]: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,
    borderRight: 0
  },

  [`& .${classes.sidebarMainLink}`]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),

    '&:hover': {
      background: 'rgba(0, 0, 0, 0.15)',
    },
    '&.isActive': {
      background: 'rgba(0, 0, 0, 0.20)',
    }
  },

  [`& .${classes.sidebarListHeader}`]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    color: 'rgb(238, 238, 238)',
    fontWeight : 400,
    lineHeight : '32px',
    background : 'inherit'
  },

  [`& .${classes.sidebarMainLinkText}`]: {
    color: 'rgb(238, 238, 238)',
    fontSize: '13px'
  },

  [`& .${classes.sidebarMainLinkIcon}`]: {
    color: 'rgba(238, 238, 238, 0.5)',
    minWidth: '40px'
  },

  [`& .${classes.sidebarDivider}`]: {
    background: 'rgba(247, 249, 252, 0.15)'
  },

  [`& .${classes.logoWithMenu}`]: {
    color: 'rgba(238, 238, 238, 0.5)',
    paddingLeft: '20px',
    paddingRight: theme.spacing(4),
    display: 'flex',
    alignItems: 'center'
  },

  [`& .${classes.logoWithMenu__Link}`]: {
    color: 'rgb(238, 238, 238)',
    fontSize: '16px',
    marginLeft: '8px'
  }
}));


const closeNavbar = (closeAction: Function) => (
  event: React.KeyboardEvent | React.MouseEvent,
) => {

  if (
    event.type === 'keydown' &&
    ((event as React.KeyboardEvent).key === 'Tab' ||
      (event as React.KeyboardEvent).key === 'Shift')
  ) {
    return;
  }

  closeAction();
};

const ListItemLink = React.forwardRef((props: any, ref) => {
  return (
    <Link to={props.href} ref={ref} {...props}>{props.children}</Link>
  );
});

const MainLink: React.FC<{ text: string, Icon?: React.ComponentType<IconProps>; href: string; }> = ({ text, Icon, ...props }) => {


  return (
    <ListItem button component={ListItemLink} key={text} className={classes.sidebarMainLink} {...props}>
      <ListItemIcon className={classes.sidebarMainLinkIcon}>
        {Icon && (<Icon size={20} strokeWidth={1.5} />)}
      </ListItemIcon>
      <ListItemText primary={text} primaryTypographyProps={{ className: classes.sidebarMainLinkText }} />
    </ListItem>
  );
};

export default function ResponsiveDrawer({ open = false, mobile = false }: { open?: boolean; mobile?: boolean; }) {
  const { actions: UIActions } = useUIContext();

  return (
    <Root className={classes.drawer}>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        variant={mobile ? 'temporary' : 'persistent'}
        open={open}
        onClose={closeNavbar(() => UIActions.toggleDrawer(false))}
      >
        <div>
          <div className={classes.logoWithMenu}>
            <IconButton
              color="inherit"
              component="span"
              onClick={() => UIActions.toggleDrawer()}
              size="large">
              <Menu size={20} />
            </IconButton>
            <div className={classes.logoWithMenu__Link}>
              <b>OmniData</b>
            </div>
          </div>

          <Divider className={classes.sidebarDivider} />

          <Authorize anyPerm={['OmniData.ControlCards*']}>
            <List
              subheader={
                <ListSubheader className={classes.sidebarListHeader}>Karty</ListSubheader>
              }
            >
              <Authorize anyPerm={['OmniData.ControlCards.Operator']}>
                <MainLink href="/control-cards/operator" text="Operator" Icon={CheckSquare} />
              </Authorize>

              <Authorize anyPerm={['OmniData.ControlCards.Approval']}>
                <MainLink href="/control-cards/approval" text="Zatwierdzanie" Icon={CheckSquare} />
              </Authorize>

              <Authorize anyPerm={['OmniData.ControlCards.Manage*']}>
                <>
                  <MainLink href="/control-cards/list" text="Lista" Icon={CheckSquare} />
                  <MainLink href="/control-cards/templates" text="Szablony" Icon={CheckSquare} />
                  <MainLink href="/control-cards/groups" text="Grupy" Icon={CheckSquare} />
                  <MainLink href="/control-cards/types" text="Typy" Icon={CheckSquare} />
                </>
              </Authorize>
            </List>
          </Authorize>

          <Authorize anyPerm={['OmniData.DataWarehouse*']}>
            <List
              subheader={
                <ListSubheader className={classes.sidebarListHeader}>Magazyn danych</ListSubheader>
              }
            >
              <MainLink href="/data-warehouse/form-types" text="Formularze" Icon={Book} />
              <MainLink href="/data-warehouse/data-types" text="Typy" Icon={Book} />
              <MainLink href="/data-warehouse/unit-types" text="Jednostki" Icon={Book} />
            </List>
          </Authorize>

          <Authorize anyPerm={['AbpIdentity.Roles*', 'AbpIdentity.Users*']}>
            <List
              subheader={
                <ListSubheader className={classes.sidebarListHeader}>Administracja</ListSubheader>
              }
            >
              <MainLink href="/administration/ou" text="Struktura" Icon={Book} />

              <Authorize anyPerm={['AbpIdentity.Users*']}>
                <MainLink href="/administration/users" text="Użytkownicy" Icon={Book} />
              </Authorize>

              <Authorize anyPerm={['AbpIdentity.Roles*']}>
                <MainLink href="/administration/roles" text="Role" Icon={Book} />
              </Authorize>
            </List>
          </Authorize>

        </div>
      </Drawer>
    </Root>
  );
}