import { useParams } from "@reach/router";
import Breadcrumbs from "../../../Components/Breadcrumbs";
import ControlCardView from "../../../OmniData/ControlCard/ControlCardView";
import React from "react";

export const Card = () => {
  const { id } = useParams();

  return (
      <>
        <Breadcrumbs title={"Aktualizacja karty"}/>
        <ControlCardView id={id} mode="operator" />
      </>
  );
}

export default Card;