import { Button, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

export interface BoolFilterProps
{
  label : string;
  id : any;
  currentValue : any;
  onChange : (newValue : any) => void;
}

const normalizeValue = (v : any) => v == undefined ? "" : v;

const BoolFilter = (props : BoolFilterProps) => {
  const [value, setValue] = useState<string | undefined>(normalizeValue(props?.currentValue?.values?.[0]));

  const submit = () => {
    props.onChange({
      values : [value],
    });
  };

  return (
      <>
        <div>
          <RadioGroup
            value={normalizeValue(value)}
            onChange={(event, selectedValue) => setValue(selectedValue)}
            sx={{ flexDirection : "row" }}
          >
            <FormControlLabel value="true" control={<Radio />} label="Tak" />
            <FormControlLabel value="false" control={<Radio />} label="Nie" />
          </RadioGroup>
        </div>
        <div>
          <Button sx={{ marginTop : "8px" }} onClick={submit} disableElevation fullWidth variant="contained">Filtruj</Button>
        </div>
      </>
  )
}

export default BoolFilter;