import { Checkbox, Divider, FormControlLabel, FormGroup, Grid, Paper, Typography } from "@mui/material";
import { navigate, useParams } from "@reach/router";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../Components/Breadcrumbs";
import { Controller, useForm } from "react-hook-form";
import api from "../../../Api";
import TextField from "../../../Core/Forms/Fields/TextField";
import Form, { FormHttpMethod } from "../../../Core/Forms/Form";
import Authorize from "../../../Core/Authorize";

const Permissions = ({ items, depth, onChange, parent } : any) => {
  depth = depth || 0;
  parent = parent || undefined;

  return (
    <>
      {items && items.map((item : any) => {
        const checked = !!item?.isGranted;

        return (
          <FormGroup key={item.name} style={{ paddingLeft : depth > 0 ? 30 : 0 }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  value={item.name}
                  checked={checked}
                  onChange={(e) => {
                    onChange(item.name, !checked, parent)
                  }}
                />
              }
              label={item.displayName}
            />

            {item.children && <Permissions items={item.children} parent={item.name} depth={depth + 1} onChange={onChange} />}
          </FormGroup>
        )
      })}
    </>
  );
}

const permsToNested = (permissions : any[]) => {
  const result : any[] = [];

  permissions.forEach(perm => {
    const name = perm.name;
    const children : any[] = [];

    permissions.forEach((p, i) => {
      const isChild = p.name != name && p.name.startsWith(name + ".");

      if(isChild) {
        children.push(p);
        delete permissions[i];
      }
    });

    result.push({
      ...perm,
      children
    });
  });

  return result;
}

const permsToFormData = (permissions : any[]) => {
  const result : any[] = [];

  permissions.forEach(perm => {
    result.push({ name : perm.name, isGranted : perm.isGranted });

    (perm?.children || []).forEach((child : any) => {
      result.push({ name : child.name, isGranted : child.isGranted });
    });
  });

  return result;
}


const MainForm = (props: any) => {
  const form = useForm({
    reValidateMode : "onSubmit",
    resolver : (values, context, options) => {
      return {
        values : values,
        errors : {}
      }
    }
  });

  const actionUrl = props?.currentData?.role?.id
    ? `/api/app/roles/${props?.currentData?.role?.id}`
    : `/api/app/roles`;

  const [permissions, setPermissions] = useState<any[]>([]);

  const onPermChange = (name : any, isGranted : boolean, parent ?: string) => {
    const rootIndex = permissions.findIndex(p => !parent ? p.name == name : p.name == parent);

    if (rootIndex >= 0 && !parent) {
      permissions[rootIndex].isGranted = isGranted;
    } else if (rootIndex >= 0 && parent) {
      if(isGranted) {
        permissions[rootIndex].isGranted = isGranted;
      }

      const childIndex = permissions[rootIndex].children.findIndex((p : any) => p.name == name);

      if(childIndex >= 0) {
        permissions[rootIndex].children[childIndex].isGranted = isGranted;
      }
    }

    setPermissions([...permissions]);
    form.setValue('permissions', permsToFormData(permissions));
  }

  useEffect(() => {
    form.reset(props.currentData);

    setPermissions(permsToNested(props?.currentData?.permissions || []));
  }, [props.currentData]);

  return (
      <Form action={actionUrl} method={props?.currentData?.role?.id ? "PUT" : "POST" } form={form} useResponseData>
        {(formProps : any) => (
          <>
            <Typography variant="h6" style={{ fontWeight: 400 }}>Dane podstawowe</Typography>

            <Paper style={{ padding: 16 }} elevation={1}>
              <Grid container spacing={1}>
                <Grid container item spacing={3}>
                  <Grid item xs={3}>
                    <TextField
                      name="role.name"
                      label="Nazwa systemowa"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      name="role.extraProperties.DisplayName"
                      label="Etykieta"
                    />
                  </Grid>
                </Grid>
              </Grid>

            </Paper>


            <Authorize anyPerm={["AbpIdentity.Roles.ManagePermissions"]}>
              {permissions.length > 0 && (
                  <>
                    <Typography variant="h6" style={{  marginTop: 16, fontWeight: 400 }}>Uprawnienia</Typography>

                    <Paper style={{ padding: 16 }} elevation={1}>
                      <Permissions items={permissions} onChange={onPermChange} />
                    </Paper>
                  </>
              )}
            </Authorize>


            <Divider style={{ marginTop: 24, marginBottom: 8 }} />
          </>
        )}
      </Form>
  );
};

export default function CreateUpdate(props: any) {
  const params = useParams();
  const [currentData, setCurrentData] = useState<any>({});

  useEffect(() => {
    if(props.mode == "update" && params.id) {
      (async () => {
        var data = await api.common.roles.get(params.id);
        setCurrentData(data.data);
      })();
    }
  }, []);

  return (
      <>
        <Breadcrumbs title={props.mode == "update" ? "Aktualizacja" : "Tworzenie"} />

        <MainForm currentData={currentData} />
      </>
  );
}