import axios from "axios";
import { getAuthToken } from "../../Context/AuthContext";

const client = axios.create({
  baseURL : window.location.hostname.includes('localhost')
      ? 'https://localhost:44342'
      : window.location.origin,
})

client.interceptors.request.use(function (config) {
  config.headers["Authorization"] = `Bearer ${getAuthToken()}`;
  return config;
});

export const swrDataFetcher = (url : string, search ?: string , test ?: any) => client.get(url, { params : { search : search, skipCount : 0, maxResultCount : 10 } }).then(res => res.data);

export const swrDataFetcherV2 = async (url : string, dataAsJson ?: string ) => {
  const data = dataAsJson ? JSON.parse(dataAsJson) : {};
  const method = (data?.method ?? "get").toLowerCase();
  delete data.method;

  const request = await client.request({
    url : url,
    method : method,
    params : method == "get" && dataAsJson ? data : {},
    data : method == "post" && dataAsJson ? data : {},
  });

  return request.data;
}
