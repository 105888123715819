import {
  useGetDataFromApi,
  useGetDataType, useGetFormType,
  useGetSingleControlCard,
  useGetSingleControlCardTemplate
} from "../DataAccess";
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from "react";
import {
  Accordion,
  Accordion as MuiAccordion, AccordionDetails,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Divider,
  Grid,
  MenuItem,
  Typography, unstable_useId,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import FormTextField from "../../Core/Forms/Fields/TextField";
import CheckboxFormControl from "../DataWarehouse/Form/Control/CheckboxFormControl";
import _ from "lodash";
import SelectField from "../../Core/Forms/Fields/SelectField";
import { FormProvider, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { mergeWithDefaultData } from "../../Common/Utils";
import api from "../../Api";
import { navigate } from "@reach/router";
import Button from "../../Components/Button";
import { CheckCircle, Plus, Save as SaveIcon, Trash } from "react-feather";
import SearchableSelectField from "../../Core/Forms/Fields/SearchableSelectField";
import DateFormControl from "../DataWarehouse/Form/Control/DateFormControl";
import DateTimeFormControl from "../DataWarehouse/Form/Control/DateTimeFormControl";
import TimePickerFormControl from "../DataWarehouse/Form/Control/TimePickerFormControl";
import ContentDisplayFormControl from "../DataWarehouse/Form/Control/ContentDisplayFormControl";
import SelectFormControl from "../DataWarehouse/Form/Control/SelectFormControl";
import { v4 as uuid4 } from 'uuid';
import Confirm from "../../Core/Utils/Confirm";

const PREFIX = 'ControlCardView';

const classes = {
  root: `${PREFIX}-root`
};


type ControlCardViewMode = "approve" | "operator" | "view";

type ControlCardViewProps = {
  id : string;
  mode : ControlCardViewMode;
};


// @todo style
//
// const Accordion = ',
//     flexGrow: 1,
//     '&:hover': {
//       // borderColor: 'rgba(0, 0, 0, 0.87)'
//     },
//     '&:not(:last-child)': {
//       marginBottom : 10
//     },
//     '&:before': {
//       display: 'none',
//     },
//     '&$expanded': {
//       margin: 'auto',
//       marginBottom : 10
//     },
//   },
//   [`& .${classes.expanded}`]: {},
// })(MuiAccordion);

const AccordionSummary = (MuiAccordionSummary);


const StyledAccordion = styled(MuiAccordion)({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    paddingBottom : 0,
    marginBottom : '8px',
    marginTop : '0px',

    '&:before' : {
      display: 'none',
    },

    '& .MuiAccordionSummary-root' : {
      minHeight : 'auto',
    },

    '& .MuiAccordionSummary-content': {
      margin: '8px 0',
      flexDirection : "column"
    },

    '& .MuiAccordionSummary-root.Mui-expanded' : {
      '& .MuiAccordionSummary-content': {
        margin: '8px 0',
      },
    },

    '.MuiAccordionDetails-root' : {
      borderTop: '1px solid rgba(0, 0, 0, 0.23)',
    }
  },
});

const NestedAccordion = styled(StyledAccordion)({
  boxShadow : "none",
  border : "1px solid rgba(0, 0, 0, 0.23)",
  marginBottom : "8px",
  borderRadius : "4px"
});



const CardElementDescription = styled("div")({
  fontSize: "12px",
  marginTop : "5px",
  "p" : {
    margin :0
  }
});


const ReadOnlyDiv = styled("div")({
  display : "flex",
  borderRadius : 4,
  fontSize : 16,
  border : "1px solid rgba(0, 0, 0, 0.1)",
  background : "rgba(0, 0, 0, 0.01)",
  padding : "10.5px 14px",
  minHeight : 40,
  alignItems : "center"
});

const getImage = (props : any) => {


};

const FormElementControl = (props : any) => {
  const isReadOnly = props?.isReadOnly || false;

  const setFormValue = (value : any) => {
    props.form.setValue(props.name, value);
  }

  const getFormValue = () => {
    return props.form.getValues(props.name) || "";
  }

  const [currentValue, setCurrentValue] = useState<any>(props.data || getFormValue());

  const settings = _.get(props, ['dataType', 'settings'], []);
  const options = (_.find(settings, (o) => o.type === "availableOptions")?.value?.options) || [];
  const isMultipleSelect = (_.find(settings, (o) => o.type === "multipleSelect")?.value?.isEnabled) || false;

  useEffect(() => {
    setCurrentValue(props.data);
  }, [props.data]);

  if(props.dataType.controlType == "text") {
    if(isReadOnly) {
      return <ReadOnlyDiv>{getFormValue()}</ReadOnlyDiv>;
    }

    return <FormTextField name={props.name} defaultValue={currentValue} label={"Wartość"} />;
  }

  if(props.dataType.controlType == "date") {
    return <DateFormControl isReadOnly={isReadOnly} form={props.form} name={props.name} />
  }

  if(props.dataType.controlType == "datetime") {
    return <DateTimeFormControl isReadOnly={isReadOnly} form={props.form} name={props.name} />
  }


  if(props.dataType.controlType == "time") {
    return <TimePickerFormControl isReadOnly={isReadOnly} form={props.form} name={props.name} />
  }


  if(props.dataType.controlType == "checkbox") {
    return <CheckboxFormControl isReadOnly={isReadOnly} form={props.form} name={props.name} />
  }

  if(props.dataType.controlType == "contentDisplay") {
    return <ContentDisplayFormControl isReadOnly={isReadOnly} form={props.form} name={props.name} />
  }

  return (
     <SelectFormControl
         isReadOnly={isReadOnly}
         multiple={isMultipleSelect}
         options={options}
         name={props.name}
         form={props.form}
         label={"Wybierz"}
     />
  )
}

const FormElement = (props : any) => {
  const template = props.template;
  const dataType = template.dataType;
  // const { data : dataType } = useGetDataType(template.dataTypeId);

  let unitName = dataType?.storedValueUnitType?.name;
  unitName = unitName ? `[${unitName}]` : ``;

  const description = props?.element?.description;
  const controlType = dataType?.controlType;
  const isDescriptionRow = controlType == "contentDisplay";
  const isFullWidthRow = controlType == "contentDisplay";

  return (
      <>
        <Grid container item spacing={3} alignItems="flex-start">

          <Grid item xs={isFullWidthRow ? 12 : 5} lg={isFullWidthRow ? 12 : 3} xl={isFullWidthRow ? 12 : 2} >
            <div>
              <b>{template.name} {unitName}</b>
            </div>

            {description && !isDescriptionRow && (
                <div>
                  <small>{description}</small>
                </div>
            )}

            {description && isDescriptionRow && (
                <div dangerouslySetInnerHTML={{ __html : description }}></div>
            )}
          </Grid>

          {!isFullWidthRow && (
              <Grid item xs={5} lg={4} xl={3}>
                {dataType && (
                    <FormElementControl
                      data={props.data}
                      form={props.form}
                      name={props.name}
                      dataType={dataType}
                      isReadOnly={props.isReadOnly || false}
                    />
                )}
              </Grid>
          )}

        </Grid>
      </>
  )
}

const CardElement = (props : any) => {
  const cardElementTemplate = props.element;
  const formTemplate = cardElementTemplate.formTemplate;
  // const { data : formTemplate } = useGetFormType(props?.element?.formTemplateId);
  const isReadOnly = props?.isReadOnly || false;
  const isSubmitted = props?.isSubmitted || false;
  const inProgress = props?.inProgress || false;
  const deletable = props?.deletable ?? false;
  const isNested = props?.isNested ?? false;

  // Repeatable elements
  const index = props?.index ?? "0";

  const getDataStorePath = (formTemplateElement : any) => {
    const cardElementPath = [cardElementTemplate.id, index].join("/");
    return `elements[${cardElementPath}][${formTemplateElement.id}]`;
  };

  const onDeleteClick = () => {
    props?.onDelete && props.onDelete();
  };

  if(!props?.element?.formTemplateId) {
    return <>Brak danych</>
  }

  const Wrapper = isNested ? NestedAccordion : StyledAccordion;

  return (
      <Wrapper
        elevation={1}
        expanded={props.expanded}
        onChange={props.onExpandChange}
        className={classes.root}
      >
        <AccordionSummary>
          <Typography sx={{ display : "flex", alignItems : "center" }}>
            { props.element.name }
            {isReadOnly && (
                <small style={{ marginLeft : 6 }}>[Podgląd]</small>
            )}
            {isSubmitted && (
                <small style={{ marginLeft : 6 }}>[Zatwierdzony]</small>
            )}
          </Typography>

          {props?.element?.description && (
              <CardElementDescription dangerouslySetInnerHTML={{ __html : props?.element?.description }} />
          )}
        </AccordionSummary>

        <AccordionDetails sx={{ padding: '8px 16px' }}>
          <Grid container spacing={2}>
            {formTemplate?.elements?.map((formTemplateElement : any, formTemplateElementIndex : number) =>
                <FormElement
                  key={formTemplateElementIndex}
                  form={props.form}
                  data={""} // Unused
                  name={getDataStorePath(formTemplateElement)}
                  template={formTemplateElement}
                  isReadOnly={isReadOnly || isSubmitted}
                />
            )}
          </Grid>

          {!isReadOnly && !isSubmitted && (
              <>
                <Divider sx={{ margin : "16px -16px 8px" }} />

                <Grid container spacing={2} alignItems="flex-start">
                  <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<SaveIcon size={22}/>}
                        type="button"
                        inProgress={inProgress}
                        style={{ marginRight: 16 }}
                        onClick={() => props?.onSave(props?.element?.formTemplateId) || _.noop}
                    >
                      Zapisz
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      inProgress={inProgress}
                      startIcon={<CheckCircle size={22}/>}
                      type="button"
                      onClick={() => props?.onSubmit(props?.element?.formTemplateId) || _.noop}
                    >
                      Zatwierdź
                    </Button>

                    {deletable && (
                        <Confirm
                          onConfirm={onDeleteClick}
                        >
                          {(onClick : any) => (
                            <Button
                              variant="contained"
                              inProgress={inProgress}
                              color="error"
                              startIcon={<Trash size={22}/>}
                              type="button"
                              style={{ marginLeft: 16 }}
                              onClick={onClick}
                            >
                              Usuń
                            </Button>
                          )}
                      </Confirm>
                    )}

                  </Grid>
                </Grid>
              </>
          )}

        </AccordionDetails>
      </Wrapper>
  )
}


const ControlCardRepeatableElementsGroup = (props : any) => {
  const name = props?.name;
  const description = props?.description;
  const formData = props?.formData ?? {};
  const isEditable = props?.isEditable ?? false;
  const cardElementTemplate = props?.template;
  const isSubmitted = props?.isSubmitted ?? false;
  const isReadOnly = props?.isReadOnly ?? false;

  const [entries, setEntries] = useState<any[]>(props.entries);
  const [localSubmits, setLocalSubmits] = useState<string[]>([]);

  const entriesAddAllowed = () => {
    let maxEntries = parseInt(cardElementTemplate?.repetitionSettings?.maxRepetitions ?? -1);
    maxEntries = !isNaN(maxEntries) ? maxEntries : -1;

    return isEditable && !isReadOnly && (entries.length < maxEntries || maxEntries == -1);
  };

  const removeEntry = async (entry : any) => {
    if(entry.id) {
      await api.app.controlCards.cards.deleteCardElement(entry.id);
      setEntries([
        ...entries.filter(i => i.id != entry.id)
      ]);
    } else {
      setEntries([
        ...entries.filter(i => i.index != entry.index)
      ]);
    }
  };

  return (
    <StyledAccordion
      elevation={1}
      expanded={props.expanded}
      onChange={props.onExpandChange}
      className={classes.root}
    >
      <AccordionSummary>
        <Typography sx={{ display : "flex", alignItems : "center" }}>
          { name }
          {isReadOnly && (
              <small style={{ marginLeft : 6 }}>[Podgląd]</small>
          )}
          {isSubmitted && (
              <small style={{ marginLeft : 6 }}>[Zatwierdzony]</small>
          )}
        </Typography>

        {description && (
            <CardElementDescription dangerouslySetInnerHTML={{ __html : description }} />
        )}
      </AccordionSummary>

      <AccordionDetails sx={{ padding: '8px 16px' }}>
        {entries.map((entry) => {
          const data : any = {};
          const savePayload = {
            elementTemplateId : cardElementTemplate.id,
            index : entry.index
          };

          return (
            <CardElement
              key={entry.index}
              expanded={true}
              onExpandChange={() => {}}
              form={props.form}
              element={cardElementTemplate}
              data={{}} // Non existing data // loaded from form store
              index={entry.index}
              isReadOnly={entry?.isReadOnly ?? !isEditable}
              isSubmitted={(localSubmits.includes(entry.index)) || (props?.isSubmitted ?? false) || (entry?.isSubmitted ?? false)}
              inProgress={props.inProgress ?? false}
              isNested
              deletable
              onDelete={() => removeEntry(entry)}
              onSave={() => {
                props.onSaveSingle(savePayload);
              }}
              onSubmit={async () => {
                const result = await props.onSubmitSingle(savePayload);

                if(result === true) {
                  setLocalSubmits([...localSubmits, entry.index]);
                }
              }}
            />
          )
        })}

        {!props.isSubmitted && (
          <div style={{ marginTop : "8px" }}>
            {entriesAddAllowed() && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<Plus size={20}/>}
                type="button"
                size="small"
                sx={{ marginRight : "8px" }}
                inProgress={props.inProgress ?? false}
                onClick={() => {
                  setEntries([...entries, {
                    index : uuid4()
                  }]);
                }}
              >
                Dodaj wpis
              </Button>
            )}

            {isEditable && !isReadOnly && entries.length > 0 && (
              <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<CheckCircle size={22}/>}
                  type="button"
                  inProgress={props.inProgress ?? false}
                  onClick={(e : any) => {
                    props.onSubmitGroup();
                  }}
              >
                Zatwierdź grupę
              </Button>
            )}
          </div>
        )}

      </AccordionDetails>
    </StyledAccordion>
  )
};

type MainFormProps = {
  mode : ControlCardViewMode;
  currentData ?: any;
  templateData ?: any;
  cardFormData ?: any;
}

type CardItem = {
  key : string;
  name : string;
  description ?: string;
  type : "element" | "repeatableElementsGroup";
  entries : any[];
  template : any;
  isReadOnly : boolean;
  isSubmitted : boolean;
}

const prepareCardItems = (formElements : any[], localData : any) : CardItem[] => {
  const elements : CardItem[] = [];
  const groups : string[] = [];

  const mapEntry = (templateData : any, entryData : any) => {
    const isSubmittedLocal = !!localData[`${templateData.id}.${(entryData.index ?? "0")}.element.isSubmitted`];

    if(isSubmittedLocal) {
      entryData.isSubmitted = true;
    }

    return entryData;
  }

  formElements.forEach((element : any, index) => {
    const isRepeatable = element?.template?.repetitionSettings?.isRepeatable ?? false;
    const templateId = element?.template?.id ?? "";
    const isReadOnly = element.isReadOnly;

    if(!isRepeatable) {
      const isSubmittedLocal = !!localData[`${element.template.id}.${("0")}.element.isSubmitted`];

      elements.push({
        key : `${index}`,
        name : element?.template?.name ?? "",
        description : element?.template?.description ?? "",
        type : "element",
        entries : element.entries.map((entry : any) => mapEntry(element.template, entry)),
        template : element.template,
        isSubmitted : isSubmittedLocal || element.isSubmitted,
        isReadOnly
      })
    } else {
      if(!groups.includes(templateId)) {
        groups.push(templateId);
        const isSubmittedLocal = !!localData[`${element.template.id}.group.isSubmitted`];

        elements.push({
          key : `${index}`,
          name : element?.template?.name ?? "",
          description : element?.template?.description ?? "",
          type : "repeatableElementsGroup",
          template : element.template,
          entries : element.entries.map((entry : any) => mapEntry(element.template, entry)),
          isSubmitted : isSubmittedLocal || element.isSubmitted,
          isReadOnly
        })
      }
    }
  });

  return elements;
}

const MainForm = (props: MainFormProps) => {
  const [isEditable, setIsEditable] = useState(props.mode == "operator");
  const [inProgress, setInProgress] = useState(false);
  const form = useForm({
    reValidateMode : "onSubmit",
    resolver : (values, context, options) => {
      return {
        values : values,
        errors : {}
      }
    }
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [formData, setFormData] = useState<any>({...props.currentData});
  const [templateData, setTemplateData] = useState<any>({...props.cardFormData.template});

  const [expandedElements, setExpandedElements] = useState<{ [index : string] : boolean }>({
    0 : true
  });

  const [localData, setLocalData] = useState<any>({});
  const [allElementsExpanded, setAllElementsExpanded] = useState(false);

  const cardFormData = props?.cardFormData || {};

  useEffect(() => {
    setTemplateData(props.templateData || {});

    const elementsData : any = {};

    _.each((props?.currentData?.elementsData) ?? {}, (formValues, cardTemplateElementId) => {
      _.each(formValues, (formValue, formElementTemplateId) => {
        _.set(elementsData, [cardTemplateElementId, formElementTemplateId], formValue);
      });
    });

    var data = mergeWithDefaultData({
          elements : {}
        },
        {
          templateId : props.currentData?.controlCardTemplateId,
          elements : elementsData
        }
    );

    form.reset(data);
    setFormData({ ...data });
  }, [props]);

  const onSubmit = async (data: any) => {
    setInProgress(true);
    try {
      var payload = {
        name : templateData.name,
        controlCardTemplateId : templateData.id,
        elementsData : data.elements || {},
        isSubmitted : data?.isSubmitted || false
      };

      if (props.currentData && props.currentData.id) {
        await api.app.controlCards.filled.update(props.currentData.id, payload);
        enqueueSnackbar('Zmiany zapisane');
      } else {
        await api.app.controlCards.filled.create(payload);
        enqueueSnackbar('Zmiany zapisane');
        await navigate("/control-cards/filled");

      }

    } catch (e : any) {
      const error = _.get(e, 'response.data.error.message', e.message);
      enqueueSnackbar(error, {
        variant : "error"
      })

      // Validation errors
      const validationErrors = _.get(e, 'response.data.error.validationErrors', []);
      if(_.isArray(validationErrors)) {
        console.log(validationErrors);
        validationErrors.forEach((validationError) => {
          const errorKey = ((validationError.members as []) || []).join(".");

          if(errorKey) {
            form.setError(errorKey, {
              type : "manual",
              message : validationError.message
            })
          }

        });
      }

      console.error(e);
    } finally {
      setInProgress(false);
    }
  };

  const saveGroup = async (cardTemplateElementId : any) => {
    try {
      setInProgress(true);

      await api.app.controlCards.cards.submitCardElementsGroup(props.currentData.id, cardTemplateElementId);
      enqueueSnackbar('Grupa zatwierdzona');
      setLocalData({
        ...localData,
        [`${cardTemplateElementId}.group.isSubmitted`] : true
      });
    } catch (e : any) {
      const error = _.get(e, 'response.data.error.message', e.message);
      enqueueSnackbar(error, {
        variant : "error"
      })

      // Validation errors
      const validationErrors = _.get(e, 'response.data.error.validationErrors', []);
      if(_.isArray(validationErrors)) {
        console.log(validationErrors);
        validationErrors.forEach((validationError) => {
          const errorKey = ((validationError.members as []) || []).join(".");

          if(errorKey) {
            form.setError(errorKey, {
              type : "manual",
              message : validationError.message
            })
          }

        });
      }
      console.error(e);
    } finally {
      setInProgress(false);
    }
  };

  const saveSingleFormElement = async (payload : any, submit : boolean = false) => {
    const cardElementIndex = payload.index ?? "0";
    const elementTemplateId = payload?.elementTemplateId;
    const cardElementDataPath = [elementTemplateId, cardElementIndex].join("/");
    const data = form.getValues(`elements[${cardElementDataPath}]`)

    setInProgress(true);
    try {
      if (props.currentData && props.currentData.id) {
        await api.app.controlCards.cards.saveCardElement(props.currentData.id, {
          elementTemplateId : elementTemplateId,
          data : data,
          index : cardElementIndex,
          submit : submit
        });

        enqueueSnackbar('Zmiany zapisane');

        if(submit) {
          setLocalData({
            ...localData,
            [`${elementTemplateId}.${cardElementIndex}.element.isSubmitted`] : true
          });
        }

        form.clearErrors();
      }

      return true;
    } catch (e : any) {
      const error = _.get(e, 'response.data.error.message', e.message);
      enqueueSnackbar(error, {
        variant : "error"
      })

      // Validation errors
      const validationErrors = _.get(e, 'response.data.error.validationErrors', []);
      if(_.isArray(validationErrors)) {
        form.clearErrors();
        validationErrors.forEach((validationError) => {
          const errorKey = ((validationError.members as []) || []).join(".");

          if(errorKey) {
            form.setError(errorKey, {
              type : "manual",
              message : validationError.message
            })
          }

        });
      }

      return false;
    } finally {
      setInProgress(false);
    }
  };

  const cardItems = prepareCardItems(cardFormData?.elements ?? [], localData);


  const toggleElementsExpansion = () => {
    const expand = allElementsExpanded ? false : true;

    cardItems.forEach(item => {
      expandedElements[item.key] = expand;
    });

    setExpandedElements(expandedElements);
    setAllElementsExpanded(expand);
  };


  return (
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <FormProvider {...form}>
          <div>
            <Button variant="contained" size="small" onClick={toggleElementsExpansion}>
              {allElementsExpanded ? "Zwiń wszystko" : "Rozwiń wszystko"}
            </Button>
          </div>

          <div style={{ marginTop : 8 }}>
            {cardItems.map((cardItem) => {
            const key = cardItem.key;

            if(cardItem.type == "repeatableElementsGroup") {
              return (
                  <ControlCardRepeatableElementsGroup
                    key={key}
                    name={cardItem.name}
                    description={cardItem.description}
                    expanded={!!expandedElements[key]}
                    onExpandChange={(e : any, expanded : any) => {
                      expandedElements[key] = !expandedElements[key];
                      setExpandedElements({ ...expandedElements });
                    }}
                    form={form}
                    entries={cardItem.entries}
                    inProgress={inProgress}
                    formData={formData}
                    isEditable={isEditable}
                    isReadOnly={cardItem.isReadOnly || !isEditable}
                    isSubmitted={cardItem.isSubmitted}
                    template={cardItem.template}
                    onSaveSingle={(payload : any) => {
                      return  saveSingleFormElement(payload);
                    }}
                    onSubmitSingle={(payload : any) => {
                      return saveSingleFormElement(payload, true);
                    }}
                    onSubmitGroup={() => {
                      return saveGroup(cardItem.template.id);
                    }}
                  />
              );
            }

            const data : any = cardItem.entries?.[0];

            const savePayload = {
              elementTemplateId : cardItem.template?.id,
              index : "0"
            };

            return (
              <CardElement
                expanded={!!expandedElements[key]}
                onExpandChange={(e : any, expanded : any) => {
                  expandedElements[key] = !expandedElements[key];
                  setExpandedElements({ ...expandedElements });
                }}
                key={key}
                form={form}
                element={cardItem.template}
                data={{}} // Non existing data // loaded from form store
                isReadOnly={cardItem.isReadOnly || !isEditable}
                isSubmitted={cardItem.isSubmitted || (data?.isSubmitted ?? false)}
                inProgress={inProgress}
                onSave={() => {
                  saveSingleFormElement(savePayload);
                }}
                onSubmit={() => {
                  saveSingleFormElement(savePayload, true);
                }}
              />
            )
          })}
          </div>
        </FormProvider>

        {isEditable && props.mode != "operator" && (
            <>
              <Divider style={{marginTop: 24, marginBottom: 8}}/>

              <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon size={22}/>}
                  type="submit"
                  inProgress={inProgress}
                  style={{ marginRight: 16 }}
              >
                Zapisz
              </Button>

              <Button
                  variant="contained"
                  color="primary"
                  startIcon={<CheckCircle size={22}/>}
                  type="button"
                  inProgress={inProgress}
                  onClick={(e : any) => {
                    form.setValue("isSubmitted", true);
                    form.handleSubmit(onSubmit)();
                  }}
              >
                Zatwierdź
              </Button>
            </>
        )}

      </form>
  );
};

const StyledMainForm = styled(MainForm)({
  [`& .${classes.root}`]: {
    display : "flex",
    borderRadius : 4,
    fontSize : 16,
    border : "1px solid rgba(0, 0, 0, 0.1)",
    background : "rgba(0, 0, 0, 0.01)",
    padding : "10.5px 14px",
    minHeight : 40,
    alignItems : "center"
  }
});

const ControlCardView = (props : ControlCardViewProps) => {
  const card = useGetSingleControlCard(props.id);
  const formData = useGetDataFromApi(`/api/app/control-card/form-data/${props.id}`);

  if(card.isLoading || formData.isLoading) {
    return <>Loading...</>
  }

  return <StyledMainForm mode={props.mode} currentData={card.data} cardFormData={formData.data} />;
};

export default ControlCardView;