import CheckboxField from "../../../../Core/Forms/Fields/CheckboxField";
import { styled } from '@mui/material/styles';
import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import _ from "lodash";
import { Check, CheckCircle, X } from "react-feather";
import { Checkbox } from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import SearchableSelectField, { SearchableSelectFieldOption } from "../../../../Core/Forms/Fields/SearchableSelectField";


export interface SelectFormControlProps
{
  name : string;
  multiple ?: boolean;
  options : SearchableSelectFieldOption[];
  label ?: string;
  defaultValue ?: number | string | SearchableSelectFieldOption[];
  form ?: UseFormReturn;
  controlProps ?: any;
  isReadOnly ?: boolean;
}

const ReadOnlyDiv = styled("div")({
    display : "flex",
    alignItems : "center",
    borderRadius : 4,
    fontSize : 16,
    border : "1px solid rgba(0, 0, 0, 0.1)",
    background : "rgba(0, 0, 0, 0.01)",
    padding : "5px 14px",
    minHeight : 40,
    marginBottom : 8
});


const ReadOnlyContainer = styled("div")({
  display : "flex",
  flexDirection : "column"
});

const OptionItem = (props : any) => {
  const imageUrl = props?.attributes?.find((attr : any) => attr.type === "image")?.value;

  return (
      <ReadOnlyDiv>
        {imageUrl && (<div style={{ width : 42, height : 42, display : "flex", alignItems : "center", justifyContent : "center", marginRight : 5 }}>
          <img style={{ maxWidth : "100%", height : "auto" }} src={imageUrl} />
        </div>)}

        <div>{props?.label ?? ""}</div>
      </ReadOnlyDiv>
  );
}


const SelectFormControl = (props : SelectFormControlProps) => {
  const isReadOnly = props?.isReadOnly || false;

  const getFormValue = () => {
    return props.form?.getValues(props.name) ?? "";
  }

  if(isReadOnly) {
    const valueAsArray = Array.isArray(getFormValue()) ? getFormValue() : [getFormValue()];
    const selectedOptions = props.options.filter(option => valueAsArray.includes(option.value));
    return <ReadOnlyContainer>{selectedOptions.map((option) => <OptionItem {...option} />)}</ReadOnlyContainer>;
  }


  return <SearchableSelectField
      isReadOnly={props.isReadOnly}
      multiple={props.multiple}
      options={props.options}
      name={props.name}
      label={"Wybierz"}
  />;
};

export default SelectFormControl;