import { navigate, RouteComponentProps } from "@reach/router";
import Breadcrumbs from "../../../Components/Breadcrumbs";
import ListDataViewer from "../../../Core/ListDataViewer/ListDataViewer";
import React from "react";

export function List(props: RouteComponentProps) {
  return (
      <>
        <Breadcrumbs title="Karty kontrolne" />

        <ListDataViewer
          searchIndexEnabled
          exportEnabled
          settingsEnabled
          endpoints={{
            base : `/api/app/control-card`
          }}
          paths={{
            base : `/control-cards/filled`
          }}
          onRowDoubleClick={async (row) => {
            await navigate(`/control-cards/${row.original.id}/view`);
          }}
          columns={[
            {
              Header: 'Nazwa',
              accessor: 'fields.Name',
            },

            {
              Header: 'Zatwierdzona',
              accessor: 'fields.IsApproved',
              type : "boolean"
            },

            {
              Header : 'Data modyfikacji',
              accessor: 'fields.LastModificationTime',
              type : 'datetime'
            },

            {
              Header : 'Data utworzenia',
              accessor: 'fields.CreationTime',
              type : 'datetime'
            },
          ]}
        />
      </>
  );
}

export default React.memo(List);