import { useParams } from "@reach/router";
import Breadcrumbs from "../../../Components/Breadcrumbs";
import ControlCardView from "../../../OmniData/ControlCard/ControlCardView";
import React from "react";

export const View = () => {
  const { id } = useParams();

  return (
      <>
        <Breadcrumbs title={"Podgląd karty"}/>
        <ControlCardView id={id} mode="view" />
      </>
  );
}

export default View;