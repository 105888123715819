import { Box, Breadcrumbs as MuiBreadcrumbs, Typography, Link as MuiLink } from "@mui/material";
import { Link } from "@reach/router";
import React from "react";
import { ChevronRight as ChevronRightIcon } from "react-feather";


const NavigationLink = React.forwardRef((props: any, ref) => {
  return (
    <Link to={props.href} ref={ref} {...props}>{props.children}</Link>
  );
});

type BreadcrumbsProps = {
  title: string;
};

export default function Breadcrumbs(props: BreadcrumbsProps) {
  return (
    <Box style={{ marginBottom: 16 }}>
      <Typography variant="h5">{props.title}</Typography>

      <MuiBreadcrumbs separator={<ChevronRightIcon size={16} />} aria-label="breadcrumb" style={{ fontSize: 14 }}>
        <MuiLink href="/" component={NavigationLink}>
          Panel
        </MuiLink>
        <Typography color="textPrimary" style={{ fontSize: 14 }}>{props.title}</Typography>
      </MuiBreadcrumbs>
    </Box>
  );
}