import React, { useState } from "react";

const UIContext = React.createContext<UIStateType | undefined>(undefined);
const UIActionsContext = React.createContext<UIActionsType | undefined>(undefined);

type UIStateType = {
  drawerOpened: boolean;
  layout: any;
};

type UIActionsType = {
  toggleDrawer: (isOpened?: boolean) => void;
  setLayout: (layout: any) => void;
};

export const UIContextProvider: React.FC = (props) => {
  const [state, setState] = useState<UIStateType>({
    drawerOpened: true,
    layout: undefined
  });

  const changeState = (prop: any, value: any) => {
    setState({ ...state, [prop]: value });
  };

  const actions = {
    toggleDrawer: (isOpened?: boolean) => {
      changeState('drawerOpened', isOpened === undefined ? !state.drawerOpened : isOpened);
    },

    setLayout: (layout: any) => {
      if (!Object.is(state.layout, layout)) {
        changeState('layout', layout);
      }
    }
  };

  return (
    <UIContext.Provider value={state}>
      <UIActionsContext.Provider value={actions}>
        {props.children}
      </UIActionsContext.Provider>
    </UIContext.Provider>
  );
};

export const useUIContext = () => {
  return {
    state: React.useContext<UIStateType>(UIContext as React.Context<UIStateType>),
    actions: React.useContext<UIActionsType>(UIActionsContext as React.Context<UIActionsType>)
  };
};