import React from "react";
import DatePickerField from "../../../../Core/Forms/Fields/DatePickerField";
import DateTimePickerField from "../../../../Core/Forms/Fields/DateTimePickerField";
import TimePickerField from "../../../../Core/Forms/Fields/TimePickerField";
import ReadOnlyDateTimeFormControl from "./ReadOnlyDateTimeFormControl";

const TimePickerFormControl = (props : any) => {
  return <TimePickerField
      name={props?.name}
      isReadOnly={props?.isReadOnly || false}
      form={props?.form}
      readOnlyRender={(inputValue : string, format : string) => <ReadOnlyDateTimeFormControl value={inputValue} format={format} />}
  />;
};

export default TimePickerFormControl;