import { Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Autocomplete } from "@mui/material";

export interface EnumFilterProps
{
  label : string;
  id : any;
  currentValue : any;
  availableValues : any[];
  onChange : (newValue : any) => void;
}

const normalizeValue = (v : any) => v == undefined ? null : v;

const EnumFilter = (props : EnumFilterProps) => {
  const [value, setValue] = useState<undefined | null | string>(normalizeValue(props?.currentValue?.values?.[0]));

  const submit = () => {
    if(!value || value.trim() == "") {
      props.onChange(undefined);
    } else {
      props.onChange({
        values : [value],
      });
    }
  };

  const options = props.availableValues.map(opt => opt.value);
  
  const getLabel = (value : string) : string => {
    return props.availableValues.find(option => option.value == value)?.label ?? value
  };
  
  return (
      <form onSubmit={(e) => { e.preventDefault(); submit(); }}>
        <div>
          <Autocomplete
            value={normalizeValue(value)}
            onChange={(e,v : string | null) => setValue(v)}
            options={options}
            getOptionLabel={getLabel}
            renderInput={(params) => <TextField {...params} size="small" label={props.label} />}
          />
        </div>
        <div>
          <Button sx={{ marginTop : "8px" }} onClick={submit} disableElevation fullWidth variant="contained">Filtruj</Button>
        </div>
      </form>
  )
}

export default EnumFilter;