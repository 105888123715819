import React, { useState } from "react";
import {  TextField } from "@mui/material";
import { DatePicker, DatePickerProps } from "@mui/lab";
import { Controller, useFormContext } from "react-hook-form";
import _ from "lodash";

const ensureValidValue = (value : any) => {
  return value === undefined ? null : value;
}

const DatePickerField = (props : any) => {
  const formContext = useFormContext();
  const form = props.form ? props.form : formContext;
  const name = props.name ?? "DatePickerField";
  const isReadOnly = props?.isReadOnly ?? false;

  const format = "dd/MM/yyyy";
  const controlProps = _.merge<Partial<DatePickerProps>, Partial<DatePickerProps>>({
    inputFormat : format,
    allowSameDateSelection : true,
    clearable : true,
    readOnly : isReadOnly
  }, props.controlProps || {});

  const error = _.get(form.formState.errors, name, undefined);
  const errorMessage = error?.message;
  const hasError = !!error;

  return (
    <Controller
      name={name}
      control={form.control}
      defaultValue={ensureValidValue(props.defaultValue)}
      render={({ field }) => {
        field.value = ensureValidValue(field.value);

        if(isReadOnly && props?.readOnlyRender) {
          return props?.readOnlyRender(field.value, format)
        }

        return (
          <DatePicker
            {...field}
            {...controlProps}
            onChange={(date : any) => {
              try {
                date = date?.toISOString();
                field.onChange(date);
              } catch {
                field.onChange(null);
              }

              // field.onChange(date.toISOString());
            }}
            renderInput={(params) => {
              return <TextField {...params} helperText={hasError ? errorMessage : undefined} error={hasError} fullWidth size="small" />;
            }}
          />
        )
      }}
    />
  );
}

export default DatePickerField;