import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
  Button as MuiButton,
  Card,
  CardActions,
  CardContent,
  Divider,
  ExtendButtonBase,
  List,
  ListItem as MuiListItem,
  ListItemTypeMap,
  Paper,
  Tab,
  Tabs,
  Typography,
  Link as MuiLink,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Link, navigate, useParams } from "@reach/router";
import React, { useEffect, useState } from "react";
import { Plus as PlusIcon, ChevronRight as ChevronRightIcon, Save as SaveIcon, Trash as TrashIcon } from "react-feather";
import Breadcrumbs from "../../../Components/Breadcrumbs";
import Button from "../../../Components/Button";
import { Controller, FormProvider, useForm, useFormContext } from "react-hook-form";
import _ from "lodash";
import api from "../../../Api";
import DataTypeAdditionalSettings from "../../../Components/DataWarehouse/DataTypeAdditionalSettingsForm";
import { mergeWithDefaultData } from "../../../Common/Utils";
import { useSnackbar } from "notistack";
import { useGetUnitTypesAsOptions } from "../../../OmniData/DataAccess";


const ControlTypeSelect = ({ getFormValue, setFormValue }: { getFormValue: any, setFormValue: any }) => {
  const [value, setValue] = useState(getFormValue("controlType"));

  useEffect(() => {
    setValue(getFormValue('controlType'));
  }, [getFormValue('controlType')]);

  return (
      <>
        <InputLabel>Rodzaj kontrolki</InputLabel>

        <Select
            label="Rodzaj kontrolki"
            value={value  || "text"}
            onChange={(e) => {
              setFormValue('controlType', e.target.value);
              setValue(e.target.value);
            }}
        >
          <MenuItem value="text">Pole tekstowe</MenuItem>
          <MenuItem value="checkbox">Checkbox</MenuItem>
          <MenuItem value="select">Pole wyboru</MenuItem>
          <MenuItem value="date">Kalendarz</MenuItem>
          <MenuItem value="datetime">Kalendarz i zegar</MenuItem>
          <MenuItem value="time">Zegar</MenuItem>
          <MenuItem value="contentDisplay">Brak kontrolki - wartość opisowa</MenuItem>
        </Select>
      </>
  );
};


const StoredValueTypeSelect = ({ getFormValue, setFormValue }: { getFormValue: any, setFormValue: any; }) => {
  const [value, setValue] = useState(getFormValue('storedValueType'));

  useEffect(() => {
    setValue(getFormValue('storedValueType'));

  }, [getFormValue('storedValueType')]);

  return (
      <>
        <InputLabel>Typ składowanych danych</InputLabel>

        <Select
            label="Typ składowanych danych"
            value={value || "text"}
            onChange={(e) => {
              setFormValue('storedValueType', e.target.value);
              setValue(e.target.value);
            }}
        >
          <MenuItem value="text">Tekst</MenuItem>
          <MenuItem value="number_integer">Liczba całkowita</MenuItem>
          <MenuItem value="number_float">Liczba zmiennoprzecinkowa</MenuItem>
          <MenuItem value="bool">Wartość logiczna (prawda/fałsz)</MenuItem>
          <MenuItem value="datetime">Data i czas</MenuItem>
        </Select>
      </>
  );
};


const UnitTypeSelect = ({ getFormValue, setFormValue }: { getFormValue: any, setFormValue: any; }) => {
  const [value, setValue] = useState(getFormValue('storedValueUnitTypeId'));
  const { data : options } = useGetUnitTypesAsOptions();

  useEffect(() => {
    setValue(getFormValue('storedValueUnitTypeId'));

  }, [getFormValue('storedValueUnitTypeId')]);

  return (
      <>
        <InputLabel shrink>Jednostka</InputLabel>

        <Select
            label="Jednostka"
            value={value || "__none__"}
            displayEmpty
            onChange={(e) => {
              setFormValue('storedValueUnitTypeId', e.target.value == "__none__" ? null : e.target.value);
              setValue(e.target.value);
            }}
        >
          <MenuItem value={"__none__"}>Brak</MenuItem>
          {options && options.length > 0 && options.map((item : any) => (
              <MenuItem value={item.value}>{item.label}</MenuItem>
          ))}
        </Select>
      </>
  );
};


const ValidatorTypeSelect = ({ getFormValue, setFormValue }: { getFormValue: any, setFormValue: any; }) => {
  const [value, setValue] = useState(getFormValue('validatorType') || 1);

  return (
      <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Walidator"
          value={value}
          onChange={(e) => {
            setFormValue('validatorType', e.target.value);
            setValue(e.target.value);
          }}
          defaultValue={'null'}
      >
        <MenuItem value={'null'}>Brak</MenuItem>
        <MenuItem value={1}>Wartość</MenuItem>
        <MenuItem value={2}>Zakres</MenuItem>
        <MenuItem value={3}>Regex</MenuItem>
      </Select>
  );
};


const MainForm = (props: any) => {
  const [inProgress, setInProgress] = useState(false);
  const form = useForm();
  const [formData, setFormData] = useState<any>({... props.currentData});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    var baseData = mergeWithDefaultData({
          displayName : null,
          controlType : "text",
          storedValueUnitTypeId : null,
          settings : []
        },
        props.currentData
    )

    form.reset(baseData);
  }, [props.currentData]);

  const onSubmit = async (data: any) => {
    setInProgress(true);
    try {
      if(props.currentData.id) {
        await api.app.dataWarehouse.dataTypes.update(props.currentData.id, data);
        enqueueSnackbar('Zmiany zapisane');
      } else {
        await api.app.dataWarehouse.dataTypes.create(data);
        await navigate("/data-warehouse/data-types")
      }

    } catch (e : any) {
      var error = _.get(e, 'response.data.error.details', e.message);
      alert(error);
      console.error(e);
    } finally {
      setInProgress(false);
    }
  };

  const getFormValue = (key: string) => {
    return form.getValues(key) ?? _.get(props.currentData, key);
  };

  const setFormValue = (key: string, value: any) => {
    form.setValue(key, value);
  };

  return (
      <form noValidate onSubmit={form.handleSubmit(onSubmit)} >

        <Typography variant="h6" style={{ fontWeight: 400 }}>Dane podstawowe</Typography>

        <Paper style={{ padding: 16 }} elevation={1}>
          <Grid container spacing={1}>
            <Grid container item spacing={3}>
              <Grid item xs={3}>
                <TextField
                    label="Nazwa"
                    id="outlined-size-small"
                    variant="outlined"
                    size="small"
                    fullWidth
                    defaultValue={getFormValue('displayName')}
                    key={getFormValue('displayName')}
                    onChange={(e) => {
                      form.setValue("displayName", e.target.value);
                    }}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <ControlTypeSelect
                      getFormValue={getFormValue}
                      setFormValue={setFormValue}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <StoredValueTypeSelect
                      getFormValue={getFormValue}
                      setFormValue={setFormValue}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <UnitTypeSelect
                      getFormValue={getFormValue}
                      setFormValue={setFormValue}
                  />
                </FormControl>
              </Grid>

            </Grid>
          </Grid>

        </Paper>

        <Typography variant="h6" style={{ fontWeight: 400, marginTop : 16  }}>Ustawienia</Typography>

        <Paper style={{ padding: 16 }} elevation={1}>
          <DataTypeAdditionalSettings form={form} name="settings" />
        </Paper>

        <Divider style={{ marginTop: 24, marginBottom: 8 }} />

        <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon size={22} />}
            type="submit"
            inProgress={inProgress}
        >
          Zapisz
        </Button>

      </form>
  );
};

export default function CreateUpdate(props: any) {
  const params = useParams();
  const [currentData, setCurrentData] = useState<any>({});

  useEffect(() => {
    if(props.mode == "update" && params.id) {
      (async () => {
        var data = await api.app.dataWarehouse.dataTypes.get(params.id);
        setCurrentData(data.data);
      })();
    }
  }, []);

  return (
      <>
        <Breadcrumbs title={props.mode == "update" ? "Aktualizacja" : "Tworzenie"} />

        <MainForm currentData={currentData} key={JSON.stringify(currentData)} />
      </>
  );
}