import { Divider, Grid, Paper, Typography } from "@mui/material";
import { navigate, useParams } from "@reach/router";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../Components/Breadcrumbs";
import api from "../../../Api";
import TextField from "../../../Core/Forms/Fields/TextField";
import apiEndpoints from "../../../OmniData/DataAccess/ApiEndpoints";
import useSimpleForm from "../../../Core/Forms/useSimpleForm";


const MainForm = (props: any) => {
  const form = useSimpleForm({
    entityId : props?.currentData?.id,
    data : props.currentData,
    baseAction : apiEndpoints.dataWarehouse.unitTypes.base()
  });

  return form.render(() => (
      <>
        <Typography variant="h6" style={{ fontWeight: 400 }}>Dane podstawowe</Typography>

        <Paper style={{ padding: 16 }} elevation={1}>
          <Grid container spacing={1}>
            <Grid container item spacing={3}>
              <Grid item xs={3}>
                <TextField
                  name="name"
                  label="Nazwa"
                />
              </Grid>
            </Grid>
          </Grid>

        </Paper>

        <Divider style={{ marginTop: 24, marginBottom: 8 }} />
      </>
  ));
};

export default function CreateUpdate(props: any) {
  const params = useParams();
  const [currentData, setCurrentData] = useState<any>({});

  useEffect(() => {
    if(props.mode == "update" && params.id) {
      (async () => {
        var data = await api.app.dataWarehouse.unitTypes.get(params.id);
        setCurrentData(data.data);
      })();
    }
  }, []);

  return (
      <>
        <Breadcrumbs title={props.mode == "update" ? "Aktualizacja" : "Tworzenie"} />

        <MainForm currentData={currentData} />
      </>
  );
}