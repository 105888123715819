import { CssBaseline, ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import React from 'react';
import { UIContextProvider, useUIContext } from './Context/UIContext';
import theme from './theme/MuiTheme';
import { Router, useLocation } from "@reach/router";

import Login from './Pages/Auth/login';
import Dash from './Pages/index';
import MainLayoutProvider from './Components/Layouts/MainLayoutProvider';
import MainRoute from './Components/MainRoute';

import DataTypesList from './Pages/Data/Types/List';
import DataTypeCreateUpdate from './Pages/Data/Types/CreateUpdate';

import DataWarehouseFormTypesList from './Pages/Data/FormTypes/List';
import DataWarehouseFormTypesCreateUpdate from './Pages/Data/FormTypes/CreateUpdate';

import ControlCardTemplatesList from './Pages/ControlCards/Templates/List';
import ControlCardTemplatesCreateUpdate from './Pages/ControlCards/Templates/CreateUpdate';

import ControlCardFilledList from './Pages/ControlCards/Card/List';
import ControlCardFilledCreateUpdate from './Pages/ControlCards/Filled/CreateUpdate';

import ControlCardGroupsList from './Pages/ControlCards/Groups/List';
import ControlCardGroupsCreateUpdate from './Pages/ControlCards/Groups/CreateUpdate';

import ControlCardTypesList from './Pages/ControlCards/Types/List';
import ControlCardTypesCreateUpdate from './Pages/ControlCards/Types/CreateUpdate';

import UnitTypesList from './Pages/Data/Units/List';
import UnitTypeCreateUpdate from './Pages/Data/Units/CreateUpdate';

import OrganizationOUManage from './Pages/Administration/OU/Manage'

import OrganizationUsersList from './Pages/Administration/Users/List';
import OrganizationUsersCreateUpdate from './Pages/Administration/Users/CreateUpdate';

import OrganizationRolesList from './Pages/Administration/Roles/List';
import OrganizationRolesCreateUpdate from './Pages/Administration/Roles/CreateUpdate';

import OperatorCard from './Pages/ControlCards/Operator/Card';

import ViewCard from './Pages/ControlCards/Card/View'

import { AuthContextProvider } from "./Context/AuthContext";

import 'react-quill/dist/quill.snow.css';
import './Assets/styles/styles.css';
import { SWRConfig } from 'swr'
import { swrDataFetcher } from "./Core/DataAccess";
import { SnackbarProvider } from "notistack";
import Grow from '@mui/material/Grow';
import OperatorDashboard from "./Pages/ControlCards/Operator/OperatorDashboard";
import ApprovalDashboard from "./Pages/ControlCards/Approval/ApprovalDashboard";
import Approve from "./Pages/ControlCards/Card/Approve";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import datePlLocale from "date-fns/locale/pl";

import SearchIndexPage from "./Pages/Administration/SearchIndex/Index";
import PdfExport from "./Pages/PdfExport/Index";

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}



function App() {

  const swrConfig = {
    fetcher : swrDataFetcher,
    revalidateOnFocus : false
  };

  return (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={datePlLocale}>
          <SWRConfig value={swrConfig}>
            <AuthContextProvider>
              <UIContextProvider>
                <SnackbarProvider
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    // @ts-ignore
                    TransitionComponent={Grow}
                    autoHideDuration={5000}
                    variant={"info"}
                >
                  <MainLayoutProvider>
                    <Router>
                      <MainRoute isAnonymousRoute={true} path="/auth/login" component={<Login />} />

                      <MainRoute isAnonymousRoute={true} path="/pdf-export" component={<PdfExport />} />



                      <MainRoute path="/" component={<Dash />} />

                      <MainRoute  path="/control-cards/operator" component={<OperatorDashboard />} />
                      <MainRoute  path="/control-cards/approval" component={<ApprovalDashboard />} />

                      <MainRoute  path="/control-cards/list" component={<ControlCardFilledList />} />
                      <MainRoute  path="/control-cards/:id/operator" component={<OperatorCard />} />
                      <MainRoute  path="/control-cards/:id/view" component={<ViewCard />} />
                      <MainRoute  path="/control-cards/:id/approve" component={<Approve />} />

                      <MainRoute  path="/control-cards/templates" component={<ControlCardTemplatesList />} />
                      <MainRoute  path="/control-cards/templates/create" component={<ControlCardTemplatesCreateUpdate mode="create" />} />
                      <MainRoute  path="/control-cards/templates/:id" component={<ControlCardTemplatesCreateUpdate  mode="update" />}  />

                      <MainRoute  path="/control-cards/groups" component={<ControlCardGroupsList />} />
                      <MainRoute  path="/control-cards/groups/create" component={<ControlCardGroupsCreateUpdate mode="create" />} />
                      <MainRoute  path="/control-cards/groups/:id" component={<ControlCardGroupsCreateUpdate  mode="update" />}  />

                      <MainRoute  path="/control-cards/types" component={<ControlCardTypesList />} />
                      <MainRoute  path="/control-cards/types/create" component={<ControlCardTypesCreateUpdate mode="create" />} />
                      <MainRoute  path="/control-cards/types/:id" component={<ControlCardTypesCreateUpdate  mode="update" />}  />

                      <MainRoute  path="/data-warehouse/form-types" component={<DataWarehouseFormTypesList />} />
                      <MainRoute  path="/data-warehouse/form-types/create" component={<DataWarehouseFormTypesCreateUpdate mode="create" />} />
                      <MainRoute  path="/data-warehouse/form-types/:id" component={<DataWarehouseFormTypesCreateUpdate mode="update" />} />

                      <MainRoute  path="/data-warehouse/data-types" component={<DataTypesList />} />
                      <MainRoute  path="/data-warehouse/data-types/create" component={<DataTypeCreateUpdate mode="create" />} />
                      <MainRoute  path="/data-warehouse/data-types/:id" component={<DataTypeCreateUpdate mode="update" />} />

                      <MainRoute  path="/data-warehouse/unit-types" component={<UnitTypesList />} />
                      <MainRoute  path="/data-warehouse/unit-types/create" component={<UnitTypeCreateUpdate mode="create" />} />
                      <MainRoute  path="/data-warehouse/unit-types/:id" component={<UnitTypeCreateUpdate mode="update" />} />

                      <MainRoute path={"/administration/ou"} component={<OrganizationOUManage />} />

                      <MainRoute  path="/administration/users" component={<OrganizationUsersList />} />
                      <MainRoute  path="/administration/users/create" component={<OrganizationUsersCreateUpdate mode="create" />} />
                      <MainRoute  path="/administration/users/:id" component={<OrganizationUsersCreateUpdate mode="update" />} />

                      <MainRoute  path="/administration/roles" component={<OrganizationRolesList />} />
                      <MainRoute  path="/administration/roles/create" component={<OrganizationRolesCreateUpdate mode="create" />} />
                      <MainRoute  path="/administration/roles/:id" component={<OrganizationRolesCreateUpdate mode="update" />} />

                      <MainRoute  path="/administration/search-index" component={<SearchIndexPage />} />

                    </Router>
                  </MainLayoutProvider>
                </SnackbarProvider>
              </UIContextProvider>
            </AuthContextProvider>
          </SWRConfig>
        </LocalizationProvider>
      </ThemeProvider>
  );
}

export default App;
