import _ from "lodash";

const withBaseApiUrl = (url : string) => {
  return `/api/app/${_.trimStart(url, "/")}`;
};

const apiEndpoints = {
  administration : {
    user : {
      withBaseUrl : (url ?: string) => {
        url = url !== undefined ? "/" + _.trimStart(url, "/") : "";
        return `/api/identity/users${url}`;
      },

      base : () => {
        return apiEndpoints.administration.user.withBaseUrl();
      },

      create : () => {
        return apiEndpoints.administration.user.withBaseUrl();
      },

      update : (id : string) => {
        return apiEndpoints.administration.user.withBaseUrl(id);
      },

      delete : (id : string) => {
        return apiEndpoints.administration.user.withBaseUrl(id);
      },
    },
  },

  dataWarehouse : {
    dataTypes : {
      withBaseUrl : (url ?: string) => {
        url = url !== undefined ? "/" + _.trimStart(url, "/") : "";
        return withBaseApiUrl(`/data-types${url}`)
      },

      base : () => {
        return apiEndpoints.dataWarehouse.dataTypes.withBaseUrl();
      },

      list : () => {
        return apiEndpoints.dataWarehouse.dataTypes.withBaseUrl();
      },

      get : (id : string) => {
        return apiEndpoints.dataWarehouse.dataTypes.withBaseUrl(id);
      },

      delete : (id : string) => {
        return apiEndpoints.dataWarehouse.dataTypes.withBaseUrl(id);
      },

      update : (id : string) => {
        return apiEndpoints.dataWarehouse.dataTypes.withBaseUrl(id);
      },

      create : () => {
        return apiEndpoints.dataWarehouse.dataTypes.withBaseUrl();
      }
    },

    unitTypes : {
      withBaseUrl : (url ?: string) => {
        url = url !== undefined ? "/" + _.trimStart(url, "/") : "";
        return withBaseApiUrl(`/unit-type${url}`)
      },

      base : () => {
        return apiEndpoints.dataWarehouse.unitTypes.withBaseUrl();
      },

      list : () => {
        return apiEndpoints.dataWarehouse.unitTypes.withBaseUrl();
      },

      get : (id : string) => {
        return apiEndpoints.dataWarehouse.unitTypes.withBaseUrl(id);
      },

      delete : (id : string) => {
        return apiEndpoints.dataWarehouse.unitTypes.withBaseUrl(id);
      },

      update : (id : string) => {
        return apiEndpoints.dataWarehouse.unitTypes.withBaseUrl(id);
      },

      create : () => {
        return apiEndpoints.dataWarehouse.unitTypes.withBaseUrl();
      }
    },

    formTemplates : {
      withBaseUrl : (url ?: string) => {
        url = url !== undefined ? "/" + _.trimStart(url, "/") : "";
        return withBaseApiUrl(`/form-templates${url}`)
      },

      base : () => {
        return apiEndpoints.dataWarehouse.formTemplates.withBaseUrl();
      },

      list : () => {
        return apiEndpoints.dataWarehouse.formTemplates.withBaseUrl();
      },

      get : (id : string) => {
        return apiEndpoints.dataWarehouse.formTemplates.withBaseUrl(id);
      },

      delete : (id : string) => {
        return apiEndpoints.dataWarehouse.formTemplates.withBaseUrl(id);
      },

      update : (id : string) => {
        return apiEndpoints.dataWarehouse.formTemplates.withBaseUrl(id);
      },

      create : () => {
        return apiEndpoints.dataWarehouse.formTemplates.withBaseUrl();
      }
    },
  },

  controlCard : {
    type : {
      withBaseUrl : (url ?: string) => {
        url = url !== undefined ? "/" + _.trimStart(url, "/") : "";
        return withBaseApiUrl(`/control-card-types${url}`)
      },

      base : () => {
        return apiEndpoints.controlCard.type.withBaseUrl();
      },

      create : () => {
        return apiEndpoints.controlCard.type.withBaseUrl();
      },

      update : (id : string) => {
        return apiEndpoints.controlCard.type.withBaseUrl(id);
      },

      delete : (id : string) => {
        return apiEndpoints.controlCard.type.withBaseUrl(id);
      },
    },

    group : {
      withBaseUrl : (url ?: string) => {
        url = url !== undefined ? "/" + _.trimStart(url, "/") : "";
        return withBaseApiUrl(`/control-card-groups${url}`)
      },

      base : () => {
        return apiEndpoints.controlCard.group.withBaseUrl();
      },

      create : () => {
        return apiEndpoints.controlCard.group.withBaseUrl();
      },

      update : (id : string) => {
        return apiEndpoints.controlCard.group.withBaseUrl(id);
      },

      delete : (id : string) => {
        return apiEndpoints.controlCard.group.withBaseUrl(id);
      },
    }
  }
};


export default apiEndpoints;