import CheckboxField from "../../../../Core/Forms/Fields/CheckboxField";
import { styled } from '@mui/material/styles';
import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import _ from "lodash";
import { Check, CheckCircle, X } from "react-feather";
import { Checkbox } from "@mui/material";

const ReadOnlyDiv = styled("div")({
    display : "flex",
    borderRadius : 4,
    fontSize : 16,
    border : "1px solid rgba(0, 0, 0, 0.1)",
    background : "rgba(0, 0, 0, 0.01)",
    padding : "10.5px 14px",
    minHeight : 40,
    alignItems : "center"
});


const normalizeValue = (value : any) => {
  if(value === undefined || value === null)
    return false;

  const normalizedStringValue = _.isString(value) ? (value as string)?.toLowerCase().trim() : "";

  if(normalizedStringValue === "true" || normalizedStringValue === "1" || value === true || value === 1) {
    return true;
  }

  if(normalizedStringValue === "false" || normalizedStringValue === "0" || value === false || value === 0) {
    return false;
  }

  return value;
}

const CheckboxFormControl = (props : any) => {
  const isReadOnly = props?.isReadOnly || false;

  const getFormValue = () => {
    return props.form.getValues(props.name) || "";
  }

  if(isReadOnly) {
    return <ReadOnlyDiv>{normalizeValue(getFormValue()) ? <Check size={20} /> : <X size={20} /> }</ReadOnlyDiv>;
  }

  return <CheckboxField name={props.name} label="Tak" />;
};

export default CheckboxFormControl;