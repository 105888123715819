import {
  Button as MuiButton,
  Divider,
  Paper,
  Typography,
  Grid, AccordionSummary, AccordionDetails, FormControl, InputLabel, Select, MenuItem, TextField as MuiTextField,
} from "@mui/material";
import { Link, navigate, useParams } from "@reach/router";
import React, { useCallback, useEffect, useState } from "react";
import {
  Plus as PlusIcon,
  ChevronRight as ChevronRightIcon,
  Save as SaveIcon,
  Trash as TrashIcon,
  Trash as DeleteIcon
} from "react-feather";
import Breadcrumbs from "../../../Components/Breadcrumbs";
import Button from "../../../Components/Button";
import { Controller, useForm, useFormContext } from "react-hook-form";
import _ from "lodash";
import api from "../../../Api";
import { mergeWithDefaultData } from "../../../Common/Utils";
import { useSnackbar } from "notistack";
import { useGetApiData } from "../../../OmniData/DataAccess";
import apiEndpoints from "../../../OmniData/DataAccess/ApiEndpoints";
import TextField from "../../../Core/Forms/Fields/TextField";
import ExpandableList from "../../../Core/ExpandableList/ExpandableList";
import ReactQuill from "react-quill";

const generateElementData = () => {
  return {
    key : `el-${Math.random()}`,
    name: 'element',
    "new" : true,
    dataTypeSettings : {
      validatorSettings : {
        type : 0,
        validValues : []
      }
    }
  };
};


const FormElement = (props: any) => {
  const availableDataTypesData = useGetApiData(apiEndpoints.dataWarehouse.dataTypes.list(), {
    maxResultCount : 1000
  });
  let availableDataTypes : any[] = [];

  if(availableDataTypesData?.data?.items) {
    availableDataTypes = availableDataTypesData.data.items.map((item : any) => {
      return {
        value : item.id,
        label : item.displayName
      }
    });
  }


  const getFieldName = (field : string) => {
    return `${props.formKey}.${field}`;
  };

  const setFormValue = (key: string, value: any) => {
    props.setFormValue(`${props.formKey}.${key}`, value);
  };

  const getFormValue = (key: string) => {
    return props.getFormValue(`${props.formKey}.${key}`);
  };

  const [wysiwygValue, setWysiwygValue] = useState<string>(getFormValue("description"));

  const refreshName = useCallback(_.debounce((v) => {
    props?.onNameChange(v);
  }, 100), []);

  const quillModules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      ['image'],
      ['clean']
    ],
  };

  const currentDataTypeId = props.form.watch(getFieldName("dataTypeId"));
  const currentDataType = availableDataTypesData?.data?.items?.find((item : any) => item.id == currentDataTypeId);
  const showWysiwygEditor = currentDataType?.controlType == "contentDisplay";

  return (
      <Grid container spacing={1}>
        <Grid container item spacing={3}>
          <Grid item xs={4} lg={5}>
            <MuiTextField
                label="Nazwa"
                variant="outlined"
                size="small"
                fullWidth
                defaultValue={getFormValue('name')}
                onChange={(e) => {
                  setFormValue('name', e.target.value);
                  refreshName(e.target.value);
                }}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={3}>
          <Grid item lg={5}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel>Typ danych</InputLabel>
              <Controller
                  render={
                    ({ field }) =>
                        <Select
                            labelId="demo-simple-select-label"
                            label="Typ danych"
                            {...field}
                            value={field.value || ""}
                        >
                          {(availableDataTypes || []).map((dataType : any) => (
                              <MenuItem key={dataType.value} value={dataType.value}>{dataType.label}</MenuItem>
                          ))}
                        </Select>
                  }
                  control={props.form.control}
                  name={getFieldName("dataTypeId")}
              />

            </FormControl>
          </Grid>
        </Grid>

        <Grid container item spacing={3}>
          <Grid item xs={4} lg={5}>
            <MuiTextField
                label="Grupa filtrów"
                variant="outlined"
                size="small"
                fullWidth
                defaultValue={getFormValue('filterGroup')}
                onChange={(e) => {
                  setFormValue('filterGroup', e.target.value);
                }}
            />
          </Grid>
        </Grid>

        <Grid container item spacing={3}>
          <Grid item xs={4} lg={5}>
            {!showWysiwygEditor && (
                <MuiTextField
                    label="Opis"
                    variant="outlined"
                    size="small"
                    fullWidth
                    multiline
                    rows={2}
                    defaultValue={getFormValue('description')}
                    onChange={(e) => {
                      setFormValue('description', e.target.value);
                    }}
                />
            )}

            {showWysiwygEditor && (
                <ReactQuill
                    theme="snow"
                    value={wysiwygValue}
                    onChange={(value) => {
                      setWysiwygValue(value);
                      setFormValue('description', value);
                    }}
                    modules={quillModules}
                />
            )}
          </Grid>
        </Grid>



        {/*<Grid container item spacing={3} style={{ display : "none" }}>*/}
        {/*  <Grid item xs={4} lg={12}>*/}
        {/*    <Typography>Ustawienia <small>(nadpisują ustawienia wybranego typu danych)</small></Typography>*/}
        {/*    <FormProvider {...props.form}>*/}
        {/*      <DataTypeAdditionalSettings name={getFieldName("dataTypeSettings")} />*/}
        {/*    </FormProvider>*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}

      </Grid>
  );
}

const MainForm = (props: any) => {
  const [inProgress, setInProgress] = useState(false);
  const form = useForm();
  const [formData, setFormData] = useState<any>(props.currentData);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    let data = mergeWithDefaultData({
          name : null,
          elements : [],
        },
        props.currentData
    );

    form.reset(data);
    setFormData({... data});

  }, [props.currentData]);

  const onSubmit = async (data: any) => {
    setInProgress(true);

    try {
      if(props.currentData.id) {
        await api.app.dataWarehouse.formTypes.update(props.currentData.id, data);
        enqueueSnackbar('Zmiany zapisane');
      } else {
        await api.app.dataWarehouse.formTypes.create(data);
        await navigate("/data-warehouse/form-types")
      }

    } catch (e : any) {
      var error = _.get(e, 'response.data.error.details', e.message);
      alert(error);
      console.error(e);
    } finally {
      setInProgress(false);
    }
  };

  const getFormValue = (key: string) => {
    return form.getValues(key) ?? _.get(props.currentData, key);
  };

  const setFormValue = (key: string, value: any) => {
    if (value == 'append') {

    } else if (value == 'remove') {
      key = key.replace('.root', '');
      _.set(formData, key, undefined);

      form.unregister(key);

      formData.elements = _.filter(formData && formData.elements ? formData.elements : []);
      setFormData({ ...formData });
      form.reset(formData)
    } else {
      form.setValue(key, value);
    }
  };

  const getNames = () => {
    var obj : any = {};
    (formData?.elements || []).map((el : any, i : any) => {
      obj[i] = el.name;
    });
    return obj;
  };
  const [names, setNames] = useState<any>(getNames());

  return (
      <form noValidate onSubmit={form.handleSubmit(onSubmit)} >

        <Typography variant="h6" style={{ fontWeight: 400 }}>Dane podstawowe</Typography>

        <Paper style={{ padding: 16 }} elevation={1}>
          <Grid container spacing={1}>
            <Grid container item spacing={3}>
              <Grid item lg={5}>
                <TextField
                  label="Nazwa"
                  name="name"
                  form={form}
                />
              </Grid>
            </Grid>
          </Grid>

        </Paper>

        <Typography variant="h6" style={{ fontWeight: 400, marginTop : 16 }}>Elementy</Typography>

        <ExpandableList
            onItemDelete={(i) => {
              setFormValue(`elements[${i}].root`, 'remove');
            }}
            items={(formData.elements || [])}
            renderTitle={(item : any, index : any) => names?.[index] ?? item?.name ?? ""}
            renderItem={(item : any, index : any) => {
              return <FormElement
                data={item}
                formKey={`elements[${index}]`}
                getFormValue={getFormValue}
                setFormValue={setFormValue}
                form={form}
                onNameChange={(name : string) => {
                  setNames({
                    ...names,
                    [index] : name
                  });
                }}
              />
            }}
        />

        <MuiButton
            variant="contained"
            color="primary"
            startIcon={<PlusIcon>send</PlusIcon>}
            type="button"
            size="small"
            onClick={(e) => {
              e.preventDefault();

              var el = generateElementData();

              formData.elements = formData.elements || [];
              formData.elements.push(el);

              var values = {...form.getValues()};

              if(!values.elements) {
                values.elements = [];
              }

              values.elements.push(el);

              form.reset(values);

              setFormData({ ...formData });
            }}
        >
          Nowy element
        </MuiButton>

        <Divider style={{ marginTop: 24, marginBottom: 8 }} />

        <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon size={22} />}
            type="submit"
            inProgress={inProgress}
        >
          Zapisz
        </Button>

      </form>
  );
};

export default function CreateUpdate(props: any) {
  const params = useParams();
  const [currentData, setCurrentData] = useState<any>({});

  useEffect(() => {
    if(props.mode == "update" && params.id) {
      (async () => {
        var data = await api.app.dataWarehouse.formTypes.get(params.id);
        setCurrentData(data.data);
      })();
    }
  }, []);

  return (
      <>
        <Breadcrumbs title={props.mode == "update" ? "Aktualizacja" : "Tworzenie"} />

        <MainForm currentData={currentData} />
      </>
  );
}