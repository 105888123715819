import { Button, Checkbox, FormControlLabel, FormGroup, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DatePicker, DateTimePicker } from "@mui/lab";

export interface NumberFilterValue
{
  values : any[];
  isRange : boolean;
}

export interface NumberFilterProps
{
  label : string;
  id : any;
  currentValue ?: NumberFilterValue;
  onChange : (newValue ?: NumberFilterValue) => void;
}

const normalizeValue = (value : any) => {
  return value === undefined || value == "" || value?.trim() == "" ? undefined : value;
}

const NumberFilter = (props : NumberFilterProps) => {
  const [value1, setValue1] = useState<any>(normalizeValue(props.currentValue?.values?.[0]));
  const [value2, setValue2] = useState<any>(normalizeValue(props.currentValue?.values?.[1]));
  const [isRange, setIsRange] = useState<boolean>(props.currentValue?.isRange ?? false);

  const firstLabel = "Wartość" + (isRange ? " - od" : "");
  const secondLabel = "Wartość" + (isRange ? " - do" : "");

  const submit = () => {
    let values : any[] = [];

    if(isRange) {
      values = [normalizeValue(value1), normalizeValue(value2)]
    } else {
      values = [normalizeValue(value1)];
    }

    const valuesEmpty = values.every(v => !v);

    props.onChange(!valuesEmpty ? {
      values : values,
      isRange : isRange,
    } : undefined);
  };

  return (
      <form onSubmit={(e) => { e.preventDefault(); submit(); }}>
        <div>
          <FormControlLabel
              control={
                <Checkbox
                    checked={isRange}
                    onChange={(ev, checked) => setIsRange(checked)}
                />
              }
              label="Zakres"
          />
        </div>

        <div>
          <TextField
              size="small"
              label={firstLabel}
              key={props.id}
              value={normalizeValue(value1)}
              onChange={(e) => setValue1(e.currentTarget.value)}
          />
        </div>

        {isRange && (
            <div style={{ marginTop : 8 }}>
              <TextField
                  size="small"
                  label={secondLabel}
                  key={props.id}
                  value={normalizeValue(value2)}
                  onChange={(e) => setValue2(e.currentTarget.value)}
              />
            </div>
        )}

        <div>
          <Button sx={{ marginTop : "8px" }} onClick={submit} disableElevation fullWidth variant="contained">Filtruj</Button>
        </div>
      </form>
  )
}

export default NumberFilter;