import { useUIContext } from "../../Context/UIContext";
import PanelLayout from "./PanelLayout";
import React from "react";

export default function MainLayoutProvider(props: React.PropsWithChildren<any>) {
  const { state } = useUIContext();

  switch (state.layout) {
    case "panel":
      return (<PanelLayout>{props.children}</PanelLayout>);

    default:
      return (<>{props.children}</>);
  }
};
