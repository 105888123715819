import { AppBar, Avatar, IconButton, MenuItem, Toolbar,  Menu, useTheme } from "@mui/material";
import { styled } from '@mui/material/styles';
import React, {  useEffect, useState } from "react";
import { Menu as MenuIcon, Share2 } from "react-feather";
import { useUIContext } from "../../Context/UIContext";
import ResponsiveDrawer from "../ResponsiveDrawer";
import {useAuthContext} from "../../Context/AuthContext";
import {QRCodeSVG} from 'qrcode.react';
const PREFIX = 'PanelLayout';

const classes = {
  Panel__Content: `${PREFIX}-Panel__Content`,
  Panel__AppBar: `${PREFIX}-Panel__AppBar`,
  Avatar: `${PREFIX}-Avatar`,
  AvatarButton: `${PREFIX}-AvatarButton`,
  Panel_PageContent: `${PREFIX}-Panel_PageContent`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.Panel__Content}`]: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),

    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${260}px)`,
      marginLeft: 260,
    },

    '&.full': {
      width: `100%`,
      marginLeft: 0,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }
  },

  [`& .${classes.Panel__AppBar}`]: {
    background: 'rgb(255, 255, 255)',
    color: 'rgb(158, 158, 158)'
  },

  [`& .${classes.Avatar}`]: {
    width: 30,
    height: 30,
    fontSize: 14
  },

  [`& .${classes.AvatarButton}`]: {
    marginLeft: "auto",
    padding: theme.typography.pxToRem(7),
  },

  [`& .${classes.Panel_PageContent}`]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),

  }
}));

const Drawer = React.memo(ResponsiveDrawer);

const AppBarMemo = React.memo((props) => {

  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
  const [shareMenuAnchor, setShareMenuAnchor] = React.useState<null | HTMLElement>(null);


  const { state: UIState, actions: UIActions } = useUIContext();
  const { state : authState, actions : authActions } = useAuthContext();

  const logout = () => {
    authActions.logout();
  }

  const getAvatar = () => {
    let usernameParts = (authState?.currentUser?.userName || "").split(" ") ?? [];

    if(usernameParts.length == 1) {
      return usernameParts[0].substr(0,1);
    } else if (usernameParts.length >= 2) {
      return usernameParts[0].substr(0,1) + usernameParts[1].substr(0,1);
    } else {
      return "-";
    }
  }

  return (
    <AppBar position="sticky" elevation={0} className={classes.Panel__AppBar}>
      <Toolbar variant="dense">
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={() => UIActions.toggleDrawer()}
          size="large">
          <MenuIcon size="20" />
        </IconButton>

        <div style={{ marginLeft : "auto" }}>
          <IconButton
            onClick={(e) => { setShareMenuAnchor(e.currentTarget); }}
            color="inherit"
            size="large"
            sx={{ marginRight : "8px" }}
          >
            <Share2 size="20" />
          </IconButton>

          <IconButton
              onClick={(e) => { setMenuAnchor(e.currentTarget); }}
              color="inherit"
              className={classes.AvatarButton}
              size="large">
            <Avatar className={classes.Avatar}>{getAvatar()}</Avatar>
          </IconButton>
        </div>

      </Toolbar>

      <Menu
        anchorEl={shareMenuAnchor}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        marginThreshold={7}
        keepMounted
        open={!!shareMenuAnchor}
        onClose={() => { setShareMenuAnchor(null); }}
      >
        <div style={{ padding : "6px 12px 0px" }}>
          <QRCodeSVG value={window.location.href} size={180} />
        </div>
      </Menu>

      <Menu
        anchorEl={menuAnchor}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        marginThreshold={7}
        id="accountMenu"
        keepMounted
        open={!!menuAnchor}
        onClose={() => { setMenuAnchor(null); }}
      >
        <MenuItem onClick={logout}>Wyloguj</MenuItem>
      </Menu>


    </AppBar>
  );
});

const PanelLayout: React.FC<any> = (props: any) => {
  const { state: UIState, actions: UIActions } = useUIContext();

  const theme = useTheme();
  const [isMobileMode, setMobileMode] = useState<boolean>(false);


  useEffect(() => {
    setMobileMode(window.innerWidth <= theme.breakpoints.values.lg);
  }, []);

  //@ts-ignore
  const InnerPageContentWrapper = props?.children?.WrapperComponent ? props.children.WrapperComponent : ({ children }) => {
    return <div className={classes.Panel_PageContent}>{children}</div>;
  };

  return (
    <Root className="Panel">
      <div className="Panel__Drawer">
        <Drawer open={UIState.drawerOpened} mobile={isMobileMode} />
      </div>

      <div className={`${classes.Panel__Content} ${!UIState.drawerOpened ? 'full' : ''}`}>
        <AppBarMemo />

        <div className="Panel__PageContentContainer">
          <InnerPageContentWrapper>{props.children}</InnerPageContentWrapper>
        </div>
      </div>
    </Root>
  );
};

export default PanelLayout;