import { Button, Checkbox, FormControlLabel, FormGroup, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DatePicker, DateTimePicker } from "@mui/lab";

export interface DateFilterValue
{
  values : any[];
  isRange : boolean;
  isDateTime : boolean;
}

export interface DateFilterProps
{
  label : string;
  id : any;
  currentValue ?: DateFilterValue;
  onChange : (newValue ?: DateFilterValue) => void;
}

const normalizeDateValue = (value : any) => {
  return value === undefined ? null : value;
}

const setTime = (value : Date, isDateTime : boolean, isStartDate : boolean) => {
  if(!value) {
    return null;
  }

  if(isDateTime) {
    return value;
  }

  if(!isDateTime && isStartDate) {
    return new Date(value.getUTCFullYear(), value.getUTCMonth(), value.getUTCDate(), 0, 0, 0);
  }

  if(!isDateTime && !isStartDate) {
    return new Date(value.getUTCFullYear(), value.getUTCMonth(), value.getUTCDate(), 23, 59, 59);
  }
}

const dateFormat = "dd/MM/yyyy";
const dateTimeFormat = "dd/MM/yyyy HH:mm";

const DateFilter = (props : DateFilterProps) => {
  const [value1, setValue1] = useState<any>(normalizeDateValue(props.currentValue?.values?.[0]));
  const [value2, setValue2] = useState<any>(normalizeDateValue(props.currentValue?.values?.[1]));

  const [isRange, setIsRange] = useState<boolean>(props.currentValue?.isRange ?? false);
  const [isDateTime, setIsDateTime] = useState<boolean>(props.currentValue?.isDateTime ?? false);

  const firstLabel = (isDateTime ? "Data i czas" : "Data") + (isRange ? " - od" : "");
  const secondLabel = (isDateTime ? "Data i czas" : "Data") + (isRange ? " - do" : "");
  const format = isDateTime ? dateTimeFormat : dateFormat;
  const Picker = isDateTime ? DateTimePicker : DatePicker;

  const submit = () => {
    let values : any[] = [];

    if(isRange) {
      values = [setTime(value1, isDateTime, true), setTime(value2, isDateTime, false)]
    } else if (!isRange && !isDateTime) {
      values = [setTime(value1, false, true), setTime(value1, false, false)]
    } else if (!isRange) {
      values = [setTime(value1, isDateTime, true)];
    }

    const valuesEmpty = values.every(v => !v);

    props.onChange(!valuesEmpty ? {
      values : values,
      isRange : isRange,
      isDateTime : isDateTime
    } : undefined);
  };

  return (
      <form onSubmit={(e) => { e.preventDefault(); submit(); }}>
        <div>
          <FormControlLabel
              control={
                <Checkbox
                    checked={isRange}
                    onChange={(ev, checked) => setIsRange(checked)}
                />
              }
              label="Zakres"
          />
          <FormControlLabel
              control={
                <Checkbox
                    checked={isDateTime}
                    onChange={(ev, checked) => setIsDateTime(checked)}
                />
              }
              label="Czas"
          />
        </div>

        <div>
          <Picker
              inputFormat={format}
              allowSameDateSelection
              clearable
              value={normalizeDateValue(value1)}
              label={firstLabel}
              onChange={(date) => {
                try {
                  setValue1(normalizeDateValue(date));
                } catch {
                  setValue1(null);
                }

                // field.onChange(date.toISOString());
              }}
              renderInput={(params) => {
                return <TextField {...params} fullWidth size="small" />;
              }}
          />
        </div>

        {isRange && (
            <div style={{ marginTop : 8 }}>
              <Picker
                  inputFormat={format}
                  allowSameDateSelection
                  clearable
                  value={normalizeDateValue(value2)}
                  label={secondLabel}
                  onChange={(date) => {
                    try {
                      setValue2(normalizeDateValue(date));
                    } catch {
                      setValue2(null);
                    }

                    // field.onChange(date.toISOString());
                  }}
                  renderInput={(params) => {
                    return <TextField {...params} fullWidth size="small" />;
                  }}
              />
            </div>
        )}

        <div>
          <Button sx={{ marginTop : "8px" }} onClick={submit} disableElevation fullWidth variant="contained">Filtruj</Button>
        </div>
      </form>
  )
}

export default DateFilter;