import { Button as MuiButton, ButtonProps as MuiButtonProps, CircularProgress } from "@mui/material";

type ButtonProps = {
  inProgress ?: boolean;
} & MuiButtonProps;

export default function Button({ inProgress = false, ...props }: ButtonProps) {
  return (
    <MuiButton {...props} disabled={inProgress}>
      <span>{props.children}</span>
      { inProgress && (<div style={{ position : 'absolute', display : "flex" }}><CircularProgress color="secondary" size={22} /></div>) }
    </MuiButton>
  );
}