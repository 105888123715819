import React, { PropsWithChildren } from 'react';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { MoreVertical, Trash as TrashIcon } from "react-feather";
import { IconButton } from "@mui/material";

export default function RowActionsMenu(props : PropsWithChildren<any>) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event : any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
      <div>
        <IconButton
          aria-haspopup="true"
          onClick={handleClick}
          sx={{
            marginTop : '-8px',
            marginBottom : '-8px',
            padding : '10px'
          }}
        >
          <MoreVertical size={22} />
        </IconButton>
        <Menu
          elevation={2}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={(e) => {
            setAnchorEl(null);
          }}
        >
          {props.children}
        </Menu>
      </div>
  );
}