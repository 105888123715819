import { Checkbox, Divider, FormControlLabel, FormGroup, Grid, Paper, Typography } from "@mui/material";
import { navigate, useParams } from "@reach/router";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../Components/Breadcrumbs";
import { Controller, useForm } from "react-hook-form";
import { useGetApiData, useGetSingleUser } from "../../../OmniData/DataAccess";
import Form from "../../../Core/Forms/Form";
import apiEndpoints from "../../../OmniData/DataAccess/ApiEndpoints";
import TextField from "../../../Core/Forms/Fields/TextField";

const MainForm = (props: any) => {
  const form = useForm({
    mode : "onSubmit",
    reValidateMode : "onSubmit",
    resolver : (values, context, options) => {
      return {
        values : values,
        errors : {}
      }
    }
  });

  const actionUrl = props?.currentData?.id
      ? apiEndpoints.administration.user.update(props.currentData.id)
      : apiEndpoints.administration.user.create();

  const { data : availableRoles } = useGetApiData("/api/identity/roles/all");

  useEffect(() => {
    form.reset(props.currentData);
  }, [props.currentData]);

  const hasAssignedRole = (roleName : string) => {
    const r : any[] = form.getValues("roleNames");

    return  (r && r.includes(roleName)) || false;
  }

  const onAfterSubmitRequestConfig = (requestConfig : any, formData : any) => {
    requestConfig.requestData.username = formData.email;
    return requestConfig;
  }

  return (
      <Form action={actionUrl} method={props?.currentData?.id ? "PUT" : "POST" } form={form} onAfterSubmitRequestConfig={onAfterSubmitRequestConfig}>
        {(formProps : any) => (
            <>
              <Typography variant="h6" style={{ fontWeight: 400 }}>Dane podstawowe</Typography>

              <Paper style={{ padding: 16 }} elevation={1}>
                <Grid container spacing={1}>
                  <Grid container item spacing={3}>
                    <Grid item xs={3}>
                      <TextField
                        name="name"
                        label="Imię"
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                          name="surname"
                          label="Nazwisko"
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        name="email"
                        label="Adres e-mail"
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <TextField
                        name="password"
                        type="password"
                        label={props?.currentData?.id ? "Zmiana hasła" : "Hasło"}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>

              <Typography variant="h6" style={{ fontWeight: 400, marginTop : 10 }}>Role</Typography>
              <Paper style={{ padding: 16 }} elevation={1}>
                <Grid container spacing={1}>
                  <Grid container item spacing={3}>
                    <Grid item xs={3}>
                      <FormGroup>
                        {(availableRoles?.items || []).map((roleData : any) =>
                            <Controller
                                render={
                                  ({ field }) =>
                                      <FormControlLabel
                                          control={
                                            <Checkbox
                                                color="primary"
                                                checked={hasAssignedRole(roleData.name)}
                                                onChange={(e) => {
                                                  const assigned = hasAssignedRole(roleData.name);
                                                  var roles = form.getValues("roleNames") || [];
                                                  roles = assigned ? roles.filter((x : any) => x != roleData.name) : [...roles, roleData.name];

                                                  form.setValue("roleNames", roles);
                                                }}
                                            />
                                          }
                                          label={roleData?.extraProperties?.DisplayName || roleData.name}
                                      />
                                }
                                control={form.control}
                                name="roleNames"
                            />

                        )}
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>

              <Divider style={{ marginTop: 24, marginBottom: 8 }} />
            </>
        )}
      </Form>
  )
};


const UpdateView = ({ id } : any) => {
  const { data } = useGetSingleUser(id);
  const { data : roles } = useGetApiData(`/api/identity/users/${id}/roles`);
  const [currentData, setCurrentData] = useState();

  useEffect(() => {
    if(data && roles) {
      data.roleNames = roles?.items.map((roleData : any) => roleData.name) || [];
      setCurrentData({...data});
    }
  }, [data, roles]);

  return (
      <>
        <Breadcrumbs title={"Aktualizacja"}/>
        <MainForm currentData={currentData}/>
      </>
  );
};


const CreateView = ({ id } : any) => {
  return (
      <>
        <Breadcrumbs title={"Tworzenie"}/>
        <MainForm currentData={null}/>
      </>
  );
};

export default function CreateUpdate(props: any) {
  const params = useParams();

  return props.mode == "update" && params.id
      ? <UpdateView id={params.id} />
      : <CreateView />
}