import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Card, CardContent, Divider, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Link, navigate, RouteComponentProps } from "@reach/router";
import DataGrid from "../../../Components/DataGrid/DataGrid";
import Breadcrumbs from "../../../Components/Breadcrumbs";
import _ from "lodash";
import api from "../../../Api";
import { Edit, Key as KeyIcon, Plus as PlusIcon, Trash as TrashIcon } from "react-feather";
import {
  useGetApiData,
  useGetDataTypesAsList,
  useGetUnitTypesAsList,
  useGetUsersList
} from "../../../OmniData/DataAccess";
import SearchInput from "../../../Components/SearchInput";
import RowActionsMenu from "../../../Core/ListDataViewer/RowActionsMenu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import apiEndpoints from "../../../OmniData/DataAccess/ApiEndpoints";
import ListDataViewer from "../../../Core/ListDataViewer/ListDataViewer";

const NavigationLink = React.forwardRef((props: any, ref) => {
    return (
        <Link to={props.href} ref={ref} {...props}>{props.children}</Link>
    );
});

export function List(props: RouteComponentProps) {
  return (
        <>
            <Breadcrumbs title="Użytkownicy" />

            <Button
                component={NavigationLink}
                variant="contained"
                color="primary"
                startIcon={<PlusIcon>send</PlusIcon>}
                href="/administration/users/create"
            >
                Dodaj
            </Button>

          <ListDataViewer
              simpleSearchEnabled
              endpoints={{
                base : `/api/identity/users`
              }}
              paths={{
                base : "/administration/users"
              }}
              columns={[
                {
                  Header: 'Imię',
                  accessor: 'name',
                },

                {
                  Header: 'Nazwisko',
                  accessor: 'surname',
                },

                {
                  Header: 'E-mail',
                  accessor: 'email',
                },

                {
                  Header : 'Data modyfikacji',
                  accessor: 'creationTime',
                  type : 'datetime'
                },

                {
                  Header : 'Data utworzenia',
                  accessor: 'lastModificationTime',
                  type : 'datetime'
                },
              ]}
          />
        </>
    );
}

export default React.memo(List);