import React from "react";
import DatePickerField from "../../../../Core/Forms/Fields/DatePickerField";
import { styled } from "@mui/material/styles";
import ReadOnlyDateTimeFormControl from "./ReadOnlyDateTimeFormControl";

const ReadOnlyDiv = styled("div")({
  display : "flex",
  borderRadius : 4,
  fontSize : 16,
  border : "1px solid rgba(0, 0, 0, 0.1)",
  background : "rgba(0, 0, 0, 0.01)",
  padding : "10.5px 14px",
  minHeight : 40,
  alignItems : "center"
});


const DateFormControl = (props : any) => {
  const isReadOnly = props?.isReadOnly ?? false;

  return <DatePickerField
    name={props?.name}
    isReadOnly={isReadOnly}
    form={props?.form}
    readOnlyRender={(inputValue : string, format : string) => <ReadOnlyDateTimeFormControl value={inputValue} format={format} />}
  />;
};

export default DateFormControl;