import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { styled } from '@mui/material/styles';
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { UseFormReturn } from "react-hook-form/dist/types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Box, Button as MuiButton,
  Checkbox,
  Chip, Collapse,
  FormControl,
  FormControlLabel,
  Grid, IconButton, Input,
  InputLabel,
  MenuItem, Paper,
  Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  TextField, Typography,
} from "@mui/material";
import { Plus as PlusIcon, Trash as TrashIcon, ChevronUp as ArrowUpIcon, ChevronDown as ArrowDownIcon } from "react-feather";
import FormTextField from "../../Core/Forms/Fields/TextField";


function ExpandableRow(props : any) {
  const { index, row, formPath } = props;
  const [open, setOpen] = React.useState(false);

  const attributesField = useFieldArray({
    control : props.form.control, // control props comes from useForm (optional: if you are using FormContext)
    name: `${formPath}.attributes`, // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  return (
    <React.Fragment>
      <TableRow>
        <TableCell sx={{ borderBottom : 0 }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell sx={{ borderBottom : 0 }} component="th" scope="row">
          <FormTextField form={props.form} defaultValue={props?.data?.value} name={`${formPath}.value`}  />
        </TableCell>

        <TableCell sx={{ borderBottom : 0 }} component="th" scope="row">
          <FormTextField form={props.form} defaultValue={props?.data?.label} name={`${formPath}.label`}  />
        </TableCell>

        <TableCell sx={{ borderBottom : 0 }} component="th" scope="row">
          <IconButton
            color="error"
            onClick={() => {
              props?.onRemove();
            }}
            size="large"
          >
            <TrashIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 10, paddingTop: 0, paddingLeft : 86 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit={false}>
            <Box margin={0}>
              <h3 style={{ marginBottom : 0, margin : 0}}>Atrybuty</h3>

              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Typ</TableCell>
                    <TableCell>Wartość</TableCell>
                    <TableCell align="right" width={60}></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {attributesField.fields.map((field: any, attrIndex) => {
                    return (
                      <TableRow key={field.id}>
                        <TableCell>
                          <FormTextField form={props.form}  defaultValue={field?.type} name={`${formPath}.attributes[${attrIndex}].type`}  />
                        </TableCell>
                        <TableCell>
                          <FormTextField form={props.form} defaultValue={field?.value} name={`${formPath}.attributes[${attrIndex}].value`}  />
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            color="error"
                            onClick={() => {
                              attributesField.remove(attrIndex);
                            }}
                            size="large">
                            <TrashIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}


                </TableBody>
              </Table>

              <MuiButton
                  variant="contained"
                  color="primary"
                  startIcon={<PlusIcon>send</PlusIcon>}
                  type="button"
                  size="small"
                  style={{ marginTop : 10 }}
                  onClick={(e) => {
                    attributesField.append({
                      type : "",
                      value : "",
                    })
                  }}
              >
                Dodaj atrybut
              </MuiButton>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const optionTypes : any = {
  "requirement" : {
    label : "Walidacja - wymagalność",
    component : (props : any) => {
      const { defaultData, formPath } = props;

      return (
          <Controller
            render={
              ({ field }) => <FormControlLabel
                  control={
                    <Checkbox {...field} checked={!!field.value} color="primary" />
                  }
                  label="Pole wymagane"
              />
            }
            control={props.form.control}
            defaultValue={defaultData?.isRequired || false}
            name={`${formPath}.value.isRequired`}
          />
      )
    }
  },


  "minLength" : {
    label : "Walidacja - min. długość",
    component : (props : any) => {
      const { defaultData, formPath } = props;

      return (
          <Controller
              render={
                ({ field }) => <TextField
                    label="Wartość"
                    id="outlined-size-small"
                    variant="outlined"
                    size="small"
                    fullWidth
                    {...field}
                />
              }
              control={props.form.control}
              name={`${formPath}.value.length`}
              defaultValue={defaultData?.length ?? ""}
          />
      )
    }
  },

  "maxLength" : {
    label : "Walidacja - maks. długość",
    component : (props : any) => {
      const { defaultData, formPath } = props;

      return (
          <Controller
              render={
                ({ field }) => <TextField
                    label="Wartość"
                    id="outlined-size-small"
                    variant="outlined"
                    size="small"
                    fullWidth
                    {...field}
                />
              }
              control={props.form.control}
              name={`${formPath}.value.length`}
              defaultValue={defaultData?.length ?? ""}
          />
      )
    }
  },

  "multipleSelect" : {
    label : "Pole wyboru - wiele wartości",
    component : (props : any) => {
      const { defaultData, formPath } = props;

      return (
          <Controller
              render={
                ({ field }) => <FormControlLabel
                    control={
                      <Checkbox {...field} checked={!!field.value} color="primary" />
                    }
                    label="Pozwalaj na wiele wartości"
                />
              }
              control={props.form.control}
              defaultValue={defaultData?.isEnabled || false}
              name={`${formPath}.value.isEnabled`}
          />
      )
    }
  },

  "validValue" : {
    label : "Walidacja - poprawna wartość",
    component : (props : any) => {
      const { defaultData, formPath } = props;
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [type, setType] = useState(defaultData?.type || 1);

      return (
        <>
          <Grid container item spacing={3}>
            <Grid item xs={4}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel id="demo-simple-select-label">Typ</InputLabel>
                <Controller
                  render={
                    ({ field }) =>
                      <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Typ"
                          {...field}
                          onChange={(e) => {
                            let value = parseInt(e.target.value as string);
                            props.form.setValue(field.name, value);
                            setType(value);
                          }}
                      >
                        <MenuItem value={1}>Wartość</MenuItem>
                        <MenuItem value={2}>Zakres</MenuItem>
                        <MenuItem value={3}>Regex</MenuItem>
                      </Select>
                  }
                  control={props.form.control}
                  name={`${formPath}.value.type`}
                  defaultValue={defaultData?.type || 1}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container item spacing={3}>
            <Grid item xs={4}>
              {type == 1 && (
                <Controller
                    render={
                      ({ field }) => <TextField
                          label="Wartość"
                          id="outlined-size-small"
                          variant="outlined"
                          size="small"
                          fullWidth
                          {...field}
                      />
                    }
                    control={props.form.control}
                    name={`${formPath}.value.values.0`}
                    defaultValue={defaultData?.values?.[0] || ""}
                />
              )}

              {type == 2 && (
                  <Grid container item spacing={3}>
                    <Grid item xs={6}>
                      <Controller
                          render={
                            ({ field }) => <TextField
                                label="Zakres od"
                                id="outlined-size-small"
                                variant="outlined"
                                size="small"
                                fullWidth
                                {...field}
                            />
                          }
                          control={props.form.control}
                          name={`${formPath}.value.values.0`}
                          defaultValue={defaultData?.values?.[0] || ""}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Controller
                          render={
                            ({ field }) => <TextField
                                label="Zakres do"
                                id="outlined-size-small"
                                variant="outlined"
                                size="small"
                                fullWidth
                                {...field}
                            />
                          }
                          control={props.form.control}
                          name={`${formPath}.value.values.1`}
                          defaultValue={defaultData?.values?.[1] || ""}
                      />
                    </Grid>
                  </Grid>
              )}

              {type == 3 && (
                <Controller
                  render={
                    ({ field }) => <TextField
                        label="Regex"
                        id="outlined-size-small"
                        variant="outlined"
                        size="small"
                        fullWidth
                        {...field}
                    />
                  }
                  control={props.form.control}
                  name={`${formPath}.value.values.0`}
                  defaultValue={defaultData?.values?.[0] || ""}
                />
              )}
            </Grid>
          </Grid>
        </>
      )
    }
  },

  "referenceValue" : {
    label : "Wartość referencyjna",
    component : (props : any) => {
      const { defaultData, formPath } = props;
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [type, setType] = useState(defaultData?.type || 1);

      return (
          <>
            <Grid container item spacing={3}>
              <Grid item xs={4}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">Typ</InputLabel>
                  <Controller
                      render={
                        ({ field }) =>
                          <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Typ"
                              {...field}
                              onChange={(e) => {
                                let value = parseInt(e.target.value as string);
                                props.form.setValue(field.name, value);
                                setType(value);
                              }}
                          >
                            <MenuItem value={1}>Wartość</MenuItem>
                            <MenuItem value={2}>Zakres</MenuItem>
                            <MenuItem value={3}>Regex</MenuItem>
                          </Select>
                      }
                      control={props.form.control}
                      name={`${formPath}.value.type`}
                      defaultValue={defaultData?.type || 1}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container item spacing={3}>
              <Grid item xs={4}>
                {type == 1 && (
                    <Controller
                        render={
                          ({ field }) => <TextField
                              label="Wartość"
                              id="outlined-size-small"
                              variant="outlined"
                              size="small"
                              fullWidth
                              {...field}
                          />
                        }
                        control={props.form.control}
                        name={`${formPath}.value.values.0`}
                        defaultValue={defaultData?.values?.[0] || ""}
                    />
                )}

                {type == 2 && (
                    <Grid container item spacing={3}>
                      <Grid item xs={6}>
                        <Controller
                            render={
                              ({ field }) => <TextField
                                  label="Zakres od"
                                  id="outlined-size-small"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  {...field}
                              />
                            }
                            control={props.form.control}
                            name={`${formPath}.value.values.0`}
                            defaultValue={defaultData?.values?.[0] || ""}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Controller
                            render={
                              ({ field }) => <TextField
                                  label="Zakres do"
                                  id="outlined-size-small"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  {...field}
                              />
                            }
                            control={props.form.control}
                            name={`${formPath}.value.values.1`}
                            defaultValue={defaultData?.values?.[1] || ""}
                        />
                      </Grid>
                    </Grid>
                )}

                {type == 3 && (
                    <Controller
                        render={
                          ({ field }) => <TextField
                              label="Regex"
                              id="outlined-size-small"
                              variant="outlined"
                              size="small"
                              fullWidth
                              {...field}
                          />
                        }
                        control={props.form.control}
                        name={`${formPath}.value.values.0`}
                        defaultValue={defaultData?.values?.[0] || ""}
                    />
                )}
              </Grid>
            </Grid>
          </>
      )
    }
  },

  "availableOptions" : {
    label : "Dostępne wartości",
    component : (props : any) => {
      const { defaultData, formPath } = props;

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const availableValuesField = useFieldArray({
        control : props.form.control, // control props comes from useForm (optional: if you are using FormContext)
        name: `${formPath}.value.options`, // unique name for your Field Array
        // keyName: "id", default to "id", you can change the key name
      });

      return (
          <>
            <TableContainer >
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell width={70} />
                    <TableCell>Wartość</TableCell>
                    <TableCell>Etykieta</TableCell>
                    <TableCell align="right" width={60}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {availableValuesField.fields.map((field, index) => (
                    <ExpandableRow
                      key={field.id}
                      form={props.form}
                      formPath={`${formPath}.value.options.${index}`}
                      data={field}
                      index={index}
                      onRemove={() => availableValuesField.remove(index)}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <MuiButton
                variant="contained"
                color="primary"
                startIcon={<PlusIcon>send</PlusIcon>}
                type="button"
                size="small"
                style={{ marginTop : 10 }}
                onClick={(e) => {
                  availableValuesField.append({
                    value : "",
                    label : "",
                    attributes : []
                  })
                }}
            >
              Dodaj wartość
            </MuiButton>
          </>
      )
    }
  }
};

function OptionRow(props : any) {
  const { data } = props;
  const isEmpty = Object.keys(props?.data?.value || {}).length <= 0;
  const [open, setOpen] = React.useState(isEmpty);

  const OptionControl = optionTypes[data.type]?.component || ((props : any) => {
    return <>-</>
  });

  return (
    <React.Fragment>
      <TableRow>
        <TableCell sx={{ borderBottom : 0 }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell sx={{ borderBottom : 0 }} component="th" scope="row" onClick={() => setOpen(!open)}>
          {optionTypes[data.type]?.label || "-"}
        </TableCell>

        <TableCell sx={{ borderBottom : 0 }} component="th" scope="row">
          <IconButton
            color="error"
            onClick={() => {
              props?.onRemove();
            }}
            size="large">
            <TrashIcon />
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft : 86 }} colSpan={3}>
          <Collapse in={open} timeout="auto">
            <div style={{ paddingBottom : 16 }}>
              <OptionControl form={props.form} formPath={props.formPath} defaultData={data?.value || {}} />
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const DataTypeAdditionalSettings = (props : any) => {
  // Form
  const settings = useFieldArray({
    control : props.form.control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'settings', // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });

  const selectOptionTypeRef = useRef<any>(null);

  return (
    <>
      <Grid container item spacing={3}>
        <Grid item xs={12}>
          <TableContainer >
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell width={70} />
                  <TableCell>Typ</TableCell>
                  <TableCell align="right" width={60}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {settings.fields.map((field, index) => (
                  <OptionRow
                    key={field.id}
                    form={props.form}
                    data={field}
                    formPath={`settings.${index}`}
                    index={index}
                    onRemove={() => settings.remove(index)}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid container item spacing={3}>
          <Grid item xs="auto">
            <FormControl variant="outlined" size="small" sx={{ width : 250 }} >
              <InputLabel id="demo-simple-select-label">Typ ustawienia</InputLabel>
              <Select
                label="Typ ustawienia"
                variant="outlined"
                placeholder="Wybierz typ"
                inputRef={selectOptionTypeRef}
                defaultValue=""
              >
                {Object.keys(optionTypes).map((type) => {
                  const data = optionTypes[type];

                  return (
                    <MenuItem value={type}>{data.label}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid  item xs={9}>
            <MuiButton
                variant="contained"
                color="primary"
                startIcon={<PlusIcon>send</PlusIcon>}
                type="button"
                size="small"
                onClick={(e) => {
                  const value = selectOptionTypeRef?.current?.value || null;

                  if(!value) {
                    alert("Wybierz typ ustawienia");
                    return;
                  }

                  settings.append({
                    type : value,
                    value : {},
                  })
                }}
            >
              Dodaj ustawienie
            </MuiButton>
          </Grid>

        </Grid>
      </Grid>
    </>
  );
}


const ConnectForm = ({ children } : any)  => {
  const methods = useFormContext();

  return children({ ...methods });
};


const DataTypeAdditionalSettingsForm = (props : any) => {
  if(props.form) {
    return <DataTypeAdditionalSettings form={props.form} {...props} />;
  }

  return (
    <ConnectForm>
      {(form : UseFormReturn) => <DataTypeAdditionalSettings form={form} {...props} />}
    </ConnectForm>
  );
}

export default DataTypeAdditionalSettingsForm;