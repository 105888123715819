import React, { useLayoutEffect } from "react";
import { useUIContext } from "../Context/UIContext";
import {useAuthContext} from "../Context/AuthContext";
import {navigate} from "@reach/router";

type MainRouteProps = {
  path: string;
  component: React.ReactNode;
  isAnonymousRoute ?: boolean;
};

export default function MainRoute(props: MainRouteProps) {
  const { actions } = useUIContext();
  const { state : authState } = useAuthContext();

  const isAnonymousRoute = props.isAnonymousRoute || false;

  if(!isAnonymousRoute && !authState.isAuthenticated) {
    // Refresh auth

    // Redirect otherwise
    navigate("/auth/login")
        .then()
        .catch()
        .then(() => {
          return <></>
        });
  }

  useLayoutEffect(() => {
    if (props.path == '/auth/login' || props.path == '/pdf-export') {
      actions.setLayout('default');
    } else {
      actions.setLayout('panel');
    }
  }, []);

  return <>{props.component}</>;
}