import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button  } from "@mui/material";
import { Link,  RouteComponentProps } from "@reach/router";
import Breadcrumbs from "../../../Components/Breadcrumbs";
import { Key as KeyIcon, Plus as PlusIcon, Trash as TrashIcon } from "react-feather";
import ListDataViewer from "../../../Core/ListDataViewer/ListDataViewer";
import apiEndpoints from "../../../OmniData/DataAccess/ApiEndpoints";

const NavigationLink = React.forwardRef((props: any, ref) => {
    return (
        <Link to={props.href} ref={ref} {...props}>{props.children}</Link>
    );
});

export function List(props: RouteComponentProps) {
  const columns = [
    {
      Header: 'Nazwa',
      accessor: 'name',
    },
    {
      Header : 'Data modyfikacji',
      accessor: 'lastModificationTime',
      type : 'datetime'
    },

    {
      Header : 'Data utworzenia',
      accessor: 'creationTime',
      type : 'datetime'
    },
  ];

  return (
      <>
        <Breadcrumbs title="Jednostki" />

        <Button
            component={NavigationLink}
            variant="contained"
            color="primary"
            startIcon={<PlusIcon>send</PlusIcon>}
            href="/data-warehouse/unit-types/create"
        >
            Dodaj
        </Button>

        <ListDataViewer
          searchIndexEnabled
          exportEnabled
          endpoints={{
            base : apiEndpoints.dataWarehouse.unitTypes.list()
          }}
          paths={{
            base : `/data-warehouse/unit-types`
          }}
          columns={columns}
        />
      </>
  );
}

export default React.memo(List);