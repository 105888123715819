import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
  Button as MuiButton,
  Card,
  CardActions,
  CardContent,
  Divider,
  ExtendButtonBase,
  List,
  ListItem as MuiListItem,
  ListItemTypeMap,
  Paper,
  Tab,
  Tabs,
  Typography,
  Link as MuiLink,
  TextField,
  Grid, AccordionSummary, AccordionDetails, FormControl, InputLabel, Select, MenuItem, IconButton,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Link } from "@reach/router";
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import {
  Plus as PlusIcon,
  ChevronRight as ChevronRightIcon,
  Save as SaveIcon,
  Trash as DeleteIcon, Move
} from "react-feather";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates, useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export interface ExpandableListProps
{
  onItemDelete : (index : number, key ?: any) => void,
  items : any[],
  renderItem : (item : any, index : number) => React.ReactElement
  renderTitle : (item : any, index : number) => string | undefined
  onItemSorted ?: (oldIndex : number, newIndex : number) => void;
  sortable ?: boolean;
  expandedItems ?: any;
  onExpandedItemsChange ?: (itemId : any, expanded : boolean) => void;
}

const PREFIX = 'FormElement';

const classes = {
  root: `${PREFIX}-root`,
  expanded: `${PREFIX}-expanded`
};

const StyledAccordion = styled(MuiAccordion)({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    paddingBottom : 0,

    '& .MuiAccordionSummary-root' : {
      minHeight : 'auto',
    },

    '& .MuiAccordionSummary-content': {
      margin: '8px 0',
    },

    '& .MuiAccordionSummary-root.Mui-expanded' : {
      '& .MuiAccordionSummary-content': {
        margin: '8px 0',
      },
    },

    '.MuiAccordionDetails-root' : {
      borderTop: '1px solid rgba(0, 0, 0, 0.23)',
    }
  },
});

const ExpandableListItemContent = (props: PropsWithChildren<any>) => {
  const expanded = props.expanded || false;
  const sortableDragHandleListeners = props.sortableDragHandleListeners;
  const [contentVisible, setContentVisible] = useState<boolean>(expanded);

  useEffect(() => {
    if(expanded && !contentVisible) {
      setContentVisible(true);
    }
  }, [expanded]);

  return (
      <StyledAccordion
          elevation={1}
          expanded={expanded}
          onChange={(e, expanded) => {
            props?.onExpandedChange(expanded);
            expanded && setContentVisible(expanded);
          }}
          TransitionProps={{
            onExited: () => { setContentVisible(false); }
          }}
          className={classes.root}
      >
        <AccordionSummary>
          <Typography>{props.title}</Typography>
          <div style={{ marginLeft: 'auto', padding : '5px', margin : '-8px -15px -8px auto',  position : 'relative' }}>
            {sortableDragHandleListeners && (
              <IconButton sx={{ marginRight: '16px' }} {...sortableDragHandleListeners} style={{
                width: 30,
                height: 30,
                minWidth: 0,
                borderRadius: '50%',
                padding : 0,
                position : "unset"
              }}>
                <Move />
              </IconButton>
            )}

            <MuiButton
                onClick={(e: any) => {
                  e.stopPropagation();
                  props.onDelete();
                }}
                onFocus={(e: any) => e.stopPropagation()}
                size="small"
                variant="contained"
                color="error"
                style={{
                  width: 30,
                  height: 30,
                  minWidth: 0,
                  borderRadius: '50%',
                  padding : 0,
                  position : "unset"
                }}
            >
              <DeleteIcon size={18} />
            </MuiButton>
          </div>
        </AccordionSummary>

        <AccordionDetails sx={{ padding : "8px 16px"}}>
          {contentVisible && (props.children)}
        </AccordionDetails>
      </StyledAccordion>
  );
}

const SortableItem = (props : any) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  if(props.sortable) {
    return (
      <div ref={setNodeRef} style={style} {...attributes}>
        {props.children({ sortableDragHandleListeners : listeners })}
      </div>
    );
  }

  return (
      <>
        {props.children()}
      </>
  );
}

export const ExpandableList = (props : ExpandableListProps) => {
  let items : any[] = props.items;
  const render = props.renderItem;
  const renderTitle = props.renderTitle;
  const sortable = props.sortable ?? false;
  const [draggedItemId, setDraggedItemId] = useState(null);
  const [expandedItems, setExpandedItems] = useState<any>({});

  const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
  );

  const handleDragStart = (event : any) => {
    const {active} = event;
    setDraggedItemId(active.id);
  }

  const handleDragEnd = (event : any) => {
    const {active, over} = event;

    if (active.id !== over.id && props.onItemSorted) {
      const oldIndex = sortableItems.indexOf(active.id);
      const newIndex = sortableItems.indexOf(over.id);
      props.onItemSorted(oldIndex, newIndex);
    }

    setDraggedItemId(null);
  }

  const getKey = (item : any, index : any) => {
    return item?.["_key"] ?? item?.id ?? index;
  };

  const onExpandedChange = (itemId : any, expanded : boolean) => {
    if(props.onExpandedItemsChange) {
      props.onExpandedItemsChange(itemId, expanded);
    } else {
      setExpandedItems({
        ...expandedItems,
        [itemId] : expanded
      })
    }
  }

  const expandedItemsData = props.expandedItems ?? expandedItems;

  const sortableItems = items.map((item, index) => getKey(item, index))

  const SortableWrapper = useMemo(() => (wrapperProps : any) => {
    if(!sortable) {
      return <>{wrapperProps.children}</>
    }

    return (
        <DndContext
          sensors={wrapperProps.sensors}
          collisionDetection={wrapperProps.collisionDetection}
          onDragStart={wrapperProps.onDragStart}
          onDragEnd={wrapperProps.onDragEnd}
        >
          <SortableContext
            items={wrapperProps.items}
            strategy={wrapperProps.strategy}
          >
            {wrapperProps.children}
          </SortableContext>
        </DndContext>

    );
  }, [sortable]);

  return (
        <SortableWrapper
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          items={sortableItems}
          strategy={verticalListSortingStrategy}
        >
            <List sx={{ paddingTop : 0 }}>
              {items.map((item, index) => {
                const key = getKey(item, index);
                return (
                    <SortableItem sortable={sortable} key={key} id={key}>
                      {(sortItemProps ?: any) => (
                          <MuiListItem disableGutters sx={{ paddingBottom : "0" }}>
                            <ExpandableListItemContent
                                expanded={(expandedItemsData?.[index] ?? false)}
                                onExpandedChange={(expanded : boolean) => {
                                  onExpandedChange(index, expanded)
                                }}
                                title={renderTitle(item, index)}
                                onDelete={() => props.onItemDelete(index, key)}
                                sortableDragHandleListeners={sortItemProps?.sortableDragHandleListeners}
                            >
                              {render(item, index)}
                            </ExpandableListItemContent>
                          </MuiListItem>
                      )}
                    </SortableItem>
                )
              })}
            </List>
        </SortableWrapper>

  );
}

export default ExpandableList;