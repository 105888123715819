import { navigate, RouteComponentProps } from "@reach/router";
import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Save as SaveIcon } from "react-feather";
import Button from "../../../Components/Button";
import api from "../../../Api";

export default function Index(props: RouteComponentProps) {
  const [inProgress, setInProgress] = useState<boolean>(false);

  const enqueueEntitiesReindexing = async () => {
    try {
      setInProgress(true);
      await api.app.searchIndex.enqueueEntitiesReindexing();
      alert('ok');
    } catch (err) {
      alert(err);
      console.error(err);
    } finally {
      setInProgress(false);
    }
  }

  const regenerateEntitiesIndexFilters = async () => {
    try {
      setInProgress(true);
      await api.app.searchIndex.regenerateEntitiesFiltersIndex();
      alert('ok');
    } catch (err) {
      alert(err);
      console.error(err);
    } finally {
      setInProgress(false);
    }
  };

  const rebuildCols = async () => {
    try {
      setInProgress(true);
      await api.app.searchIndex.regenerateDataListColumns();
      alert('ok');
    } catch (err) {
      alert(err);
      console.error(err);
    } finally {
      setInProgress(false);
    }
  };


  return (
      <>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          inProgress={inProgress}
          onClick={enqueueEntitiesReindexing}
        >
          Enquque indexing
        </Button>

        <Button
            variant="contained"
            color="primary"
            type="submit"
            inProgress={inProgress}
            onClick={regenerateEntitiesIndexFilters}
        >
          Rebuild filters
        </Button>

        <Button
            variant="contained"
            color="primary"
            type="submit"
            inProgress={inProgress}
            onClick={rebuildCols}
        >
          Rebuild cols
        </Button>
      </>
  );
}