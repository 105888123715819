import { Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

export interface TextFilterProps
{
  label : string;
  id : any;
  currentValue : any;
  onChange : (newValue : any) => void;
}

const normalizeValue = (v : any) => v == undefined ? "" : v;

const TextFilter = (props : TextFilterProps) => {
  const [value, setValue] = useState<undefined | string>(normalizeValue(props?.currentValue?.values?.[0]));

  const submit = () => {
    if(!value || value.trim() == "") {
      props.onChange(undefined);
    } else {
      props.onChange({
        values : [value],
      });
    }
  };

  return (
      <form onSubmit={(e) => { e.preventDefault(); submit(); }}>
        <div>
          <TextField
              size="small"
              label={props.label}
              key={props.id}
              value={normalizeValue(value)}
              onChange={(e) => setValue(e.currentTarget.value)}
          />
        </div>
        <div>
          <Button sx={{ marginTop : "8px" }} onClick={submit} disableElevation fullWidth variant="contained">Filtruj</Button>
        </div>
      </form>
  )
}

export default TextFilter;