import { PropsWithChildren, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export interface ConfirmProps
{
  onConfirm : () => void;
}

export const Confirm = (props : PropsWithChildren<ConfirmProps>) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClick = () => {
    setOpen(true);
  };

  const handleConfirm = () => {
    props.onConfirm();
    setOpen(false);
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ ".MuiPaper-root" : {
          minWidth : 300
        }}}
      >
        <DialogTitle>{"Czy jesteś pewien?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Usuwanie może być nieodwracalne. Potwierdź operację.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Anuluj</Button>
          <Button  color="error" variant="contained" onClick={handleConfirm} autoFocus>
            Potwierdzam
          </Button>
        </DialogActions>
      </Dialog>

      {(props.children as any)(onClick)}
    </>
  )
};


export default Confirm;