import React, {useCallback, useEffect, useMemo, useRef} from "react";

import { styled } from '@mui/material/styles';

import Checkbox from "@mui/material/Checkbox";
import MaUTable from "@mui/material/Table";
import PropTypes from "prop-types";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useResizeColumns
} from "react-table";
import withStyles from '@mui/styles/withStyles';
import { Pagination } from '@mui/material';


import {
    ChevronsLeft as FirstPageIcon,
    ChevronsRight as LastPageIcon,
    ChevronLeft as KeyboardArrowLeft,
    ChevronRight as KeyboardArrowRight,
} from "react-feather";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";


const PREFIX = 'DataTable';

const classes = {
  root: `${PREFIX}-root`
};

const StyledTableContainer = styled(TableContainer)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
  },
}));

const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div style={{ flexShrink: 0, display: "inline-flex", marginLeft : 18 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
                size="large">
                {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
                size="large">
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
                size="large">
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
                size="large">
                {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
};

const SmallCheckbox = styled(Checkbox)({
    marginTop : '-6px',
    marginBottom : '-6px',
});

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
    <SmallCheckbox
      color="primary"
      ref={resolvedRef}
      indeterminate={indeterminate}
      {...rest}
    />
    );
  }
);

// Create an editable cell renderer
const EditableCell = ({
  value,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  return <>{value}</>;
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
};

const useResizer = () => {
    const dragHandlesRefs = useRef({});
    const resizableRefs = useRef({});
    let draggedKey = null;

    const doDrag = useCallback((e) => {
        const resizableRef = resizableRefs?.current?.[draggedKey];

        if(!resizableRef) {
            return;
        }

        resizableRef.style.width = (parseInt(resizableRef.dataset.startWidth) + e.clientX - parseInt(resizableRef.dataset.dragStartX)) + 'px';
    }, []);

    const stopDrag = useCallback((e) => {
        document.removeEventListener('mousemove', doDrag, false);
        document.removeEventListener('mouseup', stopDrag, false);

        const resizableRef = resizableRefs?.current?.[draggedKey];

        if(!resizableRef) {
            // Update state
        }
    }, []);

    const initDrag = useCallback((e, key) => {
        const resizableRef = resizableRefs?.current?.[key];

        if(!resizableRef) {
            return;
        }
        e.stopPropagation();
        e.preventDefault();
        // Update state

        draggedKey = key;
        resizableRef.dataset.dragStartX = e.clientX;
        resizableRef.dataset.startWidth = resizableRef.clientWidth;
    console.log(resizableRef.getBoundingClientRect());
        document.addEventListener('mousemove', doDrag, false);
        document.addEventListener('mouseup', stopDrag, false);
    }, []);

    return {
        getResizableProps : (key) => {
            return {
                ref : (el) => resizableRefs.current[key] = el,
            }
        },

        getDragHandleProps : (key) => {
            return {
                ref : (el) => dragHandlesRefs.current[key] = el,
                onMouseDown : (e) => initDrag(e, key)
            }
        }
    }
}


const DataTable = ({
  columns,
  data,
  autoResetPage = false,
  onRowClick = (r, i, ev) => {},
  onRowDoubleClick = (r, i, ev) => {},
  onPageChange = (newPage) => {},
  onSortChange = (columnIn, sortState) => {},
  resultsCount = 1,
  resultsPerPage = 15,
  currentPage = 1,
  currentSortBy = [],
  disablePagination = false,
  disableMultipleRowsSelect = false
}) => {
  const currentSortByM = useMemo(() => currentSortBy, [JSON.stringify(currentSortBy)]);
    const resizer = useResizer();

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    setSortBy,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter, sortBy },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetPage: false,
      manualPagination : true,
      manualSortBy : true,
      disableMultiSort : true,
      initialState: { pageSize: resultsPerPage , sortBy : currentSortByM },
      state: { pageSize : resultsPerPage  }
    },
    useSortBy,
    usePagination,
    useRowSelect,

    (hooks) => {
      hooks.allColumns.push((columns) => {
          var selectionColumn = {
              id: "selection",

              disableSortBy : true,
              defaultCanSort : false,
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox.  Pagination is a problem since this will select all
              // rows even though not all rows are on the current page.  The solution should
              // be server side pagination.  For one, the clients should not download all
              // rows in most cases.  The client should only download data for the current page.
              // In that case, getToggleAllRowsSelectedProps works fine.
              Header: ({ getToggleAllRowsSelectedProps }) => (
                  <div>
                      <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                  </div>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => (
                  <div>
                      <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                  </div>
              ),
          };

          if(disableMultipleRowsSelect) {
              return columns;
          }

          return [
              selectionColumn,
              ...columns,
          ]
      });

    }
  );

  useEffect(() => {
      if(sortBy && sortBy.length > 0) {
        onSortChange(sortBy[0].id, sortBy[0].desc ? "desc" : "asc");
      } else {
        onSortChange();
      }
  }, [JSON.stringify(sortBy)])


  // Render the UI for your table
  return (
      <>
    <TableContainer sx={{ maxHeight : 800 }}>
      <MaUTable {...getTableProps()} stickyHeader size="small" sx={{ }}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()} >
              {headerGroup.headers.map((column) => {
                  return (
                  <TableCell
                      {...(column.id === "selection"
                          ? column.getHeaderProps()
                          : column.getHeaderProps(column.getSortByToggleProps()))}
                      style={{
                          width : column.id === "selection" ? 75 : undefined,
                          paddingRight : column.id === "selection" ? 16 : undefined,
                      }}
                  >
                      <div>
                          {column.render("Header")}
                          {column.canSort ? (
                              <TableSortLabel
                                  active={column.isSorted}
                                  // react-table has a unsorted state which is not treated here
                                  direction={column.isSortedDesc ? "desc" : "asc"}
                              />
                          ) : null}

                      </div>
                  </TableCell>
                  )
              })}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <TableRow
                {...row.getRowProps()}
                hover
                onDoubleClick={async (e) => {
                    await onRowDoubleClick(row, i, e);
                }}
                onClick={async (e) => {
                    await onRowClick(row, i, e);
                }}
              >
                {row.cells.map((cell) => {
                  return (
                    <TableCell className={cell.column.id == "actions" ? "tableCell--actions" : ""} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>

      </MaUTable>
    </TableContainer>

    {!disablePagination && (
        <TablePagination
            component="div"
            rowsPerPageOptions={[]}
            count={resultsCount}
            rowsPerPage={pageSize}
            page={currentPage}
            align="left"
            SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
            }}
            onPageChange={(e, page) => onPageChange(page)}
            ActionsComponent={TablePaginationActions}
            labelRowsPerPage="Wyniki na stronę"
            labelDisplayedRows={(paginationInfo) => {
                return `${paginationInfo.from} - ${paginationInfo.to} z ${paginationInfo.count}`
            }}
        />
    )}
      </>
  );
};

export default DataTable;
