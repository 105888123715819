import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Card, CardContent, Divider, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Link, navigate, RouteComponentProps } from "@reach/router";
import DataGrid from "../../../Components/DataGrid/DataGrid";
import Breadcrumbs from "../../../Components/Breadcrumbs";
import _ from "lodash";
import api from "../../../Api";
import { Plus as PlusIcon, Trash as TrashIcon } from "react-feather";
import {
  useGetControlCardGroupsList,
  useGetDataTypesAsList,
  useGetUnitTypesAsList
} from "../../../OmniData/DataAccess";
import SearchInput from "../../../Components/SearchInput";
import ListDataViewer from "../../../Core/ListDataViewer/ListDataViewer";
import NavigationLink from "../../../Core/NavigationLink";

export function List(props: RouteComponentProps) {


    return (
        <>
            <Breadcrumbs title="Grupy" />

            <Button
                component={NavigationLink}
                variant="contained"
                color="primary"
                startIcon={<PlusIcon>send</PlusIcon>}
                href="/control-cards/groups/create"
            >
                Dodaj
            </Button>

          <ListDataViewer
              searchIndexEnabled
              exportEnabled
              endpoints={{
                base : `/api/app/control-card-groups`
              }}
              paths={{
                base : `/control-cards/groups`
              }}
              columns={[
                {
                  Header: 'Nazwa',
                  accessor: 'name',
                },
                {
                  Header : 'Data modyfikacji',
                  accessor: 'lastModificationTime',
                  type : 'datetime'
                },

                {
                  Header : 'Data utworzenia',
                  accessor: 'creationTime',
                  type : 'datetime'
                },
              ]}
          />
        </>
    );
}

export default React.memo(List);