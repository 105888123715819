import { navigate, RouteComponentProps, useLocation } from "@reach/router";
import React, { FormEvent, useState } from "react";
import ControlCardView from "../../OmniData/ControlCard/ControlCardView";

export default function PdfExport(props: RouteComponentProps) {
  const location = useLocation();
  const search = new URLSearchParams(location.search);

  return (
      <>
        <ControlCardView id={search.get("id") as string} mode="view" />
      </>
  );
}