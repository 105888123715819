import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Card, CardContent, Divider, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Link, navigate, RouteComponentProps } from "@reach/router";
import DataGrid from "../../../Components/DataGrid/DataGrid";
import Breadcrumbs from "../../../Components/Breadcrumbs";
import _ from "lodash";
import api from "../../../Api";
import { Plus as PlusIcon, Trash as TrashIcon } from "react-feather";
import SearchInput from "../../../Components/SearchInput";
import { useGetControlCardTypesList, useGetFormTemplates } from "../../../OmniData/DataAccess";
import apiEndpoints from "../../../OmniData/DataAccess/ApiEndpoints";
import ListDataViewer from "../../../Core/ListDataViewer/ListDataViewer";

const NavigationLink = React.forwardRef((props: any, ref) => {
    return (
        <Link to={props.href} ref={ref} {...props}>{props.children}</Link>
    );
});

export function List(props: RouteComponentProps) {

    return (
        <>
            <Breadcrumbs title="Formularze" />

            <Button
                component={NavigationLink}
                variant="contained"
                color="primary"
                startIcon={<PlusIcon>send</PlusIcon>}
                href="/data-warehouse/form-types/create"
            >
                Dodaj
            </Button>

          <ListDataViewer
              searchIndexEnabled
              exportEnabled
              endpoints={{
                base : apiEndpoints.dataWarehouse.formTemplates.list()
              }}
              paths={{
                base : `/data-warehouse/form-types`
              }}
              columns={[
                {
                  Header: 'Nazwa',
                  accessor: 'name',
                },
                {
                  Header : 'Data modyfikacji',
                  accessor: 'lastModificationTime',
                  type : 'datetime'
                },

                {
                  Header : 'Data utworzenia',
                  accessor: 'creationTime',
                  type : 'datetime'
                },
              ]}
          />
        </>
    );
}

export default React.memo(List);