import useSWR from "swr";
import { useMemo } from "react";
import { swrDataFetcherV2 } from "../../Core/DataAccess";


export const useGetDataTypesAsOptions = () => {
  const { data, error } = useSWR(`/api/app/data-types`);
  let list : any[] = [];

  if(data && data.items) {
    list = data.items.map((item : any) => {
      return {
        value : item.id,
        label : item.displayName
      }
    });
  }

  return {
    data: list,
    isLoading: !error && !data,
    isError: error
  }
}

export const useGetDataTypesAsList = (search ?: string) => {
  const { data, error, mutate , revalidate } = useSWR([`/api/app/data-types`, search]);

  return {
    data: data && data.items ? data.items : [],
    isLoading: !error && !data,
    isError: error,
    mutate : mutate,
    revalidate : revalidate
  }
}


export const useGetUnitTypesAsList = (search ?: string) => {
  const { data, error, mutate , revalidate } = useSWR([`/api/app/unit-type`, search, 'abc']);

  return {
    data: data && data.items ? data.items : [],
    isLoading: !error && !data,
    isError: error,
    mutate : mutate,
    revalidate : revalidate
  }
}

export const useGetUsersList = (search ?: string) => {
  const { data, error, mutate , revalidate } = useSWR([`/api/identity/users`, search]);

  return {
    data: data && data.items ? data.items : [],
    isLoading: !error && !data,
    isError: error,
    mutate : mutate,
    revalidate : revalidate
  }
}

export const useGetDataFromApi = (url ?: string, payload ?: any) => {
  const payloadAsString = JSON.stringify(payload);
  const { data, error, mutate , revalidate } = useSWR(url ? [url, payload ? payloadAsString : ""] : null, swrDataFetcherV2);

  return {
    data: data,
    error : error,
    isLoading: !error && !data,
    isError: error,
    mutate : mutate,
    revalidate : revalidate
  }
}

export const useGetApiData = (url ?: string, payload ?: any) => {
  return useGetDataFromApi(url,payload);
}


export const useGetOU = () => {
  const { data, error, mutate , revalidate } = useSWR('/api/app/organization-units');

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    mutate : mutate,
    revalidate : revalidate
  }
}

export const useGetSingleUser = (id : string) => {
  const { data, error, mutate , revalidate } = useSWR(`/api/identity/users/${id}`);

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    mutate : mutate,
    revalidate : revalidate
  }
}

export const useGetSingleUnitType = (id : string) => {
  const { data, error, mutate , revalidate } = useSWR(`/api/app/unit-type/${id}`);

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    mutate : mutate,
    revalidate : revalidate
  }
}

export const useGetUnitTypesAsOptions = () => {
  const { data, error } = useGetDataFromApi(`/api/app/unit-type`, {
    maxResultCount : 1000
  });
  let list : any[] = [];

  if(data && data.items) {
    list = data.items.map((item : any) => {
      return {
        value : item.id,
        label : item.name
      }
    });
  }

  return {
    data: list,
    isLoading: !error && !data,
    isError: error
  }
}

export const useGetControlCardsList = (search ?: string) => {
  const { data, error, mutate , revalidate } = useSWR([`/api/app/control-card`, search]);

  return {
    data: data && data.items ? data.items : [],
    isLoading: !error && !data,
    isError: error,
    mutate : mutate,
    revalidate : revalidate
  }
}

export const useGetControlCardTemplatesList = (search ?: string) => {
  const { data, error, mutate , revalidate } = useSWR([`/api/app/control-cards-templates`, search]);

  return {
    data: data && data.items ? data.items : [],
    isLoading: !error && !data,
    isError: error,
    mutate : mutate,
    revalidate : revalidate
  }
}

export const useGetControlCardTypesList = (search ?: string) => {
  const { data, error, mutate , revalidate } = useSWR([`/api/app/control-card-types`, search]);

  return {
    data: data && data.items ? data.items : [],
    isLoading: !error && !data,
    isError: error,
    mutate : mutate,
    revalidate : revalidate
  }
}


export const useGetSingleControlCardType = (id : string) => {
  const { data, error, mutate , revalidate } = useSWR(`/api/app/control-card-types/${id}`);

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    mutate : mutate,
    revalidate : revalidate
  }
}

export const useGetControlCardGroupsList = (search ?: string) => {
  const { data, error, mutate , revalidate } = useSWR([`/api/app/control-card-groups`, search]);

  return {
    data: data && data.items ? data.items : [],
    isLoading: !error && !data,
    isError: error,
    mutate : mutate,
    revalidate : revalidate
  }
}

export const useGetSingleControlCardGroup = (id : string) => {
  const { data, error, mutate , revalidate } = useSWR(`/api/app/control-card-groups/${id}`);

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    mutate : mutate,
    revalidate : revalidate
  }
}

export const useGetControlCardTemplatesAsOptions = () => {
  const { data, error } = useSWR(`/api/app/control-cards-templates`);
  let list : any[] = [];

  if(data && data.items) {
    list = data.items.map((item : any) => {
      return {
        value : item.id,
        label : item.name
      }
    });
  }

  return {
    data: list,
    isLoading: !error && !data,
    isError: error
  }
}


export const useGetSingleControlCard= (id : string) => {
  const { data, error, mutate , revalidate } = useSWR(`/api/app/control-card/${id}`);

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    mutate : mutate,
    revalidate : revalidate
  }
}


export const useGetSingleControlCardTemplate = (id : string) => {
  const { data, error, mutate , revalidate } = useSWR(id ? `/api/app/control-cards-templates/${id}` : null);

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    mutate : mutate,
    revalidate : revalidate
  }
}

export const useGetFormTemplates = (search ?: string) => {
  const { data, error, mutate , revalidate } = useSWR([`/api/app/form-templates`, search]);

  return {
    data: data && data.items ? data.items : [],
    isLoading: !error && !data,
    isError: error,
    mutate : mutate,
    revalidate : revalidate
  }
}



export const useGetDataFormsAsOptions = (search ?: string) => {
  const { data, error } = useSWR([`/api/app/form-templates`, search]);
  let list : any[] = [];

  if(data && data.items) {
    list = data.items.map((item : any) => {
      return {
        value : item.id,
        label : item.name
      }
    });
  }

  return {
    data: list,
    isLoading: !error && !data,
    isError: error
  }
}

export const useGetFormType = (id : string) => {
  const { data, error } = useSWR(`/api/app/form-templates/${id}`);
  let list : any[] = [];

  return {
    data: data,
    isLoading: !error && !data,
    isError: error
  }
}


export const useGetDataType = (id : string) => {
  const { data, error } = useSWR(`/api/app/data-types/${id}`);
  let list : any[] = [];

  return {
    data: data,
    isLoading: !error && !data,
    isError: error
  }
}

