import Breadcrumbs from "../../../Components/Breadcrumbs";
import React from "react";
import { Typography } from "@mui/material";
import { navigate } from "@reach/router";
import ListDataViewer from "../../../Core/ListDataViewer/ListDataViewer";

const ApprovalDashboard = () => {

  return (
      <>
        <Breadcrumbs title={"Zatwierdzanie kart"} />

        <Typography>Karty oczekujące na zatwierdzenie</Typography>

        <ListDataViewer
            endpoints={{
              base : `/api/app/control-card/available-cards-for-approval`
            }}
            paths={{
              base : `/control-cards/operator/card`
            }}
            columns={[
              {
                Header: 'Nazwa',
                accessor: 'name',
                disableSortBy : true,
              },

              {
                Header: 'Grupa',
                accessor: 'template.controlCardGroup.name',
                disableSortBy : true,
              },

              {
                Header: 'Typ',
                accessor: 'template.controlCardType.name',
                disableSortBy : true,
              },

              {
                Header : 'Data modyfikacji',
                accessor: 'lastModificationTime',
                type : 'datetime',
                disableSortBy : true
              },

              {
                Header : 'Data utworzenia',
                accessor: 'creationTime',
                type : 'datetime',
                disableSortBy : true
              },
            ]}
            rowActionsMenuOptions={{
              disabled : true
            }}
            onRowDoubleClick={async (row) => {
              await navigate(`/control-cards/${row.original.id}/approve`);
            }}
            disableFilters
            disableMultipleRowsSelect
            disablePagination
        />
      </>
  )
}

export default ApprovalDashboard;