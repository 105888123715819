import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
  Button as MuiButton,
  Card,
  CardActions,
  CardContent,
  Divider,
  ExtendButtonBase,
  List,
  ListItem as MuiListItem,
  ListItemTypeMap,
  Paper,
  Tab,
  Tabs,
  Typography,
  Link as MuiLink,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Link, navigate, useParams } from "@reach/router";
import React, { useEffect, useState } from "react";
import { Plus as PlusIcon, ChevronRight as ChevronRightIcon, Save as SaveIcon, Trash as TrashIcon } from "react-feather";
import Breadcrumbs from "../../../Components/Breadcrumbs";
import Button from "../../../Components/Button";
import { Controller, FormProvider, useForm, useFormContext } from "react-hook-form";
import _ from "lodash";
import api from "../../../Api";
import DataTypeAdditionalSettings from "../../../Components/DataWarehouse/DataTypeAdditionalSettingsForm";
import { mergeWithDefaultData } from "../../../Common/Utils";
import { useSnackbar } from "notistack";
import { useGetSingleControlCardTemplate, useGetSingleControlCardType } from "../../../OmniData/DataAccess";
import apiEndpoints from "../../../OmniData/DataAccess/ApiEndpoints";
import Form from "../../../Core/Forms/Form";
import TextField from "../../../Core/Forms/Fields/TextField";


const MainForm = (props: any) => {
  const form = useForm({
    reValidateMode : "onSubmit"
  });

  const actionUrl = props?.currentData?.id
      ? apiEndpoints.controlCard.type.update(props.currentData.id)
      : apiEndpoints.controlCard.type.create();

  useEffect(() => {
    form.reset(props.currentData);
  }, [props.currentData]);

  return (
    <Form action={actionUrl} method={props?.currentData?.id ? "PUT" : "POST" } form={form}>
      {(formProps : any) => (
        <>
          <Typography variant="h6" style={{ fontWeight: 400 }}>Dane podstawowe</Typography>

          <Paper style={{ padding: 16 }} elevation={1}>
            <Grid container spacing={1}>
              <Grid container item spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    name="name"
                    label="Nazwa"
                  />
                </Grid>
              </Grid>
            </Grid>

          </Paper>

          <Divider style={{ marginTop: 24, marginBottom: 8 }} />
        </>
      )}
    </Form>
  );
};


const UpdateView = ({ id } : any) => {
  const { data } = useGetSingleControlCardType(id);
  return (
      <>
        <Breadcrumbs title={"Aktualizacja"}/>
        <MainForm currentData={data}/>
      </>
  );
};

const CreateView = ({ id } : any) => {
  return (
      <>
        <Breadcrumbs title={"Tworzenie"}/>
        <MainForm currentData={null}/>
      </>
  );
};

export default function CreateUpdate(props: any) {
  const params = useParams();

  return props.mode == "update" && params.id
      ? <UpdateView id={params.id} />
      : <CreateView />
}