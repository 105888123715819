import React, { useState } from "react";
import {
  useGetApiData,
  useGetControlCardTemplatesAsOptions,
  useGetDataFromApi,
  useGetDataType
} from "../../../OmniData/DataAccess";
import { navigate } from "@reach/router";
import Breadcrumbs from "../../../Components/Breadcrumbs";
import {
  Dialog, DialogActions,
  DialogContent, DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import Button from "../../../Components/Button";
import apiEndpoints from "../../../OmniData/DataAccess/ApiEndpoints";
import ListDataViewer from "../../../Core/ListDataViewer/ListDataViewer";
import { Type } from "react-feather";
import api from "../../../Api";

const ConfirmCardCreateDialog = (props : any) => {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Wymagane potwierdzenie"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Aktywna karta o wybranym wzorze już istnieje. <br />
            Upewnij się czy na pewno chcesz stworzyć nową kartę.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            Anuluj
          </Button>
          <Button inProgress={props?.inProgress} onClick={props.onConfirm} color="primary" variant="contained" autoFocus>
            Stwórz nową kartę
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const SelectCardTemplateView = () => {
  const [templateId, setTemplateId] = useState("");
  const availableTemplates = useGetDataFromApi(`/api/app/control-card-operator/available-card-templates`);
  const [confirmCardCreateDialogOpened, setConfirmCardCreateDialogOpened] = useState<boolean>(false);
  const [inProgress, setInProgress] =  useState<boolean>(false);
  const templates = availableTemplates?.data?.items || [];

  const select = async () => {
    setInProgress(inProgress);
    // Send request which will create new or get current card with given template
    var request = await api.app.controlCards.operator.initCardFilling(templateId);
    var data = request?.data || {};
    if(data.isExisting) {
      setConfirmCardCreateDialogOpened(true);
    } else {
      await navigate(`/control-cards/${data.id}/operator`);
    }
  }

  const forceNewCard = async () => {
    setInProgress(inProgress);
    var request = await api.app.controlCards.operator.initCardFilling(templateId, true);
    var data = request?.data || {};

    if(!data.isExisting) {
      await navigate(`/control-cards/${data.id}/operator`);
    }
  };

  if(availableTemplates.isLoading) {
    return <>Loading...</>
  }

  return (
      <>
        <Breadcrumbs title={"Wybierz wzór karty"}/>

        <Typography>Dostępne wzory kart</Typography>

        <div style={{ marginTop : 10 }}>
          <FormControl variant="outlined" size="small" style={{ width : 200 }} >
            <InputLabel id="demo-simple-select-label">Wzór</InputLabel>
            <Select
                id="demo-simple-select"
                labelId="demo-simple-select-label"
                label="Wzór"
                value={templateId}
                onChange={(e) => {
                  setTemplateId(e.target.value as string);
                }}
            >
              {templates && templates.map((item : any) => (
                  <MenuItem key={item.templateId} value={item.templateId}>{ item.templateName }</MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button inProgress={inProgress} onClick={select} style={{ marginLeft : 16 }} variant="contained" size="medium" color="primary">Wypełnij</Button>
        </div>

        <ConfirmCardCreateDialog
          open={confirmCardCreateDialogOpened}
          onClose={() => setConfirmCardCreateDialogOpened(false)}
          onConfirm={forceNewCard}
          inProgress={inProgress}
        />
      </>
  );
};

const SelectCardView = (props : any) => {

  return (
    <>
      <Typography>Karty w trakcie wypełniania</Typography>
      <ListDataViewer
        endpoints={{
          base : `/api/app/control-card-operator/available-cards`
        }}
        paths={{
          base : `/control-cards/operator/card`
        }}
        columns={[
          {
            Header: 'Nazwa',
            accessor: 'templateName',
            disableSortBy : true,
          },

          {
            Header: 'Grupa',
            accessor: 'templateGroupName',
            disableSortBy : true,
          },

          {
            Header: 'Typ',
            accessor: 'templateTypeName',
            disableSortBy : true,
          },

          {
            Header: 'Zawiera niezatwierdzone elementy',
            accessor: 'hasNotSubmittedElements',
            disableSortBy : true,
            type : "boolean"
          },

          {
            Header : 'Data modyfikacji',
            accessor: 'lastModificationTime',
            type : 'datetime',
            disableSortBy : true
          },

          {
            Header : 'Data utworzenia',
            accessor: 'creationTime',
            type : 'datetime',
            disableSortBy : true
          },
        ]}
        rowActionsMenuOptions={{
          disabled : true
        }}
        onRowDoubleClick={async (row) => {
          await navigate(`/control-cards/${row.original.cardId}/operator`);
        }}
        disableFilters
        disableMultipleRowsSelect
        disablePagination
      />
    </>
  )
}

const OperatorDashboard = (props : any) => {

  return (
      <>
        <SelectCardTemplateView />
        <hr style={{ marginTop : 15, marginBottom : 15 }} />
        <SelectCardView />
      </>
  )
}

export default OperatorDashboard;