import axios from "axios";
import {getAuthToken} from "../Context/AuthContext";


const client = axios.create({
    baseURL : window.location.hostname.includes('localhost')
        ? 'https://localhost:44342'
        : window.location.origin,
})

client.interceptors.request.use(function (config) {
    config.headers["Authorization"] = `Bearer ${getAuthToken()}`;
    return config;
});

const api = {
    getClient : () => {
        return client;
    },

    common : {
        getInitialData : async () => {
            return client.get('/api/abp/application-configuration')
        },

        users : {
            update : (id : string, data : any) => {
                return client.put(`/api/identity/users/${id}`, data);
            },

            create : (data : any) => {
                return client.post(`/api/identity/users`, data);
            },

            delete : (id : string) => {
                return client.delete(`/api/identity/users/${id}`);
            },
        },

        organizationUnits : {
            create : (data : any) => {
                return client.post(`/api/app/organization-units`, data);
            },
        },

        roles : {
            get : (id : string) => {
                return client.get(`/api/app/roles/${id}`);
            },

            delete : (id : string) => {
                return client.delete(`/api/identity/roles/${id}`);
            },
        }
    },

    auth : {
        getToken : async (username ?: string | null , password ?: string | null ) => {
            let data = new URLSearchParams();
            data.set("grant_type", "password");
            data.set("scope", "OmniData");
            data.set("client_id", "OmniData_App");

            username && data.set("username", username);
            password && data.set("password", password);

            return client.post("/connect/token", data);
        },
    },

    app : {
        searchIndex : {
            enqueueEntitiesReindexing : () => {
                return client.post(`/api/app/search-index/enqueue-entities-reindexing`);
            },

            regenerateEntitiesFiltersIndex : () => {
                return client.post(`/api/app/search-index/regenerate-entities-filters-index`);
            },

            regenerateDataListColumns : () => {
                return client.post(`/api/app/search-index/rebuild-data-list-columns`);
            }
        },

        controlCards : {
            operator : {
                initCardFilling : (templateId : string, forceFresh = false) => {
                    return client.post(`/api/app/control-card-operator/init-card-filling/${templateId}?forceFresh=${forceFresh}`);
                },
            },

            cards : {
                saveCardElement : (cardId : string, elementData : any) => {
                    return client.put(`/api/app/control-card/${cardId}/element`, elementData);
                },

                deleteCardElement : (elementId : string) => {
                    return client.delete(`/api/app/control-card/element/${elementId}`);
                },

                submitCardElementsGroup : (cardId : string, cardTemplateElementId : string) => {
                    return client.post(`/api/app/control-card/${cardId}/submit-elements-group/${cardTemplateElementId}`);
                }
            },

            filled : {
                update : (id : string, data : any) => {
                    return client.put(`/api/app/control-card/${id}`, data);
                },

                create : (data : any) => {
                    return client.post(`/api/app/control-card`, data);
                },

                delete : (id : string) => {
                    return client.delete(`/api/app/control-card/${id}`);
                },

                approve : (cardId : string, approvalTemplateId : any) => {
                    return client.post(`/api/app/control-card/${cardId}/approve/${approvalTemplateId}`);
                }
            },


            templates : {
                update : (id : string, data : any) => {
                    return client.put(`/api/app/control-cards-templates/${id}`, data);
                },

                create : (data : any) => {
                    return client.post(`/api/app/control-cards-templates`, data);
                },

                delete : (id : string) => {
                    return client.delete(`/api/app/control-cards-templates/${id}`);
                },
            },

            groups : {
                update : (id : string, data : any) => {
                    return client.put(`/api/app/control-card-groups/${id}`, data);
                },

                create : (data : any) => {
                    return client.post(`/api/app/control-card-groups`, data);
                },

                delete : (id : string) => {
                    return client.delete(`/api/app/control-card-groups/${id}`);
                },
            },

            types : {
                update : (id : string, data : any) => {
                    return client.put(`/api/app/control-card-types/${id}`, data);
                },

                create : (data : any) => {
                    return client.post(`/api/app/control-card-types`, data);
                },

                delete : (id : string) => {
                    return client.delete(`/api/app/control-card-types/${id}`);
                },
            }

        },


        dataWarehouse : {
            dataTypes : {
                list : () => {
                    return client.get('/api/app/data-types');
                },

                get : (id : string) => {
                    return client.get(`/api/app/data-types/${id}`);
                },

                delete : (id : string) => {
                    return client.delete(`/api/app/data-types/${id}`);
                },

                update : (id : string, data : any) => {
                    return client.put(`/api/app/data-types/${id}`, data);
                },

                create : (data : any) => {
                    return client.post(`/api/app/data-types`, data);
                }
            },

            unitTypes : {
                list : () => {
                    return client.get('/api/app/unit-type');
                },

                get : (id : string) => {
                    return client.get(`/api/app/unit-type/${id}`);
                },

                delete : (id : string) => {
                    return client.delete(`/api/app/unit-type/${id}`);
                },

                update : (id : string, data : any) => {
                    return client.put(`/api/app/unit-type/${id}`, data);
                },

                create : (data : any) => {
                    return client.post(`/api/app/unit-type`, data);
                }
            },

            formTypes : {
                list : () => {
                    return client.get('/api/app/form-templates');
                },

                get : (id : string) => {
                    return client.get(`/api/app/form-templates/${id}`);
                },

                delete : (id : string) => {
                    return client.delete(`/api/app/form-templates/${id}`);
                },

                update : (id : string, data : any) => {
                    return client.put(`/api/app/form-templates/${id}`, data);
                },

                create : (data : any) => {
                    return client.post(`/api/app/form-templates`, data);
                }
            }
        }
    },
}


export default api;