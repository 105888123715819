import { navigate } from "@reach/router";
import React, { forwardRef, SyntheticEvent, useEffect, useMemo, useRef, useState } from "react";
import {
  Autocomplete,
  Badge,
  Button,
  Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Popover,
  TextField,
  Tooltip, Typography
} from "@mui/material";
import {
  Download,
  Filter,
  Key as KeyIcon,
  MoreVertical, Move,
  Plus,
  Settings,
  Trash as TrashIcon,
  XCircle
} from "react-feather";
import { useGetApiData, useGetDataFromApi } from "../../OmniData/DataAccess";
import DataTable from "./DataTable";
import apiEndpoints from "../../OmniData/DataAccess/ApiEndpoints";
import RowActionsMenu from "./RowActionsMenu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SearchInput from "../../Components/SearchInput";
import api from "../../Api";
import _ from "lodash";
import { useSnackbar } from "notistack";
import TextFilter from "./Filter/TextFilter";
import DateFilter from "./Filter/DateFilter";
import BoolFilter from "./Filter/BoolFilter";
import EnumFilter from "./Filter/EnumFilter";
import NumberFilter from "./Filter/NumberFilter";
import Menu from "@mui/material/Menu";
import AppButton from "../../Components/Button";
import SearchableSelectField from "../Forms/Fields/SearchableSelectField";

import {
  DndContext,
  DragOverlay,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,

// @ts-ignore
} from '@dnd-kit/core';

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
// @ts-ignore
} from '@dnd-kit/sortable';

// @ts-ignore
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

export interface ListDataViewerEndpoints {
  base : string;
}


export interface ListDataViewerPaths {
  base ?: string;
}

export interface RowActionsMenuOptions {
  disabled ?: boolean;
}

export interface ListDataViewerProps
{
  endpoints : ListDataViewerEndpoints;
  paths? : ListDataViewerPaths;
  columns ?: any;
  refreshToken ?: any;
  disablePagination ?: boolean;
  disableFilters ?: boolean;
  disableMultipleRowsSelect ?: boolean;
  rowActionsMenuOptions ?: RowActionsMenuOptions;
  onRowDoubleClick ?: (row : any, index : number, event : any) => void;
  usePost ?: boolean;

  simpleSearchEnabled ?: boolean;
  searchIndexEnabled ?: boolean;
  exportEnabled ?: boolean;
  settingsEnabled ?: boolean;
}

const getRowId = (data : any) => {
  if(data.id) {
    return data.id;
  }

  if(data.Id) {
    return data.Id;
  }

  return "-no-id-";
}

const FiltersPanel = (props : any) => {
  const [search, setSearch] = useState<string>("");

  const [openedFilterName, setOpenedFilterName] = useState<string | undefined>(undefined);

  const availableFilters : any[] = props.availableFilters;

  const activeFilters : any[] =  props.activeFilters ?? [];

  const visibleFilters : any[] = availableFilters.filter(filter => {
    return filter.label.toLowerCase().includes(search.toLowerCase())
  });

  const openedFilter = availableFilters.find(filter => filter.name == openedFilterName);

  const openedFilterActiveData = activeFilters.find(filter => filter.name == openedFilterName);


  const isFilterActive = (filter : any) => {
    return activeFilters.some(active => active.name == filter.name);
  };

  const activateFilter = (id : any, name : any,  value : any) => {
    var filters = activeFilters;
    const existingFilter = filters.find(filter => filter.name == name);

    if(existingFilter) {
      existingFilter.value = value;
    } else {
      filters = [
          ...filters,
          {
            id : id,
            name : name,
            value : value
          }
      ];
    }

    // Only filters with value
    filters = filters.filter(filter => filter.value);

    props?.onActiveFiltersChange([...filters]);
  }

  const deactivateFilter = (name : any) => {
    if(openedFilter && openedFilter.name == name) {
      setOpenedFilterName(undefined);
    }

    props?.onActiveFiltersChange([
      ...activeFilters.filter(active => active.name != name)
    ]);
  }


  var openedFilterContent : any = '';

  if(openedFilter) {
    const openedFilterAvailableValues = openedFilter?.properties?.availableValues ?? [];
    const hasAvailableValues = openedFilterAvailableValues.length > 0;

    if(openedFilter.dataType == "Enum" || openedFilter.dataType == "Entity" || hasAvailableValues) {
      openedFilterContent = <EnumFilter
          key={openedFilter.id}
          label={openedFilter.label}
          id={openedFilter.id}
          currentValue={openedFilterActiveData?.value}
          availableValues={openedFilterAvailableValues}
          onChange={(newValue : any) => activateFilter(openedFilter.id, openedFilter.name, newValue)}
      />
    } else if(openedFilter.dataType == "Text") {
      openedFilterContent = <TextFilter
        key={openedFilter.id}
        label={openedFilter.label}
        id={openedFilter.id}
        currentValue={openedFilterActiveData?.value}
        onChange={(newValue : any) => activateFilter(openedFilter.id, openedFilter.name, newValue)}
      />
    } else if(openedFilter.dataType == "NumberFloat" || openedFilter.dataType == "NumberInteger") {
      openedFilterContent = <NumberFilter
          key={openedFilter.id}
          label={openedFilter.label}
          id={openedFilter.id}
          currentValue={openedFilterActiveData?.value}
          onChange={(newValue : any) => activateFilter(openedFilter.id, openedFilter.name, newValue)}
      />
    } else if(openedFilter.dataType == "DateTime") {
      openedFilterContent = <DateFilter
          key={openedFilter.id}
          label={openedFilter.label}
          id={openedFilter.id}
          currentValue={openedFilterActiveData?.value}
          onChange={(newValue : any) => activateFilter(openedFilter.id, openedFilter.name, newValue)}
      />
    } else if(openedFilter.dataType == "Bool") {
      openedFilterContent = <BoolFilter
          key={openedFilter.id}
          label={openedFilter.label}
          id={openedFilter.id}
          currentValue={openedFilterActiveData?.value}
          onChange={(newValue : any) => activateFilter(openedFilter.id, openedFilter.name, newValue)}
      />
    }

  }

  var lastRenderedGroup : any = undefined;

  return (
    <div style={{  display : "flex" }}>
      <div style={{ width : "300px", marginRight : "12px", paddingRight : "12px", borderRight : "1px solid rgba(224, 224, 224, 1)" }}>
        <div>
          <TextField
            type="search"
            size="small"
            label="Wyszukaj filtr"
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
            sx={{
              width : "100%",

              "& .MuiInputLabel-root" : {
                fontSize : "13px",
              },

              "& .MuiInputLabel-root:not(.Mui-focused):not(.MuiFormLabel-filled)" : {
                transform: "translate(10px, 5px) scale(1)"
              },

              "& .MuiInputLabel-root.Mui-focused, & .MuiInputLabel-root.MuiFormLabel-filled" : {
                transform: "translate(12px, -7px) scale(0.75)"
              },

              "& .MuiOutlinedInput-root" : {
                fontSize : "13px",
                lineHeight : "16px",
              },

              "& .MuiInputBase-inputSizeSmall" : {
                padding : "6px 10px",
                height : "auto"
              },

              "& .MuiOutlinedInput-notchedOutline" : {
                padding : "0 6px"
              }
            }}
          />
        </div>
        <div style={{ maxHeight : "350px", overflowY : "auto" }}>
          <List sx={{ width: '100%', maxWidth: 400, bgcolor: 'background.paper' }}>
            {visibleFilters.map((filter : any) => {
              const deleteButton = (
                <IconButton onClick={() => deactivateFilter(filter.name)} edge="end" color="error" aria-label="comments">
                  <TrashIcon size={16} />
                </IconButton>
              );

              return (
                <ListItem
                  key={filter.name}
                  secondaryAction={isFilterActive(filter) ? deleteButton : undefined}
                  disablePadding
                >
                  <ListItemButton sx={{ paddingLeft : "4px", paddingRight : "4px" }} dense onClick={() => setOpenedFilterName(filter.name)}>
                    <ListItemText primary={`${filter.label}`} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </div>

      </div>

      {openedFilter && (
        <div style={{ width : "240px" }}>
          <div style={{
            marginBottom : "8px",
            borderBottom : "1px solid rgba(224, 224, 224, 1)",
            minHeight : 28,
            display : "flex",
            alignItems : "center"
          }}>
            <Typography variant={"body2"}>{openedFilter?.label}</Typography>
          </div>
          <div>
            {openedFilterContent}
          </div>
        </div>
      )}
    </div>
  );
}

const getCellData = (source : any, path : any) => {
  let value = null;

  value = _.get(source, path);

  if(value === undefined) {
    value = null;
  }

  return value;
}

const renderCellData = (data : any, options ?: any) => {
  const dataType = options?.dataType.toString().toLowerCase();

  if(Array.isArray(data)) {
    let aggr = "";

    data.forEach(dataElem => {
      const dataElemValue = renderCellData(dataElem, options);
      aggr += dataElemValue;
    });

    return aggr;
  }

  if(!data || data == "" || data === undefined) {
    return "-";
  }

  if(dataType == "datetime") {
    return (new Date(data)).toLocaleString();
  }

  if(dataType == "boolean" || dataType == "bool") {
    return data ? "Tak" : "Nie";
  }

  return data.toString();
}

interface DataListSettingsProps
{
  open : boolean;
  onClose : () => void;
  inProgress ?: boolean;
  onSave : (data : any) => void;
  availableColumns : { [index : string] : { id : string; label : string; }}
  selectedColumns : string[];
}

const DataListSettings = (props : DataListSettingsProps) => {
  const [autocompleteInputValue, setAutocompleteInputValue] = useState<string | undefined>("");
  const [selectedColumns, setSelectedColumns] = useState<string[]>(props.selectedColumns.filter(id => props.availableColumns?.[id]));
  const [activeId, setActiveId] = useState(null);

  const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
  );

  const onSave = (e : any) => props.onSave(selectedColumns);

  const removeCol = (id : string) => {
    setSelectedColumns([
      ...selectedColumns.filter(selectedCol => selectedCol != id),
    ]);
  }

  const handleDragStart = (event : any) => {
    const {active} = event;
    setActiveId(active.id);
  }

  const handleDragEnd = (event : any) => {
    const {active, over} = event;

    if (active.id !== over.id) {
      setSelectedColumns((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }

    setActiveId(null);
  }

  const availableColumnsArray = _.map(props.availableColumns, (col) => col)
      .filter(col => !selectedColumns.includes(col.id));

  const ColumnItem = ({ id, listeners, isDragged } : any) => {
    const style = {
      marginTop : 8,
      border : "1px solid rgba(0, 0, 0, 0.23)"
    };

    const innerStyle : React.CSSProperties = {
      display : "flex",
      alignItems : "center",
      borderRadius : "4px",
    };

    if(isDragged) {
      innerStyle.opacity = "0";
      style.border = "1px dashed  rgba(0, 0, 0, 0.23)";
    }

    return <div style={style}>
      <div style={innerStyle}>
        <IconButton sx={{ marginRight: '8px' }} {...listeners}>
          <Move />
        </IconButton>
        <div>{props.availableColumns?.[id]?.label}</div>
        <IconButton color="error" sx={{ marginLeft: 'auto' }} onClick={() => removeCol(id)}>
          <TrashIcon />
        </IconButton>
      </div>
    </div>
  }

  const SortableItem = (itemProps : any) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
    } = useSortable({id: itemProps.id});

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    return (
      <div ref={setNodeRef} style={style} {...attributes} >
        <ColumnItem id={itemProps?.id} listeners={listeners} isDragged={itemProps?.id == itemProps?.activeId}/>
      </div>
    );
  }

  const CurrentItem = forwardRef(({id, ...props} : any, ref) => {
    return (
      <div {...props} ref={ref}>
        <ColumnItem id={id} />
      </div>
    )
  });

  return (
      <Dialog
        open={props.open}
        onClose={props.onClose}
      >
        <DialogTitle>{"Ustawienia"}</DialogTitle>
        <DialogContent>
          <div style={{ overflowX : "hidden", maxHeight : 300 }}>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={selectedColumns}
                strategy={verticalListSortingStrategy}
              >
                {selectedColumns.map(id => (<SortableItem key={id} id={id} activeId={activeId} />))}
              </SortableContext>
              <DragOverlay>
                {activeId ? <CurrentItem id={activeId} /> : null}
              </DragOverlay>
            </DndContext>
          </div>

          <div style={{ display : "flex", alignItems : "center", marginTop : 24 }}>
            <Autocomplete
                value={null}
                inputValue={autocompleteInputValue}
                onInputChange={(ev, v) => setAutocompleteInputValue(v)}
                onChange={(event, newValue) => {
                  if(!newValue?.id) {
                    return;
                  }

                  setSelectedColumns([
                    ...selectedColumns,
                    newValue?.id
                  ])
                  setAutocompleteInputValue("");
                }}
                options={availableColumnsArray}
                sx={{ width: 500 }}
                renderInput={(params) => <TextField {...params} size="small" label="Kolumna" />}
            />
          </div>


        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            Anuluj
          </Button>
          <AppButton inProgress={props?.inProgress} onClick={onSave} color="primary" variant="contained" autoFocus>
            Zapisz
          </AppButton>
        </DialogActions>
      </Dialog>
  )
}

const ListDataViewer = (props : ListDataViewerProps) => {
  const resultsPerPage = 15;
  const [page, setPage] = useState(0);
  const [currentSortBy, setCurrentSortBy] = useState<{ id : string; direction : "asc" | "desc" }>();
  const [search, setSearch] = useState<string | undefined>();
  const { enqueueSnackbar } = useSnackbar();
  const [exportInProgress, setExportInProgress] = useState(false);
  const [settingsState, setSettingsState] = useState({
    open : false,
    inProgress : false,
    selectedColumns : [],
    key : Math.random()
  })

  const [tableData, setTableData] = useState<any[]>([]);
  const [tableDataTotalCount, setTableDataTotalCount] = useState<number>(0);

  const filtersEnabled = props.simpleSearchEnabled || props.searchIndexEnabled;
  const paginationEnabled = props.disablePagination !== true;
  const multipleRowsSelectEnabled = props.disableMultipleRowsSelect !== true;
  const exportEnabled = props.exportEnabled ?? false;
  const settingsEnabled = props.settingsEnabled ?? false;

  const [filtersPopoverAnchorEl, setFiltersPopoverAnchorEl] = React.useState(null);
  const [listActionsMenuAnchor, setListActionsMenuAnchor] = React.useState(null);

  const [activeFilters, setActiveFilters] = useState<any[]>([]);

  const searchControlRef = useRef<SearchInput>(null);

  const [columnsRefreshKey, setColumnsRefreshKey] = useState(Math.random());

  const { data : configurationData, revalidate : configurationDataRefresh } = useGetDataFromApi(props.searchIndexEnabled
      ? _.trimEnd(props.endpoints.base, "/") + "/configuration"
      : undefined
  );

  const dataEndpointUrl = props.searchIndexEnabled
      ? _.trimEnd(props.endpoints.base, "/") + "/search"
      : props.endpoints.base;

  const dataExportEndpointUrl = exportEnabled
      ? _.trimEnd(props.endpoints.base, "/") + "/export-list"
      : undefined;

  const { data, mutate, error, isLoading : dataIsLoading } = useGetDataFromApi(dataEndpointUrl,{
    skipCount : page * resultsPerPage,
    maxResultCount : resultsPerPage,
    sorting : !currentSortBy ? undefined : `${currentSortBy.id} ${currentSortBy.direction}`,
    search : search,
    filters : activeFilters,
    method : props.searchIndexEnabled ? "post" : "get",

    // Filter (other api/legacy)
    filter : search
  });

  let columnsUserPrefs = configurationData?.userPreferences?.columns ?? {};
  let selectedColumnIds : any[] = Object.keys(columnsUserPrefs);

  if(error) {
    const message = _.get(error, 'response.data.error.message', "Wystąpił błąd poczas pobierania danych.");
    enqueueSnackbar(message, {
      variant : "error"
    })
  }


  const deleteRow = async (rowData : any) => {
    try {
      const result = await api.getClient().delete(`${props.endpoints.base}/${getRowId(rowData)}`);
      await mutate();
      enqueueSnackbar("Usunięto rekord");
    } catch (e : any) {
      const error = _.get(e, 'response.data.error.message', e.message);
      enqueueSnackbar(error, {
        variant : "error"
      })
    }
  }


  const columns = useMemo(() => {
    let columns = (props.columns || []).map((column : any)=> {
      if(column?.type == "datetime" && !column?.Cell) {

        column.Cell = (data : any) => {
          var columnData = getCellData(data?.cell?.row?.original, column.accessor);

          if(!columnData) {
            return '-';
          }

          return (new Date(columnData)).toLocaleString();
        }

      } else if(column?.type == "boolean" && !column?.Cell) {

        column.Cell = (data : any) => {
          var columnData = getCellData(data?.cell?.row?.original, column.accessor);
          return columnData ? "Tak" : "Nie";
        }

      } else if(!column?.Cell) {
        column.Cell = (data : any) => {
          return getCellData(data?.cell?.row?.original, column.accessor);
        }
      }

      return column;
    });

    if(settingsEnabled && columnsUserPrefs && Object.keys(columnsUserPrefs).length > 0 && configurationData?.availableColumns) {

      let cols : any[] = [];

      _.each(columnsUserPrefs, (pref, colId) => {
        const columnProps = configurationData?.availableColumns?.[colId];

        if(!columnProps) {
          return;
        }

        cols.push({
          id : colId,
          accessor : colId,
          order : pref.order,
          Header : columnProps.label,
          Cell : (data : any) => {
            const columnData = getCellData(data?.cell?.row?.original?.fields, colId);
            return renderCellData(columnData, columnProps);
          },
        })

      });

      columns = _.sortBy(cols, [function(o) { return o.order; }]).reverse();
    }


    return [
        ...columns,
      {
        id : "actions",
        Header : "",
        disableSortBy : true,
        accessor : (data : any, index : any) => getRowId(data),
        Cell : (data : any) => {
          return (<div className={"tableCellActions"}>

            {!props?.rowActionsMenuOptions?.disabled && (
                <RowActionsMenu>
                  <MenuItem onClick={async (e) => {
                    var row = data?.cell?.row?.original;
                    await deleteRow(row);
                  }}>
                    <ListItemIcon>
                      <TrashIcon />
                    </ListItemIcon>
                    <ListItemText primary="Usuń" />
                  </MenuItem>
                </RowActionsMenu>
            )}
          </div>)
        }
      }
    ]
  }, [JSON.stringify(props.columns), JSON.stringify(columnsUserPrefs), JSON.stringify(configurationData?.availableColumns), columnsRefreshKey]);

  var availableFilters : any[] = [];

  if(configurationData && configurationData.availableListFilters) {
    availableFilters = configurationData.availableListFilters.map((filter : any) => ({
      id : filter.id,
      name : filter.name,
      label : filter?.extraProperties?.Label ?? filter.name,
      group : filter.group,
      controlType : filter.controlType,
      dataType : filter.dataType,
      value : undefined,
      isActive : false,
      properties : filter?.properties ?? {}
    }));


    availableFilters = _.sortBy(availableFilters, ["group", "label"]).reverse();
  }

  const filtersResetEnabled = activeFilters.length > 0 || search;

  const resetList = () => {
    searchControlRef?.current?.reset();
    setActiveFilters([]);
  }

  const hasToolbar = filtersEnabled || exportEnabled;

  const exportList = async () => {
    try {
      if(!dataExportEndpointUrl) {
        return;
      }

      setExportInProgress(true);

      const exportColumns : any[] = [];

      columns.forEach(column => {
        console.dir(column);

        if(_.isString(column.Header) && !_.isEmpty(column.Header) && _.isString(column.accessor)) {
          exportColumns.push({
            label : column.Header,
            property : (column.accessor as string)[0].toUpperCase() + (column.accessor as string).substring(1)
          })
        }
      });

      const request = await api.getClient().post(dataExportEndpointUrl, {
        searchParams : {
          sorting : !currentSortBy ? undefined : `${currentSortBy.id} ${currentSortBy.direction}`,
          search : search,
          filters : activeFilters,
        },

        columns : exportColumns,
      }, {
        responseType : "blob"
      });

      const url = window.URL.createObjectURL(request.data);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'omnidata-export-' + (new Date().toISOString()) + '.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

    } catch (e : any) {
      const error = _.get(e, 'response.data.error.message', e?.message);
      enqueueSnackbar(error, {
        variant : "error"
      })
    } finally {
      setExportInProgress(false);
    }

  };

  const onColumnsSave = async (columnsIds : any[]) => {
    try {
      const columnsPrefsInput : any = {};

      if(columnsIds) {
        columnsIds.forEach((id, index) => {
          columnsPrefsInput[id] = {
            order : 100 - index
          }
        });
      }

      const result = await api.getClient().post(`${props.endpoints.base}/save-list-preferences`, {
        columns : columnsPrefsInput
      });

      setSettingsState((prev) => ({...prev, open : false }))
      setListActionsMenuAnchor(null);
      await configurationDataRefresh();
    } catch (e : any) {
      const error = _.get(e, 'response.data.error.message', e.message);
      enqueueSnackbar(error, {
        variant : "error"
      })
    }

  }

  const openSettings = () => {
    setSettingsState((prev) => ({...prev, open : true, key : Math.random() }))
  }


  const itemsSerialized = JSON.stringify(data?.items);
  useEffect(() => {
    if(dataIsLoading) {
      return;
    }

    setTableData(data?.items ?? []);
    setTableDataTotalCount(parseInt(data?.totalCount ?? 0));
  }, [dataIsLoading, itemsSerialized])

  return (
      <>

        <Card style={{ marginTop: 12 }}>
          {hasToolbar && (
            <div style={{ padding : '8px 15px', display : "flex" }}>
              {filtersEnabled && (
                  <div style={{ display : "flex" }}>
                    <Tooltip title={availableFilters.length <= 0 ? "Brak dostępnych filtrów" : "Filtrowanie"}>
                      <span>
                        <IconButton
                          onClick={(e : any) => setFiltersPopoverAnchorEl(e.currentTarget)}
                          sx={{ marginRight : "16px" }}
                          color="primary"
                          disabled={availableFilters.length <= 0}
                        >
                        <Badge color="primary" badgeContent={activeFilters.length > 0 ? activeFilters.length : undefined}>
                          <Filter />
                        </Badge>
                      </IconButton>
                      </span>
                    </Tooltip>

                    <Popover
                      open={!!filtersPopoverAnchorEl}
                      anchorEl={filtersPopoverAnchorEl}
                      onClose={() => setFiltersPopoverAnchorEl(null)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      sx={{
                        ".MuiPaper-root" : {
                          padding : "8px 16px",
                        }
                      }}
                    >
                      <FiltersPanel
                        availableFilters={availableFilters}
                        activeFilters={activeFilters}
                        onActiveFiltersChange={(activeFilters : any[] ) => setActiveFilters(activeFilters)}
                      />
                    </Popover>

                    <SearchInput ref={searchControlRef} onChange={(v : string) => {setSearch(v)}} />

                    <Tooltip title={"Reset filtrów"}>
                      <span>
                        <IconButton
                            onClick={resetList}
                            color="primary"
                            disabled={!filtersResetEnabled}
                            sx={{ marginLeft : "16px" }}
                        >
                          <XCircle />
                      </IconButton>
                      </span>
                    </Tooltip>
                  </div>
                )}

                {(exportEnabled || settingsEnabled) && (
                  <div style={{ marginLeft : "auto" }}>
                    <IconButton
                      onClick={(e : any) => setListActionsMenuAnchor(e.currentTarget)}
                    >
                      <MoreVertical />
                    </IconButton>

                    <Popover
                      open={!!listActionsMenuAnchor}
                      anchorEl={listActionsMenuAnchor}
                      onClose={() => setListActionsMenuAnchor(null)}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      {settingsEnabled && (
                        <MenuItem onClick={openSettings}>
                          <ListItemIcon>
                            <Settings />
                          </ListItemIcon>
                          <ListItemText primary="Ustawienia" />
                        </MenuItem>
                      )}


                      {exportEnabled && (
                        <MenuItem onClick={exportList} disabled={exportInProgress}>
                          <ListItemIcon>
                            <Download />
                          </ListItemIcon>
                          <ListItemText primary="Eksport do pliku Excel" />
                        </MenuItem>
                      )}
                    </Popover>
                  </div>

                )}
            </div>
          )}

          {/* Settings */}
          <DataListSettings
            {...settingsState}
            availableColumns={configurationData?.availableColumns ?? {}}
            selectedColumns={selectedColumnIds}
            onClose={() => {
              setSettingsState((prev) => ({...prev, open : false }))
              setListActionsMenuAnchor(null)
            }}
            onSave={onColumnsSave}
          />

          <DataTable
            columns={columns}
            data={tableData}
            resultsCount={tableDataTotalCount}
            currentPage={tableDataTotalCount > 0 ? page : 0}
            resultsPerPage={resultsPerPage}
            disablePagination={props.disablePagination}
            disableMultipleRowsSelect={props.disableMultipleRowsSelect}
            onRowClick={async (row: any, index: any, e: any) => {
              const tagName = e.target.tagName.toUpperCase();

              if(tagName == "INPUT" || tagName == "BUTTON") {
                return;
              }

              // row.toggleRowSelected();
            }}
            onRowDoubleClick={async (row: any, index: any, e: any) => {
              const tagName = e.target.tagName.toUpperCase();

              if(tagName == "INPUT" || tagName == "BUTTON") {
                return;
              }

              if(props?.onRowDoubleClick) {
                await props.onRowDoubleClick(row, index, e);
                return;
              }

              if(props?.paths?.base) {
                await navigate(`${props.paths.base}/${getRowId(row?.original)}`);
              }
            }}
            onPageChange={(page) => {
              setPage(page);
            }}
            currentSortBy={currentSortBy ? [
              { id : currentSortBy.id, desc : currentSortBy.direction == "desc" }
            ] : []}
            onSortChange={(columnId, sortDirection) => {

              if(columnId && sortDirection) {
                setCurrentSortBy({
                  id : columnId,
                  direction : sortDirection
                })
              } else {
                setCurrentSortBy(undefined);
              }

            }}
          />
        </Card>
      </>
  )
};

export default ListDataViewer;