import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Card, CardContent, Divider, MenuItem, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Link, navigate, RouteComponentProps } from "@reach/router";
import DataGrid from "../../../Components/DataGrid/DataGrid";
import Breadcrumbs from "../../../Components/Breadcrumbs";
import _ from "lodash";
import api from "../../../Api";
import { Plus as PlusIcon, Trash as TrashIcon } from "react-feather";
import { useGetDataTypesAsList } from "../../../OmniData/DataAccess";
import SearchInput from "../../../Components/SearchInput";
import apiEndpoints from "../../../OmniData/DataAccess/ApiEndpoints";
import ListDataViewer from "../../../Core/ListDataViewer/ListDataViewer";

const NavigationLink = React.forwardRef((props: any, ref) => {
    return (
        <Link to={props.href} ref={ref} {...props}>{props.children}</Link>
    );
});

export function List(props: RouteComponentProps) {

    return (
        <>
            <Breadcrumbs title="Typy danych" />

            <Button
                component={NavigationLink}
                variant="contained"
                color="primary"
                startIcon={<PlusIcon>send</PlusIcon>}
                href="/data-warehouse/data-types/create"
            >
                Dodaj
            </Button>

          <ListDataViewer
            searchIndexEnabled
            exportEnabled
            endpoints={{
              base : apiEndpoints.dataWarehouse.dataTypes.list()
            }}
            paths={{
              base : `/data-warehouse/data-types`
            }}
            columns={[
              {
                Header: 'Nazwa',
                accessor: 'DisplayName',
              },

              {
                Header: 'Typ kontrolki',
                accessor: 'ControlType',
                Cell : ({ value } : any) => {
                  if(value == "text")
                    return "Pole tekstowe";

                  if(value == "checkbox")
                    return "Checkbox";

                  if(value == "select")
                    return "Pole wyboru";

                  if(value == "time")
                    return "Czas";

                  if(value == "datetime")
                    return "Kalendarz i zegar";

                  if(value == "date")
                    return "Kalendarz";

                  if(value == "time")
                    return "Zegar";

                  if(value == "contentDisplay")
                    return "Brak kontrolki - wartość opisowa"

                  return null;
                }
              },

              {
                Header: 'Typ składowanych danych',
                accessor: 'StoredValueType',
                Cell : ({ value } : any) => {
                  if(value == "text")
                    return "Tekst";

                  if(value == "number_integer")
                    return "Liczba całkowita";

                  if(value == "number_float")
                    return "Liczba zmiennoprzecinkowa";

                  if(value == "bool")
                    return "Wartość logiczna (prawda/fałsz)";

                  if(value == "datetime")
                    return "Data i czas";

                  return null;
                }
              },


              {
                Header: 'Jednostka',
                accessor: 'StoredValueUnitType.Name',
              },

              {
                Header : 'Data modyfikacji',
                accessor: 'LastModificationTime',
                type : 'datetime'
              },

              {
                Header : 'Data utworzenia',
                accessor: 'CreationTime',
                type : 'datetime'
              },
            ]}
          />
        </>
    );
}

export default React.memo(List);