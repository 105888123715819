import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
  Button as MuiButton,
  Card,
  CardActions,
  CardContent,
  Divider,
  ExtendButtonBase,
  List,
  ListItem as MuiListItem,
  ListItemTypeMap,
  Paper,
  Tab,
  Tabs,
  Typography,
  Link as MuiLink,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  IconButton, AccordionDetails,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Link, navigate, useParams } from "@reach/router";
import React, { useCallback, useEffect, useState } from "react";
import {
  Plus as PlusIcon,
  ChevronRight as ChevronRightIcon,
  Save as SaveIcon,
  Trash as TrashIcon, Trash, Trash as DeleteIcon
} from "react-feather";
import Breadcrumbs from "../../../Components/Breadcrumbs";
import Button from "../../../Components/Button";
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form";
import _ from "lodash";
import api from "../../../Api";
import { mergeWithDefaultData } from "../../../Common/Utils";
import {
  useGetApiData,
  useGetControlCardGroupsList,
  useGetControlCardTypesList,
  useGetSingleControlCardTemplate
} from "../../../OmniData/DataAccess";
import { useSnackbar } from "notistack";
import ElementsList from "../../../Components/ControlCards/Templates/ElementsList";
import makeStyles from '@mui/styles/makeStyles';
import { Autocomplete } from '@mui/material';
import ExpandableList from "../../../Core/ExpandableList/ExpandableList";
import apiEndpoints from "../../../OmniData/DataAccess/ApiEndpoints";
import ReactQuill from "react-quill";


const PREFIX = 'CreateUpdate';

const classes = {
  option: `${PREFIX}-option`
};


const useAssignmentSelectStyles = makeStyles({
  [`& .${classes.option}`]: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 10,
      display : "inline-block",
      minWidth : 50,
      textAlign : "right"
    },
  },
});


const AssignmentSelect = (props : any) => {
  const classes = useAssignmentSelectStyles();
  const options = props?.options || [];

  let value = null;
  if(props.value) {
    let exists = options.find(
        (option : any) => option.type == props.value.type && option.value == props.value.value
    );

    if(exists) {
      value = props.value;
    }
  }

  const getOptionData = (type : string, value : string) => {
    return (options as any[]).find((item) => {
      return item && item.type == type && item.value == value;
    });
  }

  return (
      <Autocomplete
          style={{ width: '100%' }}
          options={options}
          size="small"
          classes={{
            option: classes.option,
          }}
          getOptionLabel={(value) => {
            var option = getOptionData(value.type, value.value);
            return `${option.typeLabel}: ${option.valueLabel}`;
          }}
          value={value}
          onChange={(ev,value) => {
            props?.onChange && props.onChange({
              value : value.value,
              type : value.type
            });
          }}
          isOptionEqualToValue={(option, value) => {
            if(option && option.type == value.type && option.value == value.value) {
              return true;
            }

            return false;
          }}
          renderInput={(params) => (
              <TextField
                  {...params}
                  label="Wybierz rolę/osobę"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    "aria-autocomplete" : "none",
                    "autofill" : "none",
                    autoComplete: 'off', // disable autocomplete and autofill
                  }}
              />
          )}
      />
  );
}

const AssignmentList = (props : any) => {
  const options = props.options || [];
  const form = props.form;
  const assignments = useFieldArray({
    name : `${props.formPath}.assignments`,
    control : form.control
  });

  return <>
    {assignments.fields.map((field:any, index : any) => (
        <div key={field.id} style={{ display : "flex", alignItems : "center", marginBottom : 5 }}>
          <Controller
              render={(el) => {
                return (
                    <AssignmentSelect
                        options={options}
                        value={el?.field?.value}
                        onChange={(value : any) => form.setValue(`${props.formPath}.assignments.${index}`, value)}
                    />
                )
              }}
              defaultValue={field}
              control={form.control}
              name={`${props.formPath}.assignments.${index}`}
          />
          <IconButton
              color="error"
              onClick={() => {
                assignments.remove(index);
              }}
              size="large">
            <TrashIcon />
          </IconButton>
        </div>
    ))}

    <MuiButton
        variant="contained"
        color="primary"
        startIcon={<PlusIcon>send</PlusIcon>}
        type="button"
        size="small"
        onClick={(e) => {
          e.preventDefault();
          assignments.append({
            type : "",
            value : ""
          });
        }}
    >
      Dodaj
    </MuiButton>
  </>;
}

const ApprovalSelect = (props : any) => {

  const options = props?.options || [];

  let value = null;
  if(props.value) {
    let exists = options.find(
        (option : any) => option.type == props.value.type && option.value == props.value.value
    );

    if(exists) {
      value = props.value;
    }
  }

  const getOptionData = (type : string, value : string) => {
    return (options as any[]).find((item) => {
      return item && item.type == type && item.value == value;
    });
  }

  return (
    <Autocomplete
      style={{ width: 590 }}
      options={options}
      size="small"
      classes={{
        option: classes.option,
      }}
      getOptionLabel={(value) => {
        var option = getOptionData(value.type, value.value);
        return `${option.typeLabel}: ${option.valueLabel}`;
      }}
      value={value}
      onChange={(ev,value) => {
        props?.onChange && props.onChange({
          value : value.value,
          type : value.type
        });
      }}
      isOptionEqualToValue={(option, value) => {
        if(option && option.type == value.type && option.value == value.value) {
          return true;
        }

        return false;
      }}
      renderInput={(params) => (
          <TextField
              {...params}
              label="Wybierz rolę/osobę"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                "aria-autocomplete" : "none",
                "autofill" : "none",
                autoComplete: 'off', // disable autocomplete and autofill
              }}
          />
      )}
    />
  );
}

const generateElementData = () => {
  return {
    key : `el-${Math.random()}`,
    name: 'element',
    description : "",
    'new' : true
  };
};



const TemplateElement = (props: any) => {
  const availableFormTypesData = useGetApiData(apiEndpoints.dataWarehouse.formTemplates.list(), {
    maxResultCount : 1000
  });
  let availableFormTypes : any[] = [];

  if(availableFormTypesData?.data?.items) {
    availableFormTypes = availableFormTypesData.data.items.map((item : any) => {
      return {
        value : item.id,
        label : item.name
      }
    });
  }

  const getFieldName = (field : string) => {
    return `${props.formKey}.${field}`;
  };

  const setFormValue = (key: string, value: any) => {
    props.setFormValue(`${props.formKey}.${key}`, value);
  };

  const getFormValue = (key: string) => {
    return props.getFormValue(`${props.formKey}.${key}`);
  };

  const refreshName = useCallback(_.debounce((v) => {
    props?.onNameChange(v);
  }, 100), []);

  const assignmentOptions = props?.formData?.availableAssignmentOptions || [];

  const quillModules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      ['image'],
      ['clean']
    ],
  };

  return (
      <Grid container spacing={1}>
        <Grid container item>
          <Grid item xs={4} lg={5}>
            <TextField
                label="Nazwa"
                variant="outlined"
                size="small"
                fullWidth
                defaultValue={getFormValue('name')}
                onChange={(e) => {
                  setFormValue('name', e.target.value);
                  refreshName(e.target.value);
                }}
            />
          </Grid>
        </Grid>

        <Grid container item>
          <Grid item lg={5}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel>Formularz danych</InputLabel>
              <Controller
                  render={
                    ({ field }) =>
                        <Select
                            labelId="demo-simple-select-label"
                            label="Formularz danych"
                            {...field}
                            value={field.value || ""}
                        >
                          {(availableFormTypes || []).map((item : any) => (
                              <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                          ))}
                        </Select>
                  }
                  control={props.form.control}
                  name={getFieldName("formTemplateId")}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container item>
          <Grid item xs={4} lg={5}>
              <Typography variant="body1">Opis</Typography>
              <Controller
                  render={
                    ({ field }) =>
                        <ReactQuill
                          {...field}
                          theme="snow"
                          modules={quillModules}
                        />
                  }
                  control={props.form.control}
                  name={getFieldName("description")}
              />
          </Grid>
        </Grid>


        <Grid container item>
          <Grid item lg={5} sx={{ padding : '8px', paddingTop : '4px', border : '1px solid rgba(0, 0, 0, 0.23)', borderRadius : '4px' }}>
            <Grid container item>
              <Grid item lg={12}>
                <Typography variant="h6" style={{ fontWeight: 400, marginTop : 0, fontSize : 16 }}>Ustawienia powtarzania</Typography>
              </Grid>
            </Grid>

            <Grid container item>
              <Grid item lg={12}>
                <Controller
                    render={
                      ({ field }) =>
                          <FormControlLabel
                              control={
                                <Checkbox
                                    {...field}
                                    checked={!!field.value}
                                    color="primary"
                                />
                              }
                              label={"Element powtarzalny"}
                          />
                    }
                    control={props.form.control}
                    name={getFieldName("repetitionSettings.isRepeatable")}
                />

                <div style={{ marginTop : "8px" }}>
                  <TextField
                      label="Min. powtórzeń (opcjonalnie)"
                      variant="outlined"
                      size="small"
                      type="number"
                      sx={{ width : "200px", marginRight : "8px" }}
                      defaultValue={getFormValue('repetitionSettings.minRepetitions')}
                      onChange={(e) => {
                        const v = parseInt(e.target.value);
                        setFormValue('repetitionSettings.minRepetitions', !isNaN(v) ? v : null);
                      }}
                  />

                  <TextField
                      label="Max. powtórzeń (opcjonalnie)"
                      variant="outlined"
                      size="small"
                      type="number"
                      sx={{ width : "200px" }}
                      defaultValue={getFormValue('repetitionSettings.maxRepetitions')}
                      onChange={(e) => {
                        const v = parseInt(e.target.value);
                        setFormValue('repetitionSettings.maxRepetitions', !isNaN(v) ? v : null);
                      }}
                  />
                </div>

              </Grid>
            </Grid>

          </Grid>
        </Grid>




        <Grid container item>
          <Grid item lg={5}>
            <Typography variant="h6" style={{ fontWeight: 400, marginTop : 16, fontSize : 16 }}>Przeznaczone dla</Typography>
          </Grid>
        </Grid>

        <Grid container item>
          <Grid item lg={5}>
            {/* Przeznaczone dla */}
            <AssignmentList form={props.form} formPath={props.formKey} options={assignmentOptions} />
          </Grid>
        </Grid>

      </Grid>
  );
}



const MainForm = (props: any) => {
  const [inProgress, setInProgress] = useState(false);
  const form = useForm();
  const [formData, setFormData] = useState<any>({...props.currentData});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { data : manageData } = useGetApiData("/api/app/control-cards-templates/form-data")

  const approvalsField = useFieldArray({
    control : form.control,
    name : "approvals"
  })

  // Groups
  const cardGroups = useGetControlCardGroupsList();
  const cardGroupsOptions = cardGroups.data.map((item : any) => {
    return {
      value : item.id,
      label : item.name
    }
  });

  // Types
  const cardTypes = useGetControlCardTypesList();
  const cardTypesOptions = cardTypes.data.map((item : any) => {
    return {
      value : item.id,
      label : item.name
    }
  });


  useEffect(() => {
    var data = mergeWithDefaultData({
        name: null,
        elements : [],
      },
      props.currentData
    );

    form.reset(data);
    setFormData({ ...data })
  }, [props.currentData]);

  const onSubmit = async (data: any) => {
    setInProgress(true);
    try {
      if (props.currentData && props.currentData.id) {
        await api.app.controlCards.templates.update(props.currentData.id, data);
        enqueueSnackbar('Zmiany zapisane');
      } else {
        await api.app.controlCards.templates.create(data);
        await navigate("/control-cards/templates");
      }

    } catch (e : any) {
      var error = _.get(e, 'response.data.error.details', e.message);
      alert(error);
      console.error(e);
    } finally {
      setInProgress(false);
    }
  };

  const getFormValue = (key: string) => {
    return form.getValues(key) ?? _.get(props.currentData, key);
  };

  const setFormValue = (key: string, value: any) => {
    if (value == 'append') {

    } else if (value == 'remove') {
      key = key.replace('.root', '');
      _.set(formData, key, undefined);
      form.unregister(key);
      formData.elements = _.filter(formData && formData.elements ? formData.elements : []);
      setFormData({ ...formData });
    } else {
      form.setValue(key, value);
    }
  };

  const elementsField = useFieldArray({
    control : form.control,
    name : "elements",
    keyName : "_key"
  });

  const [names, setNames] = useState<any>({});

  const [expandedItems, setExpandedItems] = useState<any>({});

  return (
    <form noValidate onSubmit={form.handleSubmit(onSubmit)}>

      <Typography variant="h6" style={{fontWeight: 400}}>Dane podstawowe</Typography>

      <Paper style={{padding: 16}} elevation={1}>
        <Grid container spacing={1}>
          <Grid container item spacing={3}>

            <Grid item xs={3}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel>Typ karty</InputLabel>
                <Controller
                    render={
                      ({ field }) =>
                          <Select
                              labelId="demo-simple-select-label"
                              label="Typ karty"
                              {...field}
                              value={field.value || ""}
                          >
                            {(cardTypesOptions || []).map((item : any) => (
                                <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                            ))}
                          </Select>
                    }
                    control={form.control}
                    name={"controlCardTypeId"}
                />
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel>Grupa karty</InputLabel>
                <Controller
                    render={
                      ({ field }) =>
                          <Select
                              labelId="demo-simple-select-label"
                              label="Grupa karty"
                              {...field}
                              value={field.value || ""}
                          >
                            {(cardGroupsOptions || []).map((item : any) => (
                                <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                            ))}
                          </Select>
                    }
                    control={form.control}
                    name={"controlCardGroupId"}
                />
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <TextField
                  label="Nazwa / wersja"
                  id="outlined-size-small"
                  variant="outlined"
                  size="small"
                  fullWidth
                  defaultValue={getFormValue('name')}
                  key={getFormValue('name')}
                  onChange={(e) => {
                    form.setValue("name", e.target.value);
                  }}
              />
            </Grid>

            <Grid item xs={3}>
              <Controller
                  render={
                    ({ field }) =>
                        <FormControlLabel
                            control={
                              <Checkbox
                                  {...field}
                                  checked={!!field.value}
                                  color="primary"
                              />
                            }
                            label={"Wersja obowiązująca"}
                        />
                  }
                  control={form.control}
                  name={"isCurrentVersion"}
              />
            </Grid>

          </Grid>
        </Grid>

      </Paper>

      <Typography variant="h6" style={{ fontWeight: 400, marginTop : 16 }}>Elementy</Typography>

      <ExpandableList
        onItemDelete={(i, key : any) => {
          elementsField.remove(i);
        }}
        items={(elementsField.fields || [])}
        onItemSorted={(oldIndex, newIndex) => {
          elementsField.move(oldIndex,  newIndex)
        }}
        sortable
        expandedItems={expandedItems}
        onExpandedItemsChange={(itemId, expanded) => {
          setExpandedItems({
            ...expandedItems,
            [itemId] : expanded
          })
        }}
        renderTitle={(item : any, index : any) => names?.[index] != undefined ? names?.[index] : (item?.name ?? "") }
        renderItem={(item : any, index : any) => {
          return <TemplateElement
            listController={elementsField}
            elements={(elementsField.fields || [])}
            formKey={`elements.${index}`}
            getFormValue={getFormValue}
            setFormValue={setFormValue}
            form={form}
            formData={manageData}
            onNameChange={(name : string) => {
              setNames({
                ...names,
                [index] : name
              });
            }}
          />
        }}
      />

      <MuiButton
          variant="contained"
          color="primary"
          startIcon={<PlusIcon>send</PlusIcon>}
          type="button"
          size="small"
          onClick={(e) => {
            e.preventDefault();
            elementsField.append(generateElementData());

            setExpandedItems((old : any) => ({
              ...old,
              [elementsField.fields.length] : true
            }));
          }}
      >
        Nowy element
      </MuiButton>

      <Typography variant="h6" style={{ fontWeight: 400, marginTop : 16 }}>Zatwierdzanie</Typography>

      <Paper style={{padding: 16}} elevation={1}>
        <Grid container spacing={1}>
          <Grid container item spacing={3}>
            <Grid item xs={3}>
              <Controller
                  render={
                    ({ field }) =>
                      <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              checked={!!field.value}
                              color="primary"
                            />
                          }
                          label={"Kolejność zatwierdzania wymagana"}
                      />
                  }
                  control={form.control}
                  name={"approvalOrderRequired"}
              />
            </Grid>
          </Grid>

          <Grid container item spacing={3}>
            <Grid item xs={12}>

              {approvalsField.fields.map((field:any, index) => (
                  <div key={field.id} style={{ display : "flex", alignItems : "center", marginBottom : 5 }}>
                    <Controller
                        render={(el) => {
                          return (
                              <ApprovalSelect
                                  options={manageData?.availableApprovalOptions || []}
                                  value={el?.field?.value}
                                  onChange={(value : any) => form.setValue(`approvals.${index}`, value)}
                              />
                          )
                        }}
                        defaultValue={field}
                        control={form.control}
                        name={`approvals.${index}`}
                    />
                    <IconButton
                      color="error"
                      onClick={() => {
                        approvalsField.remove(index);
                      }}
                      size="large">
                      <TrashIcon />
                    </IconButton>
                  </div>

              ))}

            </Grid>
          </Grid>

          <Grid container item spacing={3}>
            <Grid item xs={12}>

              <MuiButton
                  variant="contained"
                  color="primary"
                  startIcon={<PlusIcon>send</PlusIcon>}
                  type="button"
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    approvalsField.append({
                      type : "",
                      value : ""
                    });
                  }}
              >
                Dodaj
              </MuiButton>

            </Grid>
          </Grid>


        </Grid>

      </Paper>

      <Divider style={{marginTop: 24, marginBottom: 8}}/>

      <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon size={22}/>}
          type="submit"
          inProgress={inProgress}
      >
        Zapisz
      </Button>

    </form>
  );
};


const UpdateView = ({ id } : any) => {
  const { data } = useGetSingleControlCardTemplate(id);
  return (
      <>
        <Breadcrumbs title={"Aktualizacja"}/>
        <MainForm currentData={data}/>
      </>
  );
};


const CreateView = ({ id } : any) => {
  return (
      <>
        <Breadcrumbs title={"Tworzenie"}/>
        <MainForm currentData={null}/>
      </>
  );
};


const StyledCreateView = styled(CreateView)({
  [`& .${classes.option}`]: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 10,
      display : "inline-block",
      minWidth : 50,
      textAlign : "right"
    },
  },
});


export default function CreateUpdate(props: any) {
  const params = useParams();

  return props.mode == "update" && params.id
    ? <UpdateView id={params.id} />
    : <StyledCreateView />;
}