import _ from "lodash";

export const mergeWithDefaultData = (defaultData : any, sourceData : any) => {
  return _.mergeWith(
      defaultData,
      sourceData,
      (currentValue : any, newValue : any, src : any) => {
        if(currentValue === null || currentValue === undefined) {
          return newValue;
        }

        if((newValue === null || newValue === undefined) && typeof currentValue === "object") {
          return currentValue;
        }

        return newValue;
      }
  )
}