import React from "react";
import { useForm, Controller, UseFormReturn, useFormContext } from "react-hook-form";
import { TextField as MuiTextField } from "@mui/material";
import { TextFieldProps as MuiTextFieldProps } from "@mui/material/TextField/TextField";
import _ from "lodash";

export interface TextFieldProps
{
  name : string;
  label ?: string;
  type ?: "text" | "password" | "email" | "number";
  defaultValue ?: number | string;
  form ?: UseFormReturn;
  controlProps ?: MuiTextFieldProps;
}

const ensureValidValue = (value : any) => {
  if(value === undefined || value === null)
    return "";

  return value;
}

const TextField = (props : TextFieldProps) => {
  const formContext = useFormContext();
  const form = props.form ? props.form : formContext;
  const name = props.name;

  const controlProps = _.merge<MuiTextFieldProps, MuiTextFieldProps>({
    label : props.label,
    size : "small",
    fullWidth : true,
    variant : "outlined",
    type : props.type,
  }, props.controlProps || {});

  const error = _.get(form.formState.errors, name, undefined);

  if(error) {
    controlProps.error = true;
    controlProps.helperText = error?.message;
  }

  return (
    <Controller
      name={name}
      control={form.control}
      defaultValue={ensureValidValue(props.defaultValue)}
      render={({ field }) => {
          return (
              <MuiTextField
                {...field}
                {...controlProps}
                value={ensureValidValue(field.value)}
              />
          )
        }
      }
    />
  )
}

export default TextField;